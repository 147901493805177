import { jsonClone } from '@/utils';

export type Encoding = 'utf-8' | 'Big5';

export interface NormalizeDicomInfo {
  study_instance_uid: string;
  series_instance_uid: string;
  sop_instance_uid: string;
  study_datetime: string;
  study_description: string;
  accession_number: string;
  patient_id: string;
  patient_name: string;
  series_description: string;
  series_number: string;
  modality: string;
}

export interface UploadStudyInfoItem {
  study_instance_uid: string;
  study_id?: string;
  patient_id?: string;
  patient_name?: string;
  modality: string;
  files: string[];
}

const reader = new FileReader();

class UploadDicomHelper {
  private _isInit = false;

  private _pyodide: any = null;

  /**
   * ```
   * binaryDataMap = {
   *  [filePath]: [uint8Array]
   * }
   * ```
   */
  binaryDataMap: { [key in string]: Uint8Array } = {};

  private async _init() {
    if (this._isInit) {
      return;
    }

    const pyodide = await loadPyodide();
    await pyodide.loadPackage('micropip');
    const micropip = pyodide.pyimport('micropip');
    await micropip.install('pydicom');

    // 載入後端 package
    const response = await fetch(
      'https://storage.googleapis.com/public-smartpacs-static-files/dicom_helper-1.0.0-py3-none-any.whl' +
        `?t=${Date.now()}`
    );
    const buffer = await response.arrayBuffer();
    await pyodide.unpackArchive(buffer, 'whl');

    this._pyodide = pyodide;
    this._isInit = true;
  }

  /**
   * 是否為 dicom 檔案
   */
  private async _isDicomFile(filePath: string, uint8Array: Uint8Array): Promise<boolean> {
    this._pyodide.globals.set('dicom_file', uint8Array);
    this._pyodide.globals.set('dicom_file_name', filePath);

    await this._pyodide.runPythonAsync(`
    is_dcm = helper.parse_study_info(dicom_file_name, dicom_file.to_py())
    `);

    const isDcm = this._pyodide.globals.get('is_dcm');
    return Boolean(isDcm);
  }

  /**
   * 解析 dicom 檔案
   */
  private async _parseDicomFile(fileItem: File) {
    return new Promise(resolve => {
      reader.onload = async function (event) {
        const filePath = fileItem.webkitRelativePath;
        const arrayBuffer = event.target.result;
        const uint8Array = new Uint8Array(arrayBuffer);
        const isDicomFile = await uploadDicomHelper._isDicomFile(filePath, uint8Array);

        if (!isDicomFile) {
          return resolve(null);
        }

        uploadDicomHelper.binaryDataMap[filePath] = uint8Array;

        return resolve(null);
      };
      reader.readAsArrayBuffer(fileItem);
    });
  }

  /**
   * private 解析 dicom 檔案清單
   */
  private async _parseDicomFileList(fileList: FileList, encoding?: Encoding) {
    await this._init();

    if (!this._pyodide) {
      console.error('no py ready yet');
      return;
    }

    this._pyodide.globals.set('dicom_encoding', encoding);

    await this._pyodide.runPythonAsync(`
    from dicom_helper import DicomHelper

    helper = DicomHelper(dicom_encoding)
    `);

    for (let i = 0; i < fileList.length; i++) {
      const fileItem = fileList[i];

      await this._parseDicomFile(fileItem);
    }

    /**
     * 全部解析完成檔案後，取得 study info 清單
     */
    await this._pyodide.runPythonAsync(`
    study_info_list = helper.study_info_list
    `);

    const infoListData = this._pyodide.globals.get('study_info_list').toJs();
    const studyInfoList = JSON.parse(JSON.stringify(infoListData)) as UploadStudyInfoItem[];

    return studyInfoList;
  }

  /**
   * 解析 dicom 檔案清單
   */
  async parseDicomFileList(fileList: FileList, encoding?: Encoding) {
    this.reset();
    let dataList: UploadStudyInfoItem[] = [];
    try {
      dataList = await this._parseDicomFileList(fileList, encoding);
    } catch (error) {
      console.error('parse dicom file list error:', error);
    }

    return dataList;
  }

  /**
   * 格式化資料
   */
  async normalize_binary(uint8Array: Uint8Array, studyInfo: any) {
    this._pyodide.globals.set('dicom_file', uint8Array);
    this._pyodide.globals.set('study_info', studyInfo);

    await this._pyodide.runPythonAsync(`
    result_binary_data, dicom_info = helper.normalize_binary(dicom_file.to_py(), study_info.to_py())
    `);

    const finalBinaryData = this._pyodide.globals.get('result_binary_data').toJs();
    const dicomInfo = jsonClone(
      this._pyodide.globals.get('dicom_info').toJs()
    ) as NormalizeDicomInfo;

    return {
      finalBinaryData,
      dicomInfo,
    };
  }

  reset() {
    this.binaryDataMap = {};
  }
}

export const uploadDicomHelper = new UploadDicomHelper();
