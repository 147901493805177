export const sleepAtLeast = async (fn, timeout): Promise<void> => {
  const start = Date.now();

  await fn();

  const elapsedTime = Date.now() - start;
  const remainingTime = Math.max(0, timeout - elapsedTime);

  return new Promise(resolve => setTimeout(resolve, remainingTime));
};
