import axios from 'axios';

const baseURL =
  window?.location?.hostname === 'localhost' ? 'https://test.smartpacs.hugg.dev/api' : '/api';

export const fetchRequest = axios.create({
  baseURL,
});

type HandleErrorFunction = (error: any) => Promise<void> | void;

/**
 * 攔截錯誤處理清單
 */
const errorInterceptorHandlerList: HandleErrorFunction[] = [];

fetchRequest.interceptors.response.use(
  // fulfilled
  response => response,

  // rejected
  async error => {
    for (let i = 0; i < errorInterceptorHandlerList.length; i++) {
      await errorInterceptorHandlerList[i](error);
    }

    return Promise.reject(error);
  }
);

/**
 * 增加錯誤攔截處理
 */
export const addApiErrorInterceptorHandler = (errFunction: HandleErrorFunction) => {
  errorInterceptorHandlerList.push(errFunction);
};
