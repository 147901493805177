import React from 'react';
import { ModalBg } from '@/components/BaseModal/ModalBg';
import { PacsButton } from '@/components/common/PacsButton';

type Props = {
  handleConfirm?: () => Promise<void> | void;
  handleCancel?: () => Promise<void> | void;
};

export const AskLogoutModal = ({ handleCancel = () => {}, handleConfirm = () => {} }: Props) => {
  return (
    <ModalBg>
      <div className="appear_top relative w-full max-w-[480px] max-h-[90%] bg-green-182c2a overflow-hidden hidden-overflow-with-radius rounded-[16px] px-[48px] py-[36px]">
        <div className="w-full flex flex-col i-center text-white">
          <h3 className="text-gray-f9f9f9 mb-[28px]">Are you sure you want to log out?</h3>

          <PacsButton
            className="block w-full h-[40px] mb-[8px]"
            onClick={handleConfirm}
          >
            <span className="text-green-182c2a font-bold">LOG OUT</span>
          </PacsButton>
          <PacsButton
            theme="secondary"
            className="block w-full h-[40px] font-bold"
            onClick={handleCancel}
          >
            Cancel
          </PacsButton>
        </div>
      </div>
    </ModalBg>
  );
};
