import React from 'react';
import classNames from 'classnames';
import { HeadItem, HeadRow, HeadCell } from '@/components/common/PrimaryTable';
import { Svg } from '@ohif/ui';
import { ModalityWorkVM } from '@/viewModel/modalityWorkList/ModalityWorkVM';
import { FieldName, Direction } from '@/viewModel/modalityWorkList/ModalityWorkFilterVM';

type Props = {
  className?: string;
  children?: React.ReactNode;
  modalityWorkVM: ModalityWorkVM;
};

const headList: HeadItem<FieldName>[] = [
  { key: 'status', label: 'MWL Status', style: { minWidth: '120px' } },
  { key: 'accession_number', label: 'Accession No.', style: { minWidth: '120px' } },
  { key: 'identifier', label: 'RP ID', style: { minWidth: '120px' } },
  { key: 'patient_name', label: 'Patient Name', style: { minWidth: '120px' } },
  { key: 'patient_id', label: 'Patient ID', style: { minWidth: '120px' } },
  { key: 'create_time', label: 'CreateTime', style: { minWidth: '120px' } },
  { key: 'requesting_physician', label: 'Requested by', style: { minWidth: '120px' } },
  { key: 'description', label: 'RP Description', style: { minWidth: '120px' } },
];

export const ModalityWorkListTableHead = ({ modalityWorkVM }: Props) => {
  const { modalityWorkFilterVM } = modalityWorkVM;

  const checkArrowActive = (fieldName: FieldName, direction: Direction) => {
    const isSameField = modalityWorkFilterVM.currentSortData.fieldName === fieldName;
    const isSameDirection = modalityWorkFilterVM.currentSortData.direction === direction;
    return isSameField && isSameDirection;
  };

  return (
    <HeadRow>
      {headList.map(headItem => (
        <HeadCell
          key={headItem.key}
          className="h-[48px] px-[12px] cursor-pointer"
          onClick={() => modalityWorkFilterVM.handleClickDirection(headItem.key)}
        >
          <div className="w-full h-full flex i-center justify-between">
            {headItem.label}
            <div className="h-full flex flex-col justify-center">
              <Svg
                name="icTriangle"
                className={classNames('mb-[6px] block w-[10px] h-[6px]', [
                  checkArrowActive(headItem.key, 'asc') ? 'text-green-19dbc4' : 'text-gray-666',
                ])}
              />
              <Svg
                name="icTriangle"
                className={classNames('transform rotate-180 block w-[10px] h-[6px]', [
                  checkArrowActive(headItem.key, 'desc') ? 'text-green-19dbc4' : 'text-gray-666',
                ])}
              />
            </div>
          </div>
        </HeadCell>
      ))}
    </HeadRow>
  );
};
