import { fetchRequest } from '@/api/helper';
import { utils } from '@ohif/core';

/**
 * 取得 google healthcare api access token
 * (效期為1小時)
 */
export const apiGetDicomToken = (apiToken: string) => {
  return fetchRequest('/dicom_store/token', {
    headers: { Authorization: `Bearer ${apiToken}` },
  });
};

/**
 * 取得study清單
 */
export const apiGetDicomStudyList = (params: {
  unitId: string;
  search_pattern?: string;
  start_study_datetime?: string;
  end_study_datetime?: string;
  limit?: number;
}) => {
  const { apiToken } = utils.cookieHelper.getApiTokenFromCookie();
  const { unitId, ...restParams } = params;

  return fetchRequest<{ data: Api.StudyItem[] }>(`/dicom/units/${unitId}/studies`, {
    params: restParams,
    headers: { Authorization: `Bearer ${apiToken}` },
  });
};

/**
 * 取得 series 清單
 */
export const apiGetDicomSeriesList = (params: { unitId: string; studyInstanceUid: string }) => {
  const { apiToken } = utils.cookieHelper.getApiTokenFromCookie();
  const { unitId, studyInstanceUid } = params;

  return fetchRequest<{ data: Api.SeriesItem[] }>(
    `/dicom/units/${unitId}/studies/${studyInstanceUid}/series`,
    {
      headers: { Authorization: `Bearer ${apiToken}` },
    }
  );
};

/**
 * (後端) 刪除 studies
 */
export const apiDeleteStudies = (data: { unitId: string; studyUidList: string[] }) => {
  const { apiToken } = utils.cookieHelper.getApiTokenFromCookie();
  const { unitId, studyUidList } = data;

  return fetchRequest(`/dicom/units/${unitId}/studies`, {
    method: 'delete',
    data: {
      study_uid_list: studyUidList,
    },
    headers: { Authorization: `Bearer ${apiToken}` },
  });
};

/**
 * 上傳 dicom 到 google 後，同步更新 backend
 */
export const apiPutDicomInfo = (
  params: { unitId: string; studyInstanceUid: string; seriesUid: string; sopUid: string },
  infoData: any
) => {
  const { apiToken } = utils.cookieHelper.getApiTokenFromCookie();

  return fetchRequest<{ data: Api.SeriesItem[] }>(
    `dicom/units/${params.unitId}/studies/${params.studyInstanceUid}/series/${params.seriesUid}/instances/${params.sopUid}`,
    {
      method: 'put',
      data: infoData,
      headers: { Authorization: `Bearer ${apiToken}` },
    }
  );
};
