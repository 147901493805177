import { fetchRequest } from '@/api/helper';
import { utils } from '@ohif/core';

const { cookieHelper } = utils;

/**
 * 用戶登入
 */
export const apiUserLogin = (data: {
  account: string;
  hospital_code: string;
  password: string;
}) => {
  return fetchRequest('/user/login', {
    method: 'post',
    data,
  });
};

/**
 * 用戶登出
 */
export const apiUserLogout = () => {
  const { apiToken } = cookieHelper.getApiTokenFromCookie();

  return fetchRequest('/user/logout', {
    headers: { Authorization: `Bearer ${apiToken}` },
  });
};

/**
 * 取得用戶自己的資料
 */
export const apiGetUserSelfData = () => {
  const { apiToken } = cookieHelper.getApiTokenFromCookie();

  return fetchRequest('/user', {
    headers: { Authorization: `Bearer ${apiToken}` },
  });
};

/**
 * 更新用戶自己的使用者名稱
 */
export const apiUpdateUserName = (data: { name: string }) => {
  const { apiToken } = cookieHelper.getApiTokenFromCookie();

  return fetchRequest('/user', {
    method: 'patch',
    data,
    headers: { Authorization: `Bearer ${apiToken}` },
  });
};

/**
 * 驗證舊密碼
 */
export const apiVerifyOldPassword = (data: { old_password: string }) => {
  const { apiToken } = cookieHelper.getApiTokenFromCookie();

  return fetchRequest('/user/password', {
    method: 'post',
    data,
    headers: { Authorization: `Bearer ${apiToken}` },
  });
};

/**
 * 更新用戶自己的密碼
 */
export const apiUpdateUserSelfPassword = (data: { new_password: string; old_password: string }) => {
  const { apiToken } = cookieHelper.getApiTokenFromCookie();

  return fetchRequest('/user/password', {
    method: 'put',
    data,
    headers: { Authorization: `Bearer ${apiToken}` },
  });
};

/**
 * manager 新增用戶帳號
 */
export const apiManagerAddUser = (data: {
  account: string;
  description: string;
  name: string;
  password: string;
  // permission?: '預設權限為Normal';
}) => {
  const { apiToken } = cookieHelper.getApiTokenFromCookie();

  return fetchRequest('/user', {
    method: 'post',
    data,
    headers: { Authorization: `Bearer ${apiToken}` },
  });
};

/**
 * 取得使用者帳號清單
 */
export const apiManagerGetUserList = () => {
  const { apiToken } = cookieHelper.getApiTokenFromCookie();

  return fetchRequest('/user/list', {
    headers: { Authorization: `Bearer ${apiToken}` },
  });
};

/**
 * manager 更新其他帳號資料
 */
export const apiManagerUpdateUserAccount = (data: {
  userId: number;
  password?: string;
  status?: Api.Status;
}) => {
  const { apiToken } = cookieHelper.getApiTokenFromCookie();
  const { userId, ...restData } = data;

  return fetchRequest(`/user/${userId}`, {
    method: 'patch',
    data: restData,
    headers: { Authorization: `Bearer ${apiToken}` },
  });
};

/**
 * manager 刪除用戶帳號
 */
export const apiManagerDeleteUserAccount = (data: { userId }) => {
  const { apiToken } = cookieHelper.getApiTokenFromCookie();

  return fetchRequest(`/user/${data.userId}`, {
    method: 'delete',
    data,
    headers: { Authorization: `Bearer ${apiToken}` },
  });
};
