import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Svg } from '@ohif/ui';
import { CycleController } from '@/utils';

type Props = {
  className?: string;
  children?: React.ReactNode;
};

export const DogLoading = ({ className }: Props) => {
  const [iconName, setIconName] = useState('icDog1');
  const [iconCycleController] = useState(new CycleController(['icDog1', 'icDog2']));

  let interval;
  const startCycle = () => {
    clearInterval(interval);
    interval = setInterval(() => {
      setIconName(iconCycleController.getNext());
    }, 200);
  };

  const clearCycle = () => {
    clearInterval(interval);
  };

  useEffect(() => {
    startCycle();

    return clearCycle;
  }, []);

  return (
    <div className={classNames('', className)}>
      <Svg
        name={iconName}
        className="inline-block w-[55px] h-[55px]"
      />
    </div>
  );
};
