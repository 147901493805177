import { fetchRequest } from '@/api/helper';
import { utils } from '@ohif/core';

const { cookieHelper } = utils;

/**
 * 取得目前醫院資訊
 */
export const apiGetUnitInfo = () => {
  const { apiToken } = cookieHelper.getApiTokenFromCookie();

  return fetchRequest<{ data: Api.UnitInfo }>('/unit/info', {
    method: 'get',
    headers: { Authorization: `Bearer ${apiToken}` },
  });
};
