import React, { useContext, useEffect } from 'react';
import { Svg } from '@ohif/ui';
import { TextInput, PasswordInput } from '@mantine/core';
import { Checkbox } from '@/components/common/Checkbox';
import { LoginSupportModal } from './LoginSupportModal';
import { useSwitchModal } from '@hooks';
import { LoginViewModel } from '@/viewModel/LoginViewModel';
import { utils } from '@ohif/core';
import { PacsButton } from '@/components/common/PacsButton';
import { smartPacsAuthContext } from '@/SmartPacsProvider/SmartPacsAuthProvider';
import { useLocation, useNavigate } from 'react-router-dom';
import { useToast } from '@ohif/ui';

type Props = {
  loginViewModel?: LoginViewModel;
};

const { cookieHelper } = utils;

export const Login = ({ loginViewModel = new LoginViewModel() }: Props) => {
  const [isShowSupport, openSupport, closeSupport] = useSwitchModal(false);
  const { setAuthData, setDicomToken } = useContext(smartPacsAuthContext);
  const navigate = useNavigate();
  const { toastShow } = useToast();

  const location = useLocation();
  const { apiToken } = cookieHelper.getApiTokenFromCookie();
  const { dicomToken: cookieDicomToken } = cookieHelper.getDicomTokenFromCookie();
  const isLogin = apiToken && cookieDicomToken;
  const isLoginPage = location.pathname.startsWith('/login');

  /**
   * 已經登入，不需要進入登入頁
   */
  useEffect(() => {
    if (isLogin && isLoginPage) {
      navigate('/', { replace: true });
    }
  }, []);

  /**
   * 執行登入
   */
  const doLogin = async () => {
    const { isLoginSuccess } = await loginViewModel.login({
      successCallback(loginResponse, accountData, dicomToken) {
        cookieHelper.setAuthCookie(loginResponse);
        cookieHelper.setDicomCookie({ dicomToken });
        cookieHelper.setLoginAccountData(accountData);
        setAuthData(loginResponse);
        setDicomToken(dicomToken);
      },
      errorCallback(error) {
        toastShow({ type: 'error', message: '登入資料有誤' });
      },
    });

    if (isLoginSuccess) {
      window.location.replace('/');
    }
  };

  /**
   * 按下 enter 登入
   */
  const enterLogin = e => {
    utils.handleEnterKey(e, doLogin);
  };

  return (
    <div className="h-screen flex flex-col bg-green-182c2a">
      <div className="w-full h-full flex justify-center items-center text-white">
        <div className="w-full max-w-[364px] px-[32px] py-[36px] flex flex-col items-center">
          <Svg
            name="icLogo"
            className="block w-[60px] h-[45px] mr-[5px]"
          />
          <div className="w-full flex justify-center items-center mb-[28px]">
            <Svg
              name="icSmart"
              className="block w-[75px]  mr-[10px]"
            />
            <Svg
              name="icPacs"
              className="block w-[55px] "
            />
          </div>

          <div className="w-full mb-[12px]">
            <p className="text-[12px] mb-[8px]">Hospital Code</p>
            <TextInput
              placeholder="Enter your hospital code"
              value={loginViewModel.accountData.hospitalCode}
              onChange={event =>
                loginViewModel.updateAccountDataField('hospitalCode', event.currentTarget.value)
              }
              onKeyPress={enterLogin}
            />
          </div>

          <div className="w-full mb-[12px]">
            <p className="text-[12px] mb-[8px]">Account</p>
            <TextInput
              placeholder="Enter your account"
              value={loginViewModel.accountData.account}
              onChange={event =>
                loginViewModel.updateAccountDataField('account', event.currentTarget.value)
              }
              onKeyPress={enterLogin}
            />
          </div>

          <div className="w-full mb-[8px]">
            <p className="text-[12px] mb-[8px]">Password</p>
            <PasswordInput
              placeholder="Enter your password"
              value={loginViewModel.accountData.password}
              onChange={event =>
                loginViewModel.updateAccountDataField('password', event.currentTarget.value)
              }
              onKeyPress={enterLogin}
            />
          </div>

          <div
            className="w-full flex i-center cursor-pointer px-[6px] mb-[36px] select-none"
            onClick={() => loginViewModel.checkRememberPassword()}
          >
            <Checkbox
              isCheck={loginViewModel.isRememberPassword}
              size="small"
              className="mr-[8px]"
            />
            <p className="">Remember Me</p>
          </div>

          <PacsButton
            className="block w-full h-[40px] mb-[8px]"
            isLoading={loginViewModel.isDoingLogin}
            onClick={doLogin}
          >
            <span className="text-green-182c2a text-[14px] font-bold">LOG IN</span>
          </PacsButton>

          <p
            className="text-[14px] text-green-19dbc4 hover:text-[#28B4A3] underline cursor-pointer"
            onClick={openSupport}
          >
            Need help logging in?
          </p>
        </div>
      </div>

      {isShowSupport && <LoginSupportModal handleClickBack={closeSupport} />}
    </div>
  );
};
