import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useDrag } from 'react-dnd';
import Icon from '../Icon';
import { StringNumber } from '../../types';
import DisplaySetMessageListTooltip from '../DisplaySetMessageListTooltip';
import { DogLoading } from '@/components/Loading/DogLoading/DogLoading';
import { Svg } from '@ohif/ui';

/**
 * Display a thumbnail for a display set.
 */
const Thumbnail = ({
  displaySetInstanceUID,
  className,
  imageSrc,
  imageAltText,
  description,
  seriesNumber,
  numInstances,
  countIcon,
  messages,
  dragData,
  isActive,
  isShowMediaIcon,
  onClick,
  onDoubleClick,
}): React.ReactNode => {
  // TODO: We should wrap our thumbnail to create a "DraggableThumbnail", as
  // this will still allow for "drag", even if there is no drop target for the
  // specified item.
  const [collectedProps, drag, dragPreview] = useDrag({
    type: 'displayset',
    item: { ...dragData },
    canDrag: function (monitor) {
      return Object.keys(dragData).length !== 0;
    },
  });

  return (
    <div
      className={classnames(
        className,
        'group mb-8 flex flex-1 cursor-pointer select-none flex-col px-3 outline-none'
      )}
      id={`thumbnail-${displaySetInstanceUID}`}
      data-cy={`study-browser-thumbnail`}
      /**
       * [在 viewer 頁面]
       * 原本的 double click 會切換 viewer 圖片
       * 2024/02/02 發生 ipad 無法正常執行 onDoubleClick 的 react 事件
       * (在此之前是可以的，且當下、android 手機裝置也是可以執行 onDoubleClick 事件)
       *
       * 但也考量到操作不順暢，直接改成單擊事件切換 viewer 圖片
       */
      onClick={() => {
        onDoubleClick();
      }}
      role="button"
      tabIndex="0"
    >
      <div ref={drag}>
        <div
          className={classnames(
            'relative min-h-32 flex flex-1 items-center justify-center overflow-hidden rounded-md bg-gray-111 text-base text-white',
            isActive
              ? 'border-green-19dbc4 border-2'
              : 'border-gray-c9c9c9 border hover:border-[#19A698]'
          )}
          style={{
            margin: isActive ? '0' : '1px',
          }}
        >
          {imageSrc ? (
            <>
              <img
                src={imageSrc}
                alt={imageAltText}
                className="min-h-32 object-none"
                crossOrigin="anonymous"
              />
              {isShowMediaIcon && (
                <div className="absolute z-[1] right-[6px] top-[6px]">
                  <Svg
                    name="icFrames"
                    className="block w-[20px] h-[20px]"
                  />
                </div>
              )}
            </>
          ) : (
            <DogLoading />
          )}
        </div>
        <div className="flex flex-1 flex-row items-center pt-2 text-base text-white">
          <div className="mr-4">
            <span className="text-white font-bold">{'S: '}</span>
            {seriesNumber}
          </div>
          <div className="flex flex-1 flex-row items-center">
            <Icon
              name={countIcon || 'group-layers'}
              className="mr-2 w-3"
            />
            {` ${numInstances}`}
          </div>
          <DisplaySetMessageListTooltip
            messages={messages}
            id={`display-set-tooltip-${displaySetInstanceUID}`}
          />
        </div>
        <div className="break-all text-base text-white">{description}</div>
      </div>
    </div>
  );
};

Thumbnail.propTypes = {
  displaySetInstanceUID: PropTypes.string.isRequired,
  className: PropTypes.string,
  imageSrc: PropTypes.string,
  /**
   * Data the thumbnail should expose to a receiving drop target. Use a matching
   * `dragData.type` to identify which targets can receive this draggable item.
   * If this is not set, drag-n-drop will be disabled for this thumbnail.
   *
   * Ref: https://react-dnd.github.io/react-dnd/docs/api/use-drag#specification-object-members
   */
  dragData: PropTypes.shape({
    /** Must match the "type" a dropTarget expects */
    type: PropTypes.string.isRequired,
  }),
  imageAltText: PropTypes.string,
  description: PropTypes.string.isRequired,
  seriesNumber: StringNumber.isRequired,
  numInstances: PropTypes.number.isRequired,
  messages: PropTypes.object,
  isActive: PropTypes.bool.isRequired,
  isShowMediaIcon: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
};

Thumbnail.defaultProps = {
  dragData: {},
};

export default Thumbnail;
