import React, { useState } from 'react';
import classNames from 'classnames';
import { TextInput, PasswordInput } from '@mantine/core';
import { UserSelfFormViewModel } from '@/viewModel/setting/UserSelfFormViewModel';
import { PacsButton } from '@/components/common/PacsButton';
import { useSwitchModal } from '@/hooks';
import { AskChangePasswordModal } from './AskChangePasswordModal';
import { utils } from '@ohif/core';
import { useToast } from '@ohif/ui';

type Props = {
  className?: string;
  userSelfFormViewModel: UserSelfFormViewModel;
  onSaveName?: () => Promise<void> | void;
};

export const UserSelfForm = ({
  className,
  userSelfFormViewModel = new UserSelfFormViewModel(),
  onSaveName = () => {},
}: Props) => {
  const [isOpenAskChangePasswordModal, openAskChangePasswordModal, closeAskChangePasswordModal] =
    useSwitchModal(false);
  const [isShowNewPasswordInput, setIsShowNewPasswordInput] = useState(false);
  const { toastShow } = useToast();

  const handleOldPasswordSuccess = () => {
    setIsShowNewPasswordInput(true);
    closeAskChangePasswordModal();
  };

  /**
   * 儲存 name
   */
  const saveName = async () => {
    await userSelfFormViewModel.saveName({
      successCallback() {
        toastShow({ type: 'success', message: 'Name update successful.' });
      },
      errorCallback() {
        toastShow({ type: 'error', message: 'Name edit failed.' });
      },
    });
    await onSaveName();
  };

  /**
   * 儲存密碼
   */
  const saveNewPassword = async () => {
    await userSelfFormViewModel.saveNewPassword({
      successCallback() {
        toastShow({ type: 'success', message: 'Your password updated.' });
      },
      onPasswordConfirmNotMatch() {
        toastShow({ type: 'error', message: 'Password do not match.' });
      },
      errorCallback() {
        toastShow({ type: 'error', message: 'Password reset failed.' });
      },
    });
  };

  return (
    <>
      <div className={classNames('user_self_form', className)}>
        <div className="w-full mb-[24px]">
          <p className="text-[14px] mb-[8px]">Account</p>
          <TextInput
            readOnly
            placeholder="-"
            value={userSelfFormViewModel.accountData.account}
          />
        </div>

        <div className="w-full mb-[24px] ">
          <p className="text-[14px] mb-[8px]">Name</p>
          <div className="grid grid-cols-[1fr_104px] gap-x-[8px] i-center">
            <TextInput
              placeholder="-"
              value={userSelfFormViewModel.accountData.name}
              onChange={e =>
                userSelfFormViewModel.updateAccountDataField('name', e.currentTarget.value)
              }
              onKeyPress={e => utils.handleEnterKey(e, () => saveName())}
            />
            <PacsButton
              theme="secondary"
              className=" w-full h-[44px]"
              onClick={saveName}
            >
              Save
            </PacsButton>
          </div>
        </div>

        {!isShowNewPasswordInput && (
          <div className="w-full mb-[24px]">
            <p className="text-[14px] mb-[8px]">New password</p>
            <div className="grid grid-cols-[1fr_104px] gap-x-[8px] i-center">
              <PasswordInput
                readOnly
                placeholder="Enter new password"
                visibilityToggleIcon={() => false}
              />
              <PacsButton
                theme="secondary"
                className=" w-full h-[44px]"
                onClick={openAskChangePasswordModal}
              >
                Edit
              </PacsButton>
            </div>
          </div>
        )}

        {/* 填寫新密碼 */}
        {isShowNewPasswordInput && (
          <>
            <p className="text-[14px] mb-[8px]">New password</p>
            <div className="grid grid-cols-[1fr_104px] gap-x-[8px] i-center mb-[8px]">
              <PasswordInput
                placeholder="Enter new password"
                value={userSelfFormViewModel.accountData.newPassword}
                onChange={e =>
                  userSelfFormViewModel.updateAccountDataField('newPassword', e.currentTarget.value)
                }
                onKeyPress={e => utils.handleEnterKey(e, () => saveNewPassword())}
              />
            </div>

            <p className="text-[14px] mb-[8px]">Confirm password</p>
            <div className="grid grid-cols-[1fr_104px] gap-x-[8px] i-center mb-[24px]">
              <PasswordInput
                placeholder="Confirm password"
                onPaste={e => {
                  e.preventDefault();
                  return false;
                }}
                value={userSelfFormViewModel.accountData.newPasswordConfirm}
                onChange={e =>
                  userSelfFormViewModel.updateAccountDataField(
                    'newPasswordConfirm',
                    e.currentTarget.value
                  )
                }
                onKeyPress={e => utils.handleEnterKey(e, () => saveNewPassword())}
              />
              <PacsButton
                theme="secondary"
                className=" w-full h-[44px]"
                onClick={saveNewPassword}
              >
                Save
              </PacsButton>
            </div>
          </>
        )}
      </div>

      {isOpenAskChangePasswordModal && (
        <AskChangePasswordModal
          userSelfFormViewModel={userSelfFormViewModel}
          handleClose={closeAskChangePasswordModal}
          handleOldPasswordSuccess={handleOldPasswordSuccess}
        />
      )}
    </>
  );
};
