import { utils } from '@ohif/core';

const { cookieHelper } = utils;

export class BaseApi {
  protected getAuthHeader() {
    const { apiToken } = cookieHelper.getApiTokenFromCookie();

    return { Authorization: `Bearer ${apiToken}` };
  }
}
