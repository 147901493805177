import toolAngle from './../../assets/svgs/pacs_tool/ic_angle.svg';
import toolArrowAnnotation from './../../assets/svgs/pacs_tool/ic_arrow_annotation.svg';
import toolBidirectional from './../../assets/svgs/pacs_tool/ic_bidirectional.svg';
import toolCapture from './../../assets/svgs/pacs_tool/ic_capture.svg';
import toolCine from './../../assets/svgs/pacs_tool/ic_cine.svg';
import toolCobbAngle from './../../assets/svgs/pacs_tool/ic_cobb_angle.svg';
import toolCrosshairs from './../../assets/svgs/pacs_tool/ic_crosshairs.svg';
import toolCircle from './../../assets/svgs/pacs_tool/ic_circle.svg';
import toolClearMarks from './../../assets/svgs/pacs_tool/ic_clean_mark.svg';
import toolDicomTagBrowser from './../../assets/svgs/pacs_tool/ic_dicomtagbrowser.svg';
import toolEllipse from './../../assets/svgs/pacs_tool/ic_ellipse_outline.svg';
import toolFlipHorizontal from './../../assets/svgs/pacs_tool/ic_fliphorizontal.svg';
import toolFlipvertical from './../../assets/svgs/pacs_tool/ic_flipvertical.svg';
import toolGridlayout from './../../assets/svgs/pacs_tool/ic_gridlayout.svg';
import toolInvert from './../../assets/svgs/pacs_tool/ic_invert.svg';
import toolLength from './../../assets/svgs/pacs_tool/ic_length.svg';
import toolOverlay from './../../assets/svgs/pacs_tool/ic_overlay.svg';
import toolPan from './../../assets/svgs/pacs_tool/ic_pan.svg';
import toolPolygon from './../../assets/svgs/pacs_tool/ic_polygon.svg';
import toolDragProbe from './../../assets/svgs/pacs_tool/ic_drag_probe.svg';
import toolRectangle from './../../assets/svgs/pacs_tool/ic_rectangle_outline.svg';
import toolReset from './../../assets/svgs/pacs_tool/ic_reset.svg';
import toolRotateright from './../../assets/svgs/pacs_tool/ic_rotateright.svg';
import toolStackimagesync from './../../assets/svgs/pacs_tool/ic_stackimagesync.svg';
import toolWindowlevel from './../../assets/svgs/pacs_tool/ic_windowlevel.svg';
import toolZoom from './../../assets/svgs/pacs_tool/ic_zoom.svg';
import toolShare from './../../assets/svgs/pacs_tool/ic_share.svg';
import toolDragRotate from './../../assets/svgs/pacs_tool/ic_rotate_drag.svg';

export type PacsToolIconName =
  | 'toolAngle'
  | 'toolArrowAnnotation'
  | 'toolBidirectional'
  | 'toolCapture'
  | 'toolCine'
  | 'toolCircle'
  | 'toolClearMarks'
  | 'toolCobbAngle'
  | 'toolCrosshairs'
  | 'toolDicomTagBrowser'
  | 'toolEllipse'
  | 'toolFlipHorizontal'
  | 'toolFlipvertical'
  | 'toolGridlayout'
  | 'toolInvert'
  | 'toolLength'
  | 'toolOverlay'
  | 'toolPan'
  | 'toolPolygon'
  | 'toolDragProbe'
  | 'toolRectangle'
  | 'toolReset'
  | 'toolRotateright'
  | 'toolStackimagesync'
  | 'toolWindowlevel'
  | 'toolZoom'
  | 'toolShare'
  | 'toolDragRotate';

export const smartPacsToolIconMap: { [key in PacsToolIconName]: any } = {
  toolAngle,
  toolArrowAnnotation,
  toolBidirectional,
  toolCapture,
  toolCine,
  toolCircle,
  toolClearMarks,
  toolCobbAngle,
  toolCrosshairs,
  toolDicomTagBrowser,
  toolEllipse,
  toolFlipHorizontal,
  toolFlipvertical,
  toolGridlayout,
  toolInvert,
  toolLength,
  toolOverlay,
  toolPan,
  toolPolygon,
  toolDragProbe,
  toolRectangle,
  toolReset,
  toolRotateright,
  toolStackimagesync,
  toolWindowlevel,
  toolZoom,
  toolShare,
  toolDragRotate,
};
