import dayjs from 'dayjs';

export class DeviceItem {
  constructor(data: Api.UnitBinding) {
    this.data = data;
  }

  data: Api.UnitBinding;

  get uploadTime() {
    return this.displayTime(this.data.hub_upload_time);
  }

  get aliveTime() {
    return this.displayTime(this.data.hub_alive_time);
  }

  /**
   * 是否連線 (前端計算時間差)
   */
  get isConnected() {
    const aliveTime = this.data.hub_alive_time;
    if (!aliveTime) {
      return false;
    }
    const last5MinutesTime = dayjs().subtract(5, 'minute');
    return !last5MinutesTime.isAfter(aliveTime);
  }

  displayTime(dateTime: string) {
    if (!dateTime) {
      return '--';
    }

    return dayjs(dateTime).format('HH:mm:ss A ,YYYY/MM/DD');
  }
}
