import React from 'react';
import { ModalBg } from '@/components/BaseModal/ModalBg';
import { ShareStudyVM } from '@/viewModel/ShareStudy/ShareStudyVM';
import { zIndex } from '@/config/constants';
import classNames from 'classnames';
import { SelectMoreDicom } from './SelectMoreDicom';
import { ViewAndDownload } from './ViewAndDownload';
import { MenuSelect } from './MenuSelect';
import { ViewOnly } from './ViewOnly';

type Props = {
  onClose: () => Promise<void> | void;
  shareStudyVM: ShareStudyVM;
};

export const ShareStudyModal = ({ onClose, shareStudyVM }: Props) => {
  const { menuVM } = shareStudyVM;

  const handleClose = () => {
    if (shareStudyVM.actionLoading.isLoading) {
      return;
    }

    onClose();
  };

  return (
    <ModalBg
      handleClickBg={handleClose}
      zIndex={zIndex.SHARE_STUDY_MODAL}
    >
      <div
        className={classNames(
          'appear_top relative w-full  max-h-[90%] bg-green-182c2a overflow-y-auto scrollbar-primary hidden-overflow-with-radius rounded-[16px] py-[36px]',
          menuVM.currentMenuKey === 'menu' && 'max-w-[576px]',
          menuVM.currentMenuKey === 'viewOnly' && 'max-w-[470px]',
          menuVM.currentMenuKey === 'viewAndDownload' && [
            shareStudyVM.isDownloadMultpleDicom ? 'max-w-[600px]' : 'max-w-[896px]',
          ],
          menuVM.currentMenuKey === 'DownloadOthers' && 'max-w-[896px]'
        )}
      >
        {menuVM.currentMenuKey === 'menu' && <MenuSelect shareStudyVM={shareStudyVM} />}
        {menuVM.currentMenuKey === 'viewOnly' && <ViewOnly shareStudyVM={shareStudyVM} />}
        {menuVM.currentMenuKey === 'viewAndDownload' && (
          <ViewAndDownload
            shareStudyVM={shareStudyVM}
            onClose={handleClose}
          />
        )}
        {menuVM.currentMenuKey === 'DownloadOthers' && (
          <SelectMoreDicom
            onClose={handleClose}
            shareStudyVM={shareStudyVM}
          />
        )}
      </div>
    </ModalBg>
  );
};
