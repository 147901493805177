import React from 'react';
import classNames from 'classnames';
import { Loading } from '@/components/common/Loading/Loading';

interface Props extends React.ComponentProps<'button'> {
  isRoundedFull?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  theme?: 'primary' | 'secondary' | 'confirm' | 'cancel' | 'warning' | 'grass';
  className?: string;
  children?: React.ReactNode;
  size?: 'small' | 'normal';
  roundSize?: 'small' | 'normal';
}

export const PacsButton = ({
  isRoundedFull = false,
  isDisabled = false,
  isLoading = false,
  theme = 'primary',
  size = 'normal',
  roundSize = 'normal',
  className,
  children,
  ...props
}: Props) => {
  const isPreventAction = isLoading || isDisabled;

  const roundedClass = () => {
    if (isRoundedFull) {
      return 'rounded-full';
    }

    const roundedMap = { small: 'rounded-[4px]', normal: 'rounded-[8px]' };
    return roundedMap[roundSize];
  };

  return (
    <button
      className={classNames(
        'flex-center',

        roundedClass(),

        isDisabled && 'cursor-not-allowed opacity-70',

        isLoading && 'cursor-auto',

        theme === 'primary' && [
          'text-white bg-green-19dbc4',
          !isPreventAction && 'hover:bg-green-28b4a3',
        ],

        theme === 'secondary' && [
          'text-[14px] text-green-19dbc4 bg-green-182c2a',
          'border-2 border-green-19dbc4',
          !isPreventAction && 'hover:bg-green-19dbc4 hover:text-white',
        ],

        theme === 'confirm' && [
          'text-white bg-green-66d1ab',
          !isPreventAction && 'hover:bg-[#53A98B]',
        ],

        theme === 'cancel' && ['text-white bg-gray-666', !isPreventAction && 'hover:bg-[#7B7B7B]'],

        theme === 'warning' && [
          'text-white bg-green-182c2a',
          !isPreventAction && 'hover:bg-opacity-90',
        ],

        theme === 'grass' && [
          'text-white bg-green-4e6d6a',
          !isPreventAction && 'hover:bg-opacity-90',
        ],

        size === 'small' && 'text-[14px] px-[6px] py-[3px]',

        size === 'normal' && 'px-[8px] py-[3px]',

        className
      )}
      disabled={isDisabled}
      {...props}
    >
      {isLoading && <Loading className="w-[16px] h-[16px]" />}
      {!isLoading && children}
    </button>
  );
};
