import { fetchRequest } from '@/api/helper';
import { BaseApi } from '@/api/base/BaseApi';

class ShareLinkApi extends BaseApi {
  /**
   * 產生 share link 網址
   */
  createShareLink(data: { study_uid: string }) {
    return fetchRequest<{ data: Api.ShareLinkData }>(`/share_link/${data.study_uid}`, {
      method: 'post',
      data,
      headers: this.getAuthHeader(),
    });
  }

  /**
   * 驗證share token, 並取得供share link用的google healthcare api access token, 效期為1小時
   */
  createDicomToken(data: { share_token: string }) {
    return fetchRequest<{ data: Api.ShareLinkDicomTokenData }>('/share_link/dicom_store/token', {
      method: 'post',
      data,
      headers: this.getAuthHeader(),
    });
  }
}

export const shareLinkApi = new ShareLinkApi();
