import dayjs from 'dayjs';
import { useState } from 'react';
import { utils } from '@ohif/core';
import { compareHelper, CycleController } from '@/utils';
import { cookieKey } from '@/config/constants';

export type SortFieldName = keyof Api.StudyItem | 'share' | 'info';

export interface StudyFilterField {
  accession_number: string;
  modalities: string;
  patient_id: string;
  patient_name: string;
  study_description: string;
  patient_telephone_number: string;
}

const { cookie, jsonClone } = utils;

interface SortData {
  fieldName: SortFieldName;
  direction: Compare.Order;
}

/**
 * 取得天數選項的 api 參數 map
 */
const getDayOptionStrMap = () => {
  const today = dayjs();
  const last7Days = today.subtract(7, 'day');
  const last30Days = today.subtract(30, 'day');

  return {
    today,
    last7Days,
    last30Days,
  };
};

/**
 * 建立初始篩選資料
 */
const createFilterData = (): StudyFilterField => {
  return {
    patient_name: '',
    patient_id: '',
    study_description: '',
    accession_number: '',
    modalities: '',
    patient_telephone_number: '',
  };
};

export const getDefaultStudyOption = () => {
  return cookie.get(cookieKey.STUDY_OPTION).data ?? 'last7Days';
};

export class StudyListFilterVM {
  constructor() {
    const defaultStudyOption = getDefaultStudyOption();
    const defaultFilterData: StudyFilterField = {
      ...createFilterData(),
      ...cookie.get(cookieKey.FILTER_DATA).data,
    };

    [this.studyOption, this.setStudyOption] = useState<StudyListOption>(defaultStudyOption);
    this._studyOption = defaultStudyOption;
    [this.filterData, this.setFilterData] = useState<StudyFilterField>(defaultFilterData);
    [this.currentSortData, this.setCurrentSortData] = useState<SortData>({
      fieldName: 'study_datetime',
      direction: 'desc',
    });
    [this.cycleController] = useState(new CycleController<Compare.Order>(['desc', 'asc']));
  }

  cycleController: CycleController<Compare.Order>;

  /**
   * (ui state) 天數選項
   */
  studyOption: StudyListOption;

  /**
   * 天數選項
   */
  private _studyOption: StudyListOption;

  /**
   * setter 天數選項
   */
  private setStudyOption: React.Dispatch<React.SetStateAction<StudyListOption>>;

  /**
   * 篩選欄位
   */
  filterData: StudyFilterField;

  currentSortData: SortData;
  setCurrentSortData: React.Dispatch<React.SetStateAction<SortData>>;

  /**
   * setter 篩選欄位
   */
  private setFilterData: React.Dispatch<React.SetStateAction<StudyFilterField>>;

  /**
   * 勾選選項
   */
  checkOption(option: StudyListOption) {
    this.setStudyOption(option);
    this._studyOption = option;
    cookie.set(cookieKey.STUDY_OPTION, { data: option });
  }

  /**
   * 更新欄位
   */
  updateFilterDataField(fieldName: keyof StudyFilterField, val: string) {
    const newFieldData = { ...this.filterData, [fieldName]: val };
    this.setFilterData(newFieldData);
    cookie.set(cookieKey.FILTER_DATA, { data: newFieldData });
  }

  /**
   * 取得篩選參數
   */
  getFilterParams() {
    const dayStrOptionMap = getDayOptionStrMap();
    const startFormat = 'YYYY-MM-DD 00:00:00';
    const endFormat = 'YYYY-MM-DD 23:59:59';

    const params = {
      limit: undefined,
      startDate: dayStrOptionMap.today.format(startFormat),
      endDate: dayStrOptionMap.today.format(endFormat),
    };

    if (this._studyOption === 'last7Days') {
      params.startDate = dayStrOptionMap.last7Days.format(startFormat);
    } else if (this._studyOption === 'last30Days') {
      params.startDate = dayStrOptionMap.last30Days.format(startFormat);
    } else if (this._studyOption === 'recent') {
      params.startDate = undefined;
      params.endDate = undefined;
      params.limit = 100;
    }

    return params;
  }

  /**
   * 點擊排序方向
   */
  handleClickDirection(fieldName: SortFieldName) {
    const isSameField = this.currentSortData.fieldName === fieldName;
    let newDirection: Compare.Order = 'desc';

    if (isSameField) {
      newDirection = this.cycleController.getNext();
    } else {
      this.cycleController.reset();
    }

    this.setCurrentSortData({ fieldName, direction: newDirection });
  }

  /**
   * 取得文字篩選後的 study 清單
   */
  private getTextFilterStudyList(studyList: Api.StudyItem[]) {
    const filterData = { ...this.filterData };
    Object.keys(filterData).forEach(key => {
      filterData[key] = filterData[key].toLocaleLowerCase();
    });

    const filterFieldList: Array<keyof Api.StudyItem> = [
      'patient_name',
      'patient_id',
      'study_description',
      'accession_number',
      'modalities',
      'patient_telephone_number',
    ];

    const filterList = studyList.filter(study => {
      return filterFieldList.reduce((prev, current) => {
        const strField = study[current] as string;
        const condition = strField.toLocaleLowerCase().includes(filterData[current]);
        return prev && condition;
      }, true);
    });

    return filterList;
  }

  /**
   * 檢查欄位是否可以被排序
   */
  private checkCanSort() {
    const sortFieldMap: { [key in SortFieldName]?: boolean } = {
      study_datetime: true,
      patient_id: true,
      patient_name: true,
      accession_number: true,
      study_description: true,
      modalities: true,
      instances: true,
    };

    const canSort = sortFieldMap[this.currentSortData.fieldName];
    return canSort;
  }

  /**
   * 取得排序過的 study 清單
   */
  private getSortStudyList(studyList: Api.StudyItem[]): Api.StudyItem[] {
    if (!this.checkCanSort()) {
      console.warn('can not sort the column:', this.currentSortData.fieldName);
      return studyList;
    }

    const copyList = jsonClone(studyList) as Api.StudyItem[];
    const compareTypeMap: { [key in SortFieldName]?: Compare.Type } = {
      patient_name: 'string',
      patient_id: 'string',
      study_description: 'string',
      accession_number: 'string',
      modalities: 'string',
      study_datetime: 'dateTime',
      instances: 'number',
    };

    const sortData = this.currentSortData;
    const compareFunc = compareHelper.createCompareFunc([
      {
        fieldName: sortData.fieldName,
        order: sortData.direction,
        compareType: compareTypeMap[sortData.fieldName],
      },
      { fieldName: 'study_datetime', order: 'desc', compareType: 'dateTime' },
    ]);
    copyList.sort(compareFunc);

    return copyList;
  }

  /**
   * 取得最終結果
   */
  getFinalFilterStudyList(studyList: Api.StudyItem[]) {
    const filterList = this.getTextFilterStudyList(studyList);
    const sortList = this.getSortStudyList(filterList);
    return sortList;
  }

  /**
   * 檢查是否為預設排序
   */
  checkIsFilterMatchDefault() {
    let hasFilterStr = false;
    Object.keys(this.filterData).forEach((keyName: keyof StudyFilterField) => {
      if (this.filterData[keyName] !== '') {
        hasFilterStr = true;
      }
    });

    const isMatchStudyDate =
      this.currentSortData.direction === 'desc' &&
      this.currentSortData.fieldName === 'study_datetime';

    return !hasFilterStr && isMatchStudyDate;
  }

  /**
   * 清除排序與篩選
   */
  resetFilterAndSort() {
    this.setFilterData(createFilterData());
    this.setCurrentSortData({ fieldName: 'study_datetime', direction: 'desc' });
    cookie.remove(cookieKey.FILTER_DATA);
  }
}
