import React from 'react';
import classNames from 'classnames';
import {
  TableRowProps,
  TableCellProps,
  HeadItem,
  TableProps,
} from '@/components/common/base/baseTableType';

export type { HeadItem };

export const HeadRow = ({ className, children, ...props }: TableRowProps) => {
  return (
    <ul
      className={classNames(['table-row w-full'], className)}
      {...props}
    >
      {children}
    </ul>
  );
};

export const HeadCell = ({
  className,
  children,
  cellAlign = 'middle',
  ...props
}: TableCellProps) => {
  return (
    <li
      className={classNames(
        'table-cell font-bold last-of-type:border-r-0 select-none',
        cellAlign === 'top' && 'align-top',
        cellAlign === 'middle' && 'align-middle',
        className
      )}
      {...props}
    >
      {children}
    </li>
  );
};

export const TableRow = ({ className, children, isDisabled = false, ...props }: TableRowProps) => {
  return (
    <ul
      className={classNames(['table-row w-full'], className)}
      {...props}
    >
      {children}
    </ul>
  );
};

export const TableCell = ({
  className,
  children,
  cellAlign = 'middle',
  ...props
}: TableCellProps) => {
  return (
    <li
      className={classNames(
        'table-cell relative last-of-type:border-r-0',
        cellAlign === 'top' && 'align-top',
        cellAlign === 'middle' && 'align-middle',
        className
      )}
      {...props}
    >
      {children}
    </li>
  );
};

export const CommonTable = ({
  isCustomHeader = false,
  isHeightFull = false,
  customHeader,
  children,
  className,
  headList,
}: TableProps) => {
  return (
    <div
      className={classNames(
        'relative w-full border-separate overflow-x-auto scrollbar-primary',
        isHeightFull && 'h-full',
        className
      )}
    >
      <div className={classNames('table w-full')}>
        <div className="table-row-group">
          {/*
            head
          */}
          {!isCustomHeader && (
            <HeadRow>
              {headList.map(headItem => (
                <HeadCell
                  key={headItem.key}
                  className="px-[8px]"
                  style={headItem.style}
                >
                  {headItem.label}
                </HeadCell>
              ))}
            </HeadRow>
          )}

          {isCustomHeader && <>{customHeader}</>}

          {/*
            body
          */}
          {children}
        </div>
      </div>
    </div>
  );
};
