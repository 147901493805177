class DeviceHelper {
  private isAlreadyDisablePageScaling = false;

  /**
   * 禁止 ios (touch) 用戶縮放整個網頁
   *
   * - reference:
   * https://stackoverflow.com/a/42780478
   */
  disableIosTouchDeviceScaling() {
    const isIosDevice = /iPad|iPhone|iPod/.test(navigator.userAgent);
    if (!isIosDevice || this.isAlreadyDisablePageScaling) {
      return;
    }

    window.document.addEventListener(
      'touchmove',
      e => {
        if (e.scale !== 1) {
          e.preventDefault();
        }
      },
      { passive: false }
    );

    this.isAlreadyDisablePageScaling = true;
  }
}

export const deviceHelper = new DeviceHelper();
