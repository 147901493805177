import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Icon from '../Icon';

const baseClasses =
  'first:border-0 border-t border-secondary-light cursor-pointer select-none outline-none';

const StudyItem = ({
  date,
  description,
  numInstances,
  modalities,
  trackedSeries,
  isActive,
  onClick,
}) => {
  return (
    <div
      className={classnames(
        'first:border-0 border-t border-black cursor-pointer select-none outline-none bg-green-182c2a'
      )}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex="0"
    >
      <div className="flex flex-1 flex-col px-4 pb-2 text-white">
        <div className="flex flex-row items-center justify-between pt-2 pb-2">
          <div className="text-base">{date}</div>
          <div className="flex flex-row items-center text-base">
            <Icon
              name="group-layers"
              className="mx-2 w-4"
            />
            {numInstances}
          </div>
        </div>
        <div className="flex flex-row py-1">
          <div className="pr-5 text-xl text-green-19dbc4">{modalities}</div>
          <div className="truncate-2-lines break-words text-base">{description}</div>
        </div>
      </div>
      {!!trackedSeries && (
        <div className="flex-2 flex">
          <div
            className={classnames(
              'bg-green-4e6d6a mt-2 flex flex-row py-1 pl-2 pr-4 text-base text-white ',
              isActive ? 'border-black flex-1 justify-center border-t' : 'mx-4 mb-4 rounded-sm'
            )}
          >
            <Icon
              name="tracked"
              className="text-white mr-2 w-4"
            />
            {trackedSeries} Tracked Series
          </div>
        </div>
      )}
    </div>
  );
};

StudyItem.propTypes = {
  date: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  modalities: PropTypes.string.isRequired,
  numInstances: PropTypes.number.isRequired,
  trackedSeries: PropTypes.number,
  isActive: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default StudyItem;
