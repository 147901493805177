import { useState } from 'react';

export class ApiLoading {
  constructor() {
    [this.isLoading, this.setLoading] = useState(false);
  }

  isLoading: boolean;
  private setLoading: React.Dispatch<React.SetStateAction<boolean>>;

  startLoading() {
    this.setLoading(true);
  }

  stopLoading() {
    this.setLoading(false);
  }
}

export const doApi = async (
  fn: () => Promise<void>, //
  apiLoading: ApiLoading,
  errMsg: string,
  options?: Api.Options
) => {
  if (apiLoading.isLoading) {
    return;
  }

  apiLoading.startLoading();
  try {
    await fn();
    await options?.successCallback?.();
  } catch (error) {
    console.error(errMsg, error);
    options?.errorCallback?.(error);
  } finally {
    apiLoading.stopLoading();
  }
};
