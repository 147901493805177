import { useOpenState } from '@/hooks';
import { useState } from 'react';
import { uploadDicomHelper, jsonClone, dicomHelper, sleepAtLeast } from '@/utils';
import type { UploadStudyInfoItem, NormalizeDicomInfo, Encoding } from '@/utils';
import { apiPutDicomInfo } from '@/api/dicom';
import { utils } from '@ohif/core';

let selectedFiles: any = [];

export class UploadDicomVM {
  constructor() {
    this.modal = useOpenState();
    [this.isParseDicomLoading, this.setIsParseDicomLoading] = useState(false);
    [this.studyInfoList, this.setStudyInfoList] = useState<UploadStudyInfoItem[]>([]);
    [this.encoding, this._setEncoding] = useState<string | null>(null);

    const saveAccountData = utils.cookieHelper.getLoginAccountData();
    this.unitId = saveAccountData.hospitalCode;
  }

  unitId = '';
  fileList: any = [];
  modal: ReturnType<typeof useOpenState>;

  /**
   * 解析 dicom 檔案 loading
   */
  isParseDicomLoading: boolean;
  setIsParseDicomLoading: React.Dispatch<React.SetStateAction<boolean>>;

  /**
   * 解析的 study 清單
   */
  studyInfoList: UploadStudyInfoItem[];
  setStudyInfoList: React.Dispatch<React.SetStateAction<UploadStudyInfoItem[]>>;

  encoding: string;
  private _setEncoding: React.Dispatch<React.SetStateAction<string>>;

  encodingOptionList = [
    { value: null, label: 'default', key: 'default' },
    { value: 'utf-8', label: 'utf-8', key: 'utf-8' },
    { value: 'Big5', label: 'big5', key: 'big5' },
  ];

  /**
   * 選擇編碼格式
   */
  async selectEncoding(encoding) {
    if (encoding === this.encoding) {
      return;
    }

    this._setEncoding(encoding);
    await this.selectUploadFolder(selectedFiles, encoding);
  }

  /**
   * 選擇資料夾
   */
  async selectUploadFolder(fileList: FileList, encoding?: Encoding) {
    selectedFiles = fileList;
    this.setIsParseDicomLoading(true);
    this.modal.open();

    const fn = async () => {
      const studyInfoList = await uploadDicomHelper.parseDicomFileList(fileList, encoding);
      this.setStudyInfoList(studyInfoList);
    };

    await sleepAtLeast(fn, 1000);

    this.setIsParseDicomLoading(false);
  }

  /**
   * 移除 study
   */
  removeStudy(arrIndex: number) {
    const copyArr = jsonClone(this.studyInfoList) as UploadStudyInfoItem[];
    copyArr.splice(arrIndex, 1);
    this.setStudyInfoList(copyArr);
  }

  /**
   * 關閉上傳彈窗
   */
  closeUploadModal() {
    this.modal.close();
    uploadDicomHelper.reset();
    this.reset();
  }

  /**
   * 更新 study tag
   */
  updateStudyTag(data: {
    arrIndex: number;
    tag: 'study_id' | 'patient_id' | 'patient_name';
    value: string;
  }) {
    const copyArr = jsonClone(this.studyInfoList) as UploadStudyInfoItem[];
    copyArr[data.arrIndex][data.tag] = data.value;
    this.setStudyInfoList(copyArr);
  }

  /**
   * 上傳 dicom 檔案清單
   */
  async uploadDicomList() {
    for (let i = 0; i < this.studyInfoList.length; i++) {
      const studyInfo = this.studyInfoList[i];

      for (let filePathIndex = 0; filePathIndex < studyInfo.files.length; filePathIndex++) {
        const filePath = studyInfo.files[filePathIndex];
        const binaryData = uploadDicomHelper.binaryDataMap[filePath];

        const { files, ...info } = studyInfo;

        const { finalBinaryData, dicomInfo } = await uploadDicomHelper.normalize_binary(
          binaryData,
          {
            ...info,
            study_id: info.study_id ?? '',
            patient_name: info.patient_name ?? '',
            patient_id: info.patient_id ?? '',
          }
        );

        const { isUploadSuccess } = await this._uploadDicomToGoogle(finalBinaryData, dicomInfo);

        const { study_instance_uid, series_instance_uid, sop_instance_uid, ...restParams } =
          dicomInfo;

        // 更新 backend
        if (isUploadSuccess) {
          await apiPutDicomInfo(
            {
              unitId: this.unitId,
              studyInstanceUid: dicomInfo.study_instance_uid,
              seriesUid: dicomInfo.series_instance_uid,
              sopUid: dicomInfo.sop_instance_uid,
            },
            restParams
          );
        }
      }
    }
  }

  private _checkStatusCodeSuccess(statusCode: number) {
    return statusCode >= 200 && statusCode <= 299;
  }

  /**
   * 上傳 dicom 到 google
   */
  private async _uploadDicomToGoogle(binaryData, dicomInfo: NormalizeDicomInfo) {
    let statusCode = 200;
    try {
      const res = await dicomHelper.uploadStudy(binaryData);
      statusCode = res.status;
    } catch (error) {
      statusCode = error?.response?.status;
    }

    // 有衝突，先刪除 dicom instance 再上傳
    if (statusCode === 409) {
      await dicomHelper.deleteStudy(dicomInfo.study_instance_uid, {
        series_uid: dicomInfo.series_instance_uid,
        instance_uid: dicomInfo.sop_instance_uid,
      });

      const res = await dicomHelper.uploadStudy(binaryData);
      statusCode = res.status;
    }

    const isUploadSuccess = this._checkStatusCodeSuccess(statusCode);

    return { isUploadSuccess };
  }

  reset() {
    this._setEncoding(null);
  }
}
