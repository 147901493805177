import dayjs, { extend } from 'dayjs';
import utc from 'dayjs/plugin/utc';

extend(utc);

class TimeFormatHelper {
  /**
   * 格式化 utc 時間顯示
   */
  formatUtcTime(utcTime: number | string, formatString = 'YYYY-MM-DD HH:mm:ss') {
    if (!utcTime) {
      return '';
    }
    return dayjs.utc(utcTime).local().format(formatString);
  }

  /**
   * 格式化時間
   */
  formatTime(time: number | string, formatString = 'YYYY-MM-DD HH:mm:ss') {
    if (!time) {
      return '';
    }
    return dayjs(time).format(formatString);
  }
}

export const timeFormatHelper = new TimeFormatHelper();
