import React from 'react';
import { PacsButton } from '@/components/common/PacsButton';
import { StudyListMainVM } from '@/viewModel/StudyList/StudyListMainVM';
import { useDialog } from '@/components/Dialog/SmartPacsDialogProvider';
import { Loading } from '@/components/common/Loading/Loading';
import { InputFilter } from './InputFilter';
import { getDefaultStudyOption } from '@/viewModel/StudyList/StudyListFilterVM';
import { StudyDayRadioOptionList } from '@/components/StudyDayRadioOptionList/StudyDayRadioOptionList';

const StudyListFilter = ({ studyListMainVM }: Props) => {
  const { studyListFilterVM } = studyListMainVM;
  const { dialog } = useDialog();
  const defaultStudyOption = getDefaultStudyOption();

  /**
   * 刪除 study 清單
   */
  const deleteStudyList = async () => {
    const successCallback = () => {
      dialog.update({
        icon: 'none',
        title: '',
        content: '刪除完成',
        positiveText: '確認',
        negativeText: '',
        isLoading: false,
        isHideButton: false,
      });
    };

    const errorCallback = () => {
      dialog.update({
        icon: 'alert',
        title: '',
        content: '刪除失敗',
        positiveText: '關閉',
        negativeText: '',
        isLoading: false,
        isHideButton: false,
      });
    };

    const onPositiveClick = async () => {
      dialog.update({
        icon: 'none',
        title: <Loading className="block w-[24px] h-[24px]" />,
        content: (
          <p className="text-[14px]">檔案刪除中，正在同步更新資料庫，請完成後再進行瀏覽...</p>
        ),
        isHideButton: true,
      });

      await studyListMainVM.deleteStudyList({
        successCallback,
        errorCallback,
      });

      return { shouldClose: false };
    };

    dialog.open({
      icon: 'alert',
      content: '提醒：刪除後將無法復原，是否確定刪除？',
      positiveText: '刪除',
      onPositiveClick,
    });
  };

  return (
    <React.Fragment>
      <div>
        <div className="px-[12px] py-[17px] flex i-center justify-between min-h-[62px]">
          <div className="flex i-center">
            {studyListMainVM.getHasCheckStudy() && (
              <PacsButton
                className="w-[124px] h-[28px] mr-[8px]"
                onClick={deleteStudyList}
              >
                <span className="text-green-182c2a text-[14px] font-bold">Delete</span>
              </PacsButton>
            )}

            {!studyListFilterVM.checkIsFilterMatchDefault() && (
              <PacsButton
                className="w-[172px] h-[28px]"
                theme="secondary"
                onClick={() => studyListFilterVM.resetFilterAndSort()}
              >
                Clear Filter
              </PacsButton>
            )}
          </div>

          <StudyDayRadioOptionList
            defaultDayOption={defaultStudyOption}
            onClickDayOption={option => studyListMainVM.checkStudyOption(option.key)}
          />
        </div>
      </div>
      <div className="w-full mx-auto border-b border-black">
        <div className="bg-green-182c2a pt-3 pb-3">
          <InputFilter studyListMainVM={studyListMainVM} />
        </div>
      </div>
    </React.Fragment>
  );
};

interface Props {
  studyListMainVM: StudyListMainVM;
}

export default StudyListFilter;
