export class CycleController<T> {
  constructor(list: T[]) {
    this.list = list;
  }

  list: T[];
  private cycleIndex = 0;

  getNext() {
    const nextCycleIndex = (this.cycleIndex + 1) % this.list.length;
    const nextMode = this.list[nextCycleIndex];
    this.cycleIndex = nextCycleIndex;
    return nextMode;
  }

  back() {
    if (this.cycleIndex <= 0) {
      return;
    }

    this.cycleIndex--;
  }

  reset() {
    this.cycleIndex = 0;
  }
}
