import { cookieHelper } from './cookie';
import { apiGetDicomToken } from '@/api/dicom';

/**
 * 刷新 dicom token
 */
export const refreshDicomToken = async (options: {
  successCallback: (dicomToken: string) => Promise<void> | void;
}) => {
  try {
    const { apiToken } = cookieHelper.getApiTokenFromCookie();
    if (!apiToken) {
      return;
    }

    const res = await apiGetDicomToken(apiToken);
    const dicomToken = res.data.data.access_token;
    options.successCallback(dicomToken);
  } catch (error) {
    console.error('refresh get dicom token error:', error);
  }
};
