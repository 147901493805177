import React, { useEffect } from 'react';
import classNames from 'classnames';
import { DeviceCard } from '../DeviceCard/DeviceCard';
import { HubStatusVM } from '@/viewModel/setting/HubStatusVM';

type Props = {
  className?: string;
  children?: React.ReactNode;
};

export const HubStatusTab = ({ className }: Props) => {
  const hubStatusVM = new HubStatusVM();

  useEffect(() => {
    hubStatusVM.intervalRefreshDeviceList();

    return () => {
      hubStatusVM.destroyIntervalRefresh();
    };
  }, []);

  return (
    <div
      className={classNames(
        'py-[42px] pb-[40px] px-[60px] max-xl:px-[36px] max-960:px-[24px]',
        className
      )}
    >
      <div className="w-full grid grid-cols-4 gap-x-[30px] gap-y-[30px] max-1440:grid-cols-3 max-960:grid-cols-2 max-sm:grid-cols-1">
        {hubStatusVM.deviceList.map((item, i) => (
          <DeviceCard
            key={`deviceCard_${i}`}
            deviceItem={item}
          />
        ))}
      </div>
    </div>
  );
};
