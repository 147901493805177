import React from 'react';
import classNames from 'classnames';

/*
  請在父層給予 relative
*/

type Props = {
  className?: string;
  children?: React.ReactNode;
  onSelectFolder?: (fileList: FileList) => Promise<void> | void;
};

export const FolderUpload = ({ className, onSelectFolder = () => {} }: Props) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    onSelectFolder(fileList);
  };

  const resetInput = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    // @ts-expect-error reset input
    e.target.value = '';
  };

  return (
    <div
      className={classNames(
        'absolute left-0 top-0 z-[1] w-full h-full opacity-0 cursor-pointer',
        className
      )}
    >
      <input
        type="file"
        id="folder"
        // @ts-expect-error Property 'webkitdirectory' does not exist
        webkitdirectory="true"
        multiple
        className="block w-full h-full opacity-0 cursor-pointer text-[0px] text-transparent"
        onClick={resetInput}
        onChange={handleChange}
        title=""
      />
    </div>
  );
};
