import { useState } from 'react';
import { RadioOptionItem } from './RadioOptionList';
import { utils } from '@ohif/core';

export const useRadioOptionList = <T>(
  initTabList: RadioOptionItem<T>[],
  options?: { initialKey: T }
) => {
  const [optionList, setOptionList] = useState<RadioOptionItem<T>[]>(initTabList);

  const handleClickTab = (arrIndex: number) => {
    let copyList = utils.jsonClone(optionList) as RadioOptionItem<T>[];
    copyList = copyList.map(item => {
      return { ...item, isCheck: false };
    });
    copyList[arrIndex].isCheck = true;
    setOptionList(copyList);

    return copyList[arrIndex];
  };

  return {
    tabList: optionList,
    handleClickTab,
  };
};
