import { useState } from 'react';

export class StudySizeVM {
  constructor() {
    [this._data, this._setData] = useState({});
  }

  private _data: any;
  private _setData: React.Dispatch<React.SetStateAction<any>>;

  /**
   * 新增或更新
   */
  addOrUpdateSize(studyInstanceUid: string, gbSize: number) {
    const copyData = { ...this._data };
    copyData[studyInstanceUid] = gbSize;
    this._setData(copyData);
  }

  /**
   * 更新整個資料
   */
  updateWholeData(data: any) {
    this._setData(data);
  }

  /**
   * 移除
   */
  removeSize(studyInstanceUid) {
    const { [studyInstanceUid]: _, ...rest } = this._data;
    this._setData(rest);
  }

  /**
   * 取得總大小
   */
  getTotalSize() {
    let total = 0;
    for (const keyName in this._data) {
      const size = this._data[keyName];
      total += size;
    }

    return +total.toFixed(2);
  }

  /**
   * 是否超過容量大小 (單位 gb)
   */
  get isOverSize() {
    return this.getTotalSize() > 1;
  }

  /**
   * 重置
   */
  reset() {
    this._setData({});
  }
}
