import React, { useContext, useEffect } from 'react';
import { SettingMainViewModel } from '@/viewModel/setting/SettingMainViewModel';
import { smartPacsAuthContext } from '@/SmartPacsProvider/SmartPacsAuthProvider';
import { UserSelfForm } from '../UserSelfForm';
import { AccountList } from '../AccountList';
import { Progress } from '@mantine/core';
import classNames from 'classnames';
import { PacsButton } from '@/components/common/PacsButton';
import { webCacheHelper } from '@/utils';
import { useDialog } from '@/components/Dialog/SmartPacsDialogProvider';
import { Svg } from '@ohif/ui';

type Props = {
  settingMainViewModel?: SettingMainViewModel;
};

export const SettingTabItem = ({ settingMainViewModel = new SettingMainViewModel() }: Props) => {
  const { isManager, initUserSelfData } = useContext(smartPacsAuthContext);
  const { userSelfFormViewModel } = settingMainViewModel;
  const { diskSpace } = settingMainViewModel;
  const { dialog } = useDialog();

  useEffect(() => {
    settingMainViewModel.getAppVersion();
    settingMainViewModel.getUnitInfo();
  }, []);

  const clearWebData = () => {
    const onNegativeClick = async () => {
      await webCacheHelper.clearAll();
    };

    dialog.open({
      icon: 'alert',
      isBtnHorizontal: true,
      content: (
        <p className="text-center">
          Delete Web Data <br />
          You will be logged out of SmartPACS after deleting web data. No saved DICOM will be
          removed by this action. <br />
          在刪除網站數據後，您將從 SmartPACS 登出。這個功能不會刪除任何已保存的影像資料。
        </p>
      ),
      positiveText: 'Cancel',
      negativeText: 'Delete',
      onNegativeClick,
    });
  };

  return (
    <div className="flex w-full pt-[56px] pb-[15px] pl-[60px] pr-[75px] max-xl:px-[30px] max-lg:block">
      {/* left */}
      <div className="w-full min-w-[360px] max-w-[440px] mr-[110px] max-xl:mr-[40px] max-lg:mb-[32px]">
        <UserSelfForm
          userSelfFormViewModel={userSelfFormViewModel}
          onSaveName={initUserSelfData}
        />

        <div className="w-full grid grid-cols-2 mb-[24px]">
          <div>
            <p>Version</p>
            {settingMainViewModel.systemInfo && <p>{settingMainViewModel.systemInfo.version}</p>}
          </div>
          <div>
            <p>Support</p>
            <a
              href="mailto:support@hugg.com.tw"
              className="cursor-pointer"
            >
              <p className="underline">support@hugg.com.tw</p>
            </a>
          </div>
        </div>

        {diskSpace && (
          <div className="w-full text-[12px] mb-[24px]">
            <div className="flex items-end justify-between mb-[8px]">
              <p className="text-[14px]">Storage</p>
              <p>Total {diskSpace.maxGb} GB</p>
            </div>
            <div className={classNames('mb-[8px]', diskSpace.currentStatus)}>
              <Progress
                color="#19DBC4"
                value={diskSpace.usedPercentage}
              />
            </div>
            <div className="flex i-center justify-between">
              <p
                className={classNames([
                  diskSpace.currentStatus === 'normal' && 'text-green-19dbc4',
                  diskSpace.currentStatus === 'warning' && 'text-orange-ea6a3d',
                  diskSpace.currentStatus === 'alert' && 'text-red-ed1c24',
                ])}
              >
                {diskSpace.currentUsedGb} GB USED ({diskSpace.usedPercentage}%)
              </p>
              <p
                className={classNames([
                  diskSpace.currentStatus === 'normal' && 'text-gray-666',
                  diskSpace.currentStatus === 'warning' && 'text-orange-ea6a3d',
                  diskSpace.currentStatus === 'alert' && 'text-red-ed1c24',
                ])}
              >
                {diskSpace.leftGb} GB LEFT ({diskSpace.leftPercentage}%)
              </p>
            </div>
          </div>
        )}

        <div className="w-full flex justify-end">
          <PacsButton
            theme="warning"
            className="h-[46px] min-w-[155px]"
            isLoading={false}
            onClick={clearWebData}
          >
            <Svg
              name="icDelete"
              className="mr-[4px] block w-[12px] h-[12px]"
            />
            <span className="text-[14px]">Delete Web Data</span>
          </PacsButton>
        </div>
      </div>

      {/* right */}
      {isManager && <AccountList settingMainViewModel={settingMainViewModel} />}
    </div>
  );
};
