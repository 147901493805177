import { createBlobUrl, revokeBlobUrl } from '@/utils';

class DownloadHelper {
  downloadFileByUrl(url: string, fileName?: string) {
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    if (fileName) {
      a.setAttribute('download', fileName);
    }
    document.body.appendChild(a);
    a.click();
    revokeBlobUrl(url);
    a.remove();
  }

  downloadFile(blobOrFile: Blob | File, fileName?: string) {
    const url = createBlobUrl(blobOrFile);
    this.downloadFileByUrl(url, fileName);
  }

  /**
   * reference
   *
   * https://stackoverflow.com/a/14011075
   */
  downloadFileByBase64String(base64String: string, fileName?: string) {
    this.downloadFileByUrl('data:application/octet-stream;base64,' + base64String, fileName);
  }
}

export const downloadHelper = new DownloadHelper();
