import React from 'react';
import classNames from 'classnames';
import { PacsButton } from '@/components/common/PacsButton';

type Props = {
  className?: string;
  children?: React.ReactNode;
  title?: React.ReactNode;
  content?: React.ReactNode;
  isDisabled?: boolean;
  onClickResetPassword?: () => Promise<void> | void;
  onClickAccountStatus?: () => Promise<void> | void;
  onClickRecoverAccount?: () => Promise<void> | void;
};

export const AccountCard = ({
  className,
  title = '',
  content = '',
  isDisabled = false,
  onClickResetPassword = () => {},
  onClickAccountStatus = () => {},
  onClickRecoverAccount = () => {},
}: Props) => {
  return (
    <div
      className={classNames(
        'flex justify-between rounded-[16px] px-[24px] py-[18px]',
        isDisabled ? 'bg-green-182c2a text-gray-f9f9f9' : 'bg-gray-f9f9f9 text-gray-202020',
        className
      )}
    >
      <div>
        <h3 className="flex items-end text-[20px] font-bold leading-[22px] mb-[8px]">{title}</h3>

        <p className="text-gray-666 text-[14px] font-bold">{content}</p>
      </div>

      {isDisabled && (
        <div className="flex items-start">
          <PacsButton
            theme="cancel"
            className="block w-[88px] h-[28px] mr-[10px]"
            onClick={onClickRecoverAccount}
          >
            Suspended
          </PacsButton>
        </div>
      )}

      {!isDisabled && (
        <div className="flex items-start">
          <PacsButton
            theme="cancel"
            className="block w-[88px] h-[28px] mr-[10px]"
            onClick={onClickAccountStatus}
          >
            Status
          </PacsButton>

          <PacsButton
            theme="confirm"
            className="block px-[12px] h-[28px]"
            onClick={onClickResetPassword}
          >
            Reset Password
          </PacsButton>
        </div>
      )}
    </div>
  );
};
