import React from 'react';
import classNames from 'classnames';
import { Checkbox } from '@/components/common/Checkbox';
import { ShareStudyVM } from '@/viewModel/ShareStudy/ShareStudyVM';
import { getStudyDate } from '@/utils';

type TableCellProps = {
  children: React.ReactNode;
};

export const TableCell = ({ children }: TableCellProps) => {
  return (
    <li
      className={classNames(
        'table-cell relative px-[10px] border-r border-r-green-182c2a border-b border-b-green-182c2a last-of-type:border-r-0 align-middle h-[42px]'
      )}
    >
      {children}
    </li>
  );
};

interface HeadItem {
  label: string;
  key: string;
  style: React.CSSProperties;
}

type Props = {
  className?: string;
  shareStudyVM: ShareStudyVM;
};

export const ShareStudyTable = ({ className, shareStudyVM }: Props) => {
  const headList: HeadItem[] = [
    { key: 'select', label: '', style: { width: '60px', minWidth: '60px ', maxWidth: '60px' } },
    { key: 'name', label: 'Name', style: { width: '50%' } },
    { key: 'time', label: 'Time', style: { width: '50%' } },
  ];

  return (
    <div
      className={classNames(
        'relative w-full rounded-[8px] border-separate overflow-x-auto scrollbar-primary',
        className
      )}
    >
      <div className="table w-full">
        <div className="table-row-group">
          {/* head */}
          <ul className="table-row w-full bg-primary-default bg-green-19a698">
            {headList.map(headItem => (
              <li
                key={headItem.key}
                style={headItem.style}
                className={classNames([
                  'table-cell font-bold text-[14px] h-[40px] border-r border-green-182c2a last-of-type:border-r-0 text-white align-middle',
                ])}
              >
                {headItem.key === 'select' ? (
                  <div
                    className="w-full h-[42px] flex-center cursor-pointer"
                    onClick={() => shareStudyVM.checkAllStudy()}
                  >
                    <Checkbox isCheck={false} />
                  </div>
                ) : (
                  <div className="px-[8px]">{headItem.label}</div>
                )}
              </li>
            ))}
          </ul>

          {shareStudyVM.studyList.map((study, i) => {
            const { studyDate, studyTime } = getStudyDate(study.date, study.time);

            const dateTime = (
              <>
                {studyDate && <span className="mr-4">{studyDate}</span>}
                {studyTime && <span>{studyTime}</span>}
              </>
            );

            return (
              <ul
                key={study.studyInstanceUid}
                className={classNames([
                  'table-row w-full bg-green-4e6d6a cursor-pointer hover:bg-green-19a698',
                ])}
                onClick={() => shareStudyVM.checkItem(i)}
              >
                <TableCell>
                  <div className="w-full h-[42px] flex-center cursor-pointer">
                    <Checkbox isCheck={study.isCheck} />
                  </div>
                </TableCell>

                <TableCell>{study.patientName}</TableCell>

                <TableCell>{dateTime}</TableCell>
              </ul>
            );
          })}
        </div>
      </div>
    </div>
  );
};
