import { fetchRequest } from '@/api/helper';
import { BaseApi } from '@/api/base/BaseApi';

class ExportApi extends BaseApi {
  /**
   * 匯出 study
   */
  exportStudy(data: { patientId: string; study_uid_list: string[] }) {
    const { patientId, ...restData } = data;
    return fetchRequest<{ data: Api.ShareDicom }>(`/export/${patientId}`, {
      method: 'post',
      data: restData,
      headers: this.getAuthHeader(),
    });
  }

  /**
   * 將匯出檔案下載網址寄送至email
   */
  sendMail(data: {
    email: string;
    patient_id: string;
    patient_name: string;
    download_url?: string;
    share_link_url?: string;
  }) {
    return fetchRequest('/export/send_mail', {
      method: 'post',
      data,
      headers: this.getAuthHeader(),
    });
  }
}

export const exportApi = new ExportApi();
