import React, { useEffect } from 'react';
import { Header } from '@ohif/ui';
import { ProgressLoadingBar } from '@ohif/ui';
import { PatientRedirectVM } from '@/viewModel/PatientRedirectVM';
import { useSearchParams } from '@/hooks';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@ohif/ui';

type Props = {
  className?: string;
  children?: React.ReactNode;
};

export const PatientRedirect = ({ className }: Props) => {
  const patientDicomVM = new PatientRedirectVM();
  const query = useSearchParams();
  const navigate = useNavigate();
  const { toastShow } = useToast();

  const goToViewer = (studyInstanceUid: string) => {
    navigate(`/viewer?StudyInstanceUIDs=${studyInstanceUid}`, { replace: true });
  };

  const errorCallback = () => {
    navigate('/');
    toastShow({
      type: 'error',
      title: 'Data Error',
      message: 'No image of this patient.',
    });
  };

  const onNoContent = () => {
    errorCallback();
  };

  /**
   * 取得 patient 第一筆 study 到 viewer
   */
  const getPatientFirstStudyToViewer = async () => {
    const patientId = query.get('patientId');
    if (!patientId) {
      return;
    }

    const study = await patientDicomVM.getPatientStudyList(patientId, {
      successCallback() {},
      onNoContent,
      errorCallback,
    });

    if (!study) {
      return;
    }

    goToViewer(study.studyInstanceUid);
  };

  useEffect(() => {
    getPatientFirstStudyToViewer();
  }, []);

  return (
    <div>
      <div>
        <Header isReturnEnabled={false} />

        {/*
          loading
        */}
        <div
          className="w-full h-full pt-[24px] pl-[18px] pr-[60px] bg-gray-111 text-white overflow-y-auto scrollbar-primary"
          style={{ height: 'calc(100vh - 60px' }}
        >
          <div className="flex flex-col items-center justify-center pt-48">
            <div className="w-full max-w-[192px]">
              <ProgressLoadingBar />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
