import { dicomHelper, compareHelper, DataCache, sizeConvert, sleepAtLeast } from '@/utils';
import { doApi, ApiLoading } from '../helper/doApi';
import { useState } from 'react';
import { processStudyResults, validation, copyHelper, statusCodeHelper } from '@/utils';
import { utils } from '@ohif/core';
import { exportApi } from '@/api/export';
import { StudySizeVM } from './StudySizeVM';
import { shareLinkApi } from '@/api/shareLink';
import { MenuVM } from '@/viewModel/common/MenuVM';
import { useToast } from '@ohif/ui';
import { ShareDownloadVM } from './ShareDownloadVM';

const patientIdTag = '00100020';

interface FormData {
  isCheckEmail: boolean;
  isSendEmail: boolean;
  errorMsg: string;
  email: string;
  patientId: string;
}

const sizeCache = new DataCache();

const createDefaultFormData = (): FormData => {
  return {
    isCheckEmail: false,
    isSendEmail: false,
    email: '',
    errorMsg: '',
    patientId: '',
  };
};

export class ShareStudyVM {
  constructor() {
    [this.studyList, this.setStudyList] = useState<WorkListStudy[]>([]);
    [this.formData, this.setFormData] = useState(createDefaultFormData());
    [this.qrCodeSrc, this.setQrcodeSrc] = useState('');
    [this.shareLinkUrl, this.setShareLinkUrl] = useState('');
    [this.currentPatientName, this.setCurrentPatientName] = useState('');
    [this.studySizeLoading, this.setStudySizeLoading] = useState(false);
    [this.isDownloadMultpleDicom, this.setIsDownloadMultpleDicom] = useState(false);

    this.toast = useToast();
  }

  toast: ReturnType<typeof useToast>;

  menuVM = new MenuVM([
    { key: 'menu' },
    { key: 'viewOnly' },
    { key: 'viewAndDownload' },
    { key: 'DownloadOthers' },
  ]);

  studySizeLoading: boolean;
  dataLoading = new ApiLoading();
  actionLoading = new ApiLoading();
  sizeLoading = new ApiLoading();
  shareLoading = new ApiLoading();
  studyList: WorkListStudy[];
  formData: FormData;
  studySizeVM = new StudySizeVM();
  shareDownloadVM = new ShareDownloadVM();
  qrCodeSrc: string;
  shareLinkUrl: string;
  isDownloadMultpleDicom: boolean;

  currentUid = '';
  currentPatientName = '';

  setStudyList: React.Dispatch<React.SetStateAction<WorkListStudy[]>>;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
  setQrcodeSrc: React.Dispatch<React.SetStateAction<string>>;
  setShareLinkUrl: React.Dispatch<React.SetStateAction<string>>;
  setCurrentPatientName: React.Dispatch<React.SetStateAction<string>>;
  setStudySizeLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDownloadMultpleDicom: React.Dispatch<React.SetStateAction<boolean>>;

  initData(data: { patientId: string; studyInstanceUid: string; patientName: string }) {
    this.setIsDownloadMultpleDicom(false);
    this.shareDownloadVM.reset();
    this.menuVM.setCurrentMenuItem('menu');
    this.currentUid = data.studyInstanceUid;
    this.setCurrentPatientName(data.patientName);
    this.getPatientStudyList(data.patientId);
    this.initCurrentStudySize(data.studyInstanceUid);
    this.createShareLink(data.studyInstanceUid);
  }

  /**
   * 要填寫 email
   */
  enableEmailForm() {
    this.setFormData({ ...this.formData, isCheckEmail: true });
    this.validate();
  }

  /**
   * 初始化當前 study 檔案大小
   */
  async initCurrentStudySize(studyInstanceUid: string) {
    this.setStudySizeLoading(true);

    const gb = await this._getStudySize(studyInstanceUid);
    this.studySizeVM.updateWholeData({
      [studyInstanceUid]: gb,
    });

    this.setStudySizeLoading(false);
  }

  /**
   * 產生 share link 網址
   */
  async createShareLink(studyInstanceUid: string) {
    const fn = async () => {
      const res = await shareLinkApi.createShareLink({ study_uid: studyInstanceUid });
      this.setQrcodeSrc(`data:image/png;base64,${res.data.data.qrcode_base64_string}`);
      this.setShareLinkUrl(res.data.data.share_link_url);
    };

    await doApi(fn, this.shareLoading, 'create share link error');
  }

  /**
   * 取得此 patient 相關的 study 清單
   */
  async getPatientStudyList(patientId: string, options?: Api.Options) {
    if (!patientId) {
      console.error('no patient id for sharing dicom');
      return;
    }

    const defaultFormData = createDefaultFormData();
    this.setStudyList([]);
    this.setFormData({ ...defaultFormData, patientId: patientId });

    const fn = async () => {
      const res = await dicomHelper.getStudyList({ [patientIdTag]: patientId });
      const studyList = processStudyResults(res.data).map((study, i): WorkListStudy => {
        return {
          ...study,
          isCheck: false,
        };
      });

      const compareFunc = compareHelper.createCompareFunc([
        { fieldName: 'studyDate', order: 'desc', compareType: 'studyDate' },
      ]);

      studyList //
        .sort(compareFunc)
        .map(item => {
          if (item.studyInstanceUid === this.currentUid) {
            item.isCheck = true;
          }
          return item;
        });

      this.setStudyList(studyList);
    };

    await doApi(
      () => sleepAtLeast(fn, 500),
      this.dataLoading,
      'get patient study list error',
      options
    );
  }

  /**
   * 取得 study size
   */
  private async _getStudySize(studyInstanceUid: string): Promise<number> {
    const size = sizeCache.get(studyInstanceUid) as number;
    if (size) {
      return size;
    }

    const res = await dicomHelper.getStudyMetric(studyInstanceUid);

    const bytesSize = res.data?.blobStorageSizeBytes;
    const gb = sizeConvert.byteToGigaByte(bytesSize);
    sizeCache.set(studyInstanceUid, gb);

    return gb;
  }

  /**
   * 複製瀏覽網址
   */
  async copyShareLink() {
    const isCopySuccess = await copyHelper.clipboardCopy(this.shareLinkUrl);
    if (isCopySuccess) {
      this.toast.toastShow({ type: 'success', message: 'Copy successful', title: '' });
    }
  }

  /**
   * 勾選項目
   */
  async checkItem(arrIndex: number) {
    if (this.sizeLoading.isLoading) {
      return;
    }

    const copyList = utils.jsonClone(this.studyList) as WorkListStudy[];
    copyList[arrIndex].isCheck = !copyList[arrIndex].isCheck;
    const studyInstanceUid = copyList[arrIndex].studyInstanceUid;
    this.setStudyList(copyList);

    const isAddCheck = copyList[arrIndex].isCheck;

    const fn = async () => {
      const gb = await this._getStudySize(studyInstanceUid);

      if (isAddCheck) {
        this.studySizeVM.addOrUpdateSize(studyInstanceUid, gb);
      } else {
        this.studySizeVM.removeSize(studyInstanceUid);
      }
    };

    await doApi(() => sleepAtLeast(fn, 250), this.sizeLoading, 'get study size error');
  }

  /**
   * 全選 study
   */
  async checkAllStudy() {
    if (this.sizeLoading.isLoading) {
      return;
    }

    let copyList = utils.jsonClone(this.studyList) as WorkListStudy[];
    copyList = copyList.map(study => {
      return { ...study, isCheck: true };
    });

    this.setStudyList(copyList);

    const fn = async () => {
      const data = {};
      for (let i = 0; i < copyList.length; i++) {
        const study = copyList[i];
        const gb = await this._getStudySize(study.studyInstanceUid);
        data[study.studyInstanceUid] = gb;
      }

      this.studySizeVM.updateWholeData(data);
    };

    await doApi(() => sleepAtLeast(fn, 250), this.sizeLoading, 'get all study size error');
  }

  /**
   * 更新表單欄位
   */
  updateFormField(keyName: keyof FormData, val: string, shouldValidate = false) {
    let errorMsg = '';
    const isEmailOk = validation.isEmail(val);
    if (shouldValidate && !isEmailOk) {
      errorMsg = 'email is not valid';
    }

    this.setFormData({ ...this.formData, [keyName]: val, errorMsg });
  }

  /**
   * 切換勾選 email
   */
  toggleEmail() {
    this.setFormData({ ...this.formData, isCheckEmail: !this.formData.isCheckEmail });
  }

  /**
   * 驗證欄位
   */
  validate(): boolean {
    if (!this.formData.isCheckEmail) {
      return true;
    }

    const isEmailOk = validation.isEmail(this.formData.email);
    if (!isEmailOk) {
      this.setFormData({ ...this.formData, errorMsg: 'Invalid email format' });
    }

    return isEmailOk;
  }

  /**
   * 是否有勾選的 study
   */
  hasCheckStudy() {
    return this.studyList.some(study => study.isCheck);
  }

  /**
   * 取得勾選的 study 清單
   */
  getCheckStudyList() {
    return this.studyList.filter(study => study.isCheck);
  }

  /**
   * 分享 study 影像
   */
  async shareStudy(
    options?: Api.Options & {
      onLoading?: () => Promise<void> | void;
    }
  ) {
    const canShare = this.validate();

    if (!canShare) {
      return;
    }

    const fn = async () => {
      const patientId = this.formData.patientId;
      const uidList = this.getCheckStudyList().map(study => study.studyInstanceUid);

      options?.onLoading();
      const res = await exportApi.exportStudy({
        patientId,
        study_uid_list: uidList,
      });

      // email
      if (this.formData.isCheckEmail) {
        await exportApi.sendMail({
          patient_id: patientId,
          email: this.formData.email,
          patient_name: this.currentPatientName,
          download_url: res.data.data.download_url,
        });
      }

      this.shareDownloadVM.onShareDicomSuccess(res.data.data);
    };

    await doApi(fn, this.actionLoading, 'share study error', options);
  }

  /**
   * 寄送 dicom email 信件
   */
  async emailDicom() {
    const canShare = this.validate();

    if (!canShare) {
      return;
    }

    const shareLinkUrl = this.isDownloadMultpleDicom ? undefined : this.shareLinkUrl;

    const fn = async () => {
      const res = await exportApi.sendMail({
        patient_id: this.formData.patientId,
        email: this.formData.email,
        patient_name: this.currentPatientName,
        share_link_url: shareLinkUrl,
        download_url: this.shareDownloadVM.dicomDownloadLink,
      });

      const isSuccess = statusCodeHelper.checkStatusCodeSuccess(res.status);
      if (isSuccess) {
        this.toast.toastShow({ type: 'success', message: 'Send successful', title: '' });
      }
    };

    const errorCallback = () =>
      this.toast.toastShow({ type: 'error', message: 'Failed to send email', title: '' });

    await doApi(fn, this.actionLoading, 'email dicom error', { errorCallback });
  }

  /**
   * 重置
   */
  reset() {
    this.setStudyList([]);
    this.setFormData(createDefaultFormData());
  }
}
