export const zIndex = Object.freeze({
  SHARE_STUDY_MODAL: 99994,
  BASE_MODAL_BG: 99995,
  TOAST: 99996,
  MODALITY_WORKLIST_POPUP: 6,
});

export const cookieKey = Object.freeze({
  FILTER_DATA: 'filterData', // 首頁 worklist
  STUDY_OPTION: 'dayOption', // 首頁 worklist
  AUTH: 'auth', // 後端 api token
  DICOM: 'dicom', // google api token
  LOGIN_ACCOUNT: 'loginAccount',
  IS_SAVE_PASSWORD: 'isSavePassword',
  SETTING_PAGE_TAB: 'settingPageTab',
  MODALITY_WORKLIST_DAY_OPTION: 'dayOptionModality', // 設定頁 modality worklist
  STUDY_SEARCH: 'studySearch',
  SHARE_LINK_AUTH_DATA: 'shareLinkAuthData', // study 分享相關資料
});
