import React from 'react';
import classNames from 'classnames';
import './Loading.css';

type Props = {
  className?: string;
  children?: React.ReactNode;
};

/**
 * 請由外部給 width 和 height
 */
export const Loading = ({ className }: Props) => {
  return <div className={classNames('smart_pacs_loading', className)}></div>;
};
