import React from 'react';
import classNames from 'classnames';
import { StudyOption } from '@/components/StudyList/StudyOption';
import { PacsButton } from '@/components/common/PacsButton';
import { StudyListMainVM } from '@/viewModel/StudyList/StudyListMainVM';
import { getDefaultStudyOption } from '@/viewModel/StudyList/StudyListFilterVM';
import { useDialog } from '@/components/Dialog/SmartPacsDialogProvider';
import { Loading } from '@/components/common/Loading/Loading';
import { StudySearchInput } from '@/components/StudyList/StudySearchInput';
import { PacsNativeSelect } from '@/components/common/PacsNativeSelect';
import { Svg } from '@ohif/ui';
import { FolderUpload } from '@/components/File/FolderUpload';
import { UploadDicomModal } from '@/components/StudyList/UploadDicomModal/UploadDicomModal';

type Props = {
  className?: string;
  studyListMainVM: StudyListMainVM;
};

export const StudyListHeader = ({ className, studyListMainVM }: Props) => {
  const { studyListFilterVM, studyListTransfer, uploadDicomVM } = studyListMainVM;
  const defaultStudyOption = getDefaultStudyOption();
  const { dialog } = useDialog();

  /**
   * 刪除 study 清單
   */
  const deleteStudyList = async () => {
    const successCallback = () => {
      dialog.update({
        icon: 'none',
        title: '',
        content: '刪除完成',
        positiveText: '確認',
        negativeText: '',
        isLoading: false,
        isHideButton: false,
      });
    };

    const errorCallback = () => {
      dialog.update({
        icon: 'alert',
        title: '',
        content: '刪除失敗',
        positiveText: '關閉',
        negativeText: '',
        isLoading: false,
        isHideButton: false,
      });
    };

    const onPositiveClick = async () => {
      dialog.update({
        icon: 'none',
        title: <Loading className="block w-[24px] h-[24px]" />,
        content: (
          <p className="text-[14px]">檔案刪除中，正在同步更新資料庫，請完成後再進行瀏覽...</p>
        ),
        isHideButton: true,
      });

      await studyListMainVM.deleteStudyList({
        successCallback,
        errorCallback,
      });

      return { shouldClose: false };
    };

    dialog.open({
      icon: 'alert',
      content: '提醒：刪除後將無法復原，是否確定刪除？',
      positiveText: '刪除',
      onPositiveClick,
    });
  };

  const optionList = [
    { label: 'Last 7 Days', key: 'last7Days' },
    { label: 'Last 30 Days', key: 'last30Days' },
    { label: 'Today', key: 'today' },
    { label: 'Recent', key: 'recent' },
  ];

  return (
    <div className={classNames('w-full', className)}>
      {/*
        desktop
      */}
      <div className="w-full px-[12px] flex i-center justify-between overflow-x-auto no-scrollbar h-[62px] max-md:hidden">
        <div className="w-full flex i-center flex-nowrap">
          <StudySearchInput
            studyListMainVM={studyListMainVM}
            className="mr-[8px]"
          />

          {studyListMainVM.getHasCheckStudy() && (
            <PacsButton
              className="w-[124px] h-[36px] mr-[8px] order"
              roundSize="small"
              onClick={deleteStudyList}
            >
              <span className="text-green-182c2a text-[14px] font-bold">Delete</span>
            </PacsButton>
          )}

          {!studyListFilterVM.checkIsFilterMatchDefault() && (
            <PacsButton
              className="w-[172px] h-[36px] text-[14px] text-green-182c2a font-bold mr-[8px]"
              roundSize="small"
              onClick={() => studyListFilterVM.resetFilterAndSort()}
            >
              Clear Filter
            </PacsButton>
          )}

          <PacsButton
            theme="secondary"
            className="relative z-0 min-w-[132px] mr-[8px] h-[36px] whitespace-nowrap"
          >
            <Svg
              name="icUpload"
              className="block w-[12px] h-[12px] mr-[4px]"
            />
            Upload DICOM
            <FolderUpload onSelectFolder={fileList => uploadDicomVM.selectUploadFolder(fileList)} />
          </PacsButton>
        </div>

        <div className="whitespace-nowrap inline-flex">
          <StudyOption
            className={classNames(
              studyListMainVM.listLoading.isLoading && 'pointer-events-none',
              studyListMainVM.searchText && 'opacity-40 pointer-events-none'
            )}
            defaultStudyOption={defaultStudyOption}
            onClickDayOption={option => studyListMainVM.checkStudyOption(option.key)}
          />
        </div>
      </div>

      <div className="md:hidden flex i-center flex-wrap justify-between px-[12px] pt-[12px] pb-[4px]">
        <StudySearchInput
          studyListMainVM={studyListMainVM}
          className="mr-[8px] mb-[8px]"
        />

        <PacsNativeSelect
          optionList={optionList}
          className={classNames(
            'min-w-[120px] h-[36px] mb-[8px]',
            studyListMainVM.listLoading.isLoading && 'pointer-events-none'
          )}
          // @ts-expect-error string
          onClickOption={optionKey => studyListMainVM.checkStudyOption(optionKey)}
        >
          {studyListTransfer.displayStudyOption(studyListFilterVM.studyOption)}
        </PacsNativeSelect>
      </div>

      {uploadDicomVM.modal.isOpen && (
        <UploadDicomModal
          studyListMainVM={studyListMainVM}
          onClose={() => uploadDicomVM.closeUploadModal()}
        />
      )}
    </div>
  );
};
