import React, { createContext, useContext, useEffect } from 'react';
import { ProgressLoadingBar } from '@ohif/ui';
import { ShareLinkVM } from '@/viewModel/ShareLink/ShareLinkVM';
import { shareLinkHelper } from '@/utils';
import { useNavigate } from 'react-router-dom';

type Props = {
  children?: React.ReactNode;
};

const shareLinkVMContext = createContext<ShareLinkVM>(null);

export const useShareLinkVMContext = () => {
  const shareLinkVM = useContext(shareLinkVMContext);
  return { shareLinkVM };
};

export const ShareLinkProvider = ({ children }: Props) => {
  const shareLinkVM = new ShareLinkVM();
  const { shareToken, StudyInstanceUIDs, isShareLinkPage, isUrlHasShareParam } =
    shareLinkHelper.getCurrentUrlInfo();
  const navigate = useNavigate();

  /**
   * 從網址中移除 share 參數
   */
  const removeShareQueryParam = () => {
    if (!isUrlHasShareParam) {
      return;
    }

    navigate(`/viewer?StudyInstanceUIDs=${StudyInstanceUIDs}`);
  };

  /**
   * 解析分享影像網址
   */
  const parseShareLinkUrl = async () => {
    const data = await shareLinkVM.init(shareToken);
    window.location.replace(`/viewer?StudyInstanceUIDs=${data.study_instance_uid}&share=y`);
  };

  useEffect(() => {
    if (!isShareLinkPage) {
      removeShareQueryParam();
      return;
    }

    parseShareLinkUrl();
  }, []);

  return (
    <shareLinkVMContext.Provider value={shareLinkVM}>
      {isShareLinkPage ? (
        <div
          className="w-full h-full pt-[24px] px-[24px] bg-gray-111 text-white overflow-y-auto scrollbar-primary"
          style={{ height: '100dvh' }}
        >
          <div className="w-full flex flex-col items-center justify-center pt-48">
            <div className="w-full max-w-[192px]">
              <ProgressLoadingBar />
            </div>
          </div>
        </div>
      ) : (
        children
      )}
    </shareLinkVMContext.Provider>
  );
};
