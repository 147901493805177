/**
 * ex:
 * - studyDate 20231213 (2023年 12月 13號)
 * - studyTime 142013.000000 (14點 20分 13秒)
 *
 * return:
 * - 2023-12-13 14:20:13
 */
export const getDateStrByStudyInfo = (studyDate: string, studyTime: string) => {
  const date = {
    year: studyDate.substring(0, 4),
    month: studyDate.substring(4, 6),
    day: studyDate.substring(6, 8),
  };
  const time = {
    hour: studyTime.substring(0, 2),
    min: studyTime.substring(2, 4),
    sec: studyTime.substring(4, 6),
  };

  const dateStr = `${date.year}-${date.month}-${date.day} ${time.hour}:${time.min}:${time.sec}`;
  return dateStr;
};
