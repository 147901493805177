import { fetchRequest } from '@/api/helper';
import { utils } from '@ohif/core';

const { cookieHelper } = utils;

/**
 * 取得 modality worklist 清單
 */
export const apiGetModalityWorklist = (params: {
  unitId: string;
  patient_name?: string;
  patient_id?: string;
  ae_title?: string;
  modality?: string;
  performing_physician?: string;
  from_datetime?: string;
  to_datetime?: string;
}) => {
  const { apiToken } = cookieHelper.getApiTokenFromCookie();
  const { unitId, ...restParams } = params;

  return fetchRequest<{ data: Api.ModalityWorkListItem[] }>(`/worklist/${unitId}`, {
    params: restParams,
    headers: { Authorization: `Bearer ${apiToken}` },
  });
};
