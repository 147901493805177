import React from 'react';
import classNames from 'classnames';
import {
  TableRowProps,
  TableCellProps,
  HeadItem,
  TableProps,
} from '@/components/common/base/baseTableType';

export type { HeadItem };

export const HeadRow = ({ className, children, ...props }: TableRowProps) => {
  return (
    <ul
      className={classNames(['table-row w-full bg-green-182c2a'], className)}
      {...props}
    >
      {children}
    </ul>
  );
};

export const HeadCell = ({
  className,
  children,
  cellAlign = 'middle',
  hasPadding = false,
  ...props
}: TableCellProps) => {
  return (
    <li
      className={classNames(
        'table-cell font-bold text-[14px] h-[40px] border-r border-gray-111 last-of-type:border-r-0 text-white select-none',
        cellAlign === 'top' && 'align-top',
        cellAlign === 'middle' && 'align-middle',
        hasPadding && 'px-[10px]',
        className
      )}
      {...props}
    >
      {children}
    </li>
  );
};

export const TableRow = ({ className, children, isDisabled = false, ...props }: TableRowProps) => {
  return (
    <ul
      className={classNames(
        [
          'table-row w-full cursor-pointer',
          isDisabled && 'bg-gray-666 text-gray-898989',
          !isDisabled && 'bg-gray-2b2b2b hover:bg-gray-666',
        ],
        className
      )}
      {...props}
    >
      {children}
    </ul>
  );
};

export const TableCell = ({
  className,
  children,
  cellAlign = 'middle',
  hasPadding = true,
  ...props
}: TableCellProps) => {
  return (
    <li
      className={classNames(
        'table-cell relative border-r border-r-gray-111 border-b border-b-gray-111 last-of-type:border-r-0 h-[42px]',
        cellAlign === 'top' && 'align-top',
        cellAlign === 'middle' && 'align-middle',
        hasPadding && 'px-[10px] max-md:px-[6px]',
        className
      )}
      {...props}
    >
      {children}
    </li>
  );
};

export const PrimaryTable = ({
  isCustomHeader = false,
  isHeightFull = false,
  customHeader,
  children,
  className,
  headList,
}: TableProps) => {
  return (
    <div
      className={classNames(
        'relative w-full border-separate overflow-x-auto scrollbar-primary',
        isHeightFull && 'h-full',
        className
      )}
    >
      <div className={classNames('table w-full')}>
        <div className="table-row-group">
          {/*
            head
          */}
          {!isCustomHeader && (
            <HeadRow>
              {headList.map(headItem => (
                <HeadCell
                  key={headItem.key}
                  className="px-[8px]"
                  style={headItem.style}
                >
                  {headItem.label}
                </HeadCell>
              ))}
            </HeadRow>
          )}

          {isCustomHeader && <>{customHeader}</>}

          {/*
            body
          */}
          {children}
        </div>
      </div>
    </div>
  );
};
