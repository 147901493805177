import React from 'react';
import classNames from 'classnames';
import { HeadItem, HeadRow, HeadCell } from '@/components/common/PrimaryTable';
import { Svg } from '@ohif/ui';
import { TextInput } from '@mantine/core';
import { StudyListMainVM } from '@/viewModel/StudyList/StudyListMainVM';
import type { SortFieldName, StudyFilterField } from '@/viewModel/StudyList/StudyListFilterVM';
import { useWindowSize } from '@/components/provider/WindowResizeProvider';

type Props = {
  className?: string;
  children?: React.ReactNode;
  studyListMainVM: StudyListMainVM;
};

type StudyHeadItem = HeadItem<SortFieldName> & { canFilter: boolean; canSort: boolean };

const headList: StudyHeadItem[] = [
  {
    key: 'isCheck',
    label: '',
    style: { width: '60px', minWidth: '60px', maxWidth: '60px' },
    canFilter: false,
    canSort: false,
  },
  {
    key: 'patient_name',
    label: 'Patient Name',
    style: { minWidth: '120px' },
    canFilter: true,
    canSort: true,
  },
  { key: 'patient_id', label: 'MRN', style: { minWidth: '120px' }, canFilter: true, canSort: true },
  {
    key: 'study_datetime',
    label: 'Study Date',
    style: { minWidth: '185px' },
    canFilter: false,
    canSort: true,
  },
  {
    key: 'patient_telephone_number',
    label: 'Phone No.',
    style: { minWidth: '135px' },
    canFilter: true,
    canSort: false,
  },
  {
    key: 'study_description',
    label: 'Description',
    style: { minWidth: '120px' },
    canFilter: true,
    canSort: true,
  },
  {
    key: 'modalities',
    label: 'Modalities',
    style: { minWidth: '120px' },
    canFilter: true,
    canSort: true,
  },
  {
    key: 'accession_number',
    label: 'Accession #',
    style: { minWidth: '120px' },
    canFilter: true,
    canSort: true,
  },
  {
    key: 'instances',
    label: 'Instances',
    style: { minWidth: '120px' },
    canFilter: false,
    canSort: true,
  },
  {
    key: 'info',
    label: '',
    style: { width: '55px', minWidth: '55px', maxWidth: '55px' },
    canFilter: false,
    canSort: false,
  },
  {
    key: 'share',
    label: '',
    style: { width: '55px', minWidth: '55px', maxWidth: '55px' },
    canFilter: false,
    canSort: false,
  },
];

const mobileHeadList = [
  {
    key: 'patient_name',
    label: 'Name',
    style: { minWidth: '85px' },
    canFilter: true,
    canSort: true,
  },
  {
    key: 'patient_id',
    label: 'MRN',
    style: { minWidth: '85px' },
    canFilter: true,
    canSort: true,
  },
  {
    key: 'study_datetime',
    label: 'Time',
    style: { width: '105px', minWidth: '105px', maxWidth: '105px' },
    canFilter: false,
    canSort: true,
  },
  {
    key: 'modalities',
    label: '',
    style: { width: '60px', minWidth: '60px', maxWidth: '60px' },
    canFilter: true,
    canSort: true,
  },
];

export const StudyTableHead = ({ studyListMainVM }: Props) => {
  const { studyListFilterVM } = studyListMainVM;
  const { isMobile } = useWindowSize();

  const tableHeadList = isMobile ? mobileHeadList : headList;

  const checkArrowActive = (fieldName: SortFieldName, direction: Compare.Order) => {
    const isSameField = studyListFilterVM.currentSortData.fieldName === fieldName;
    const isSameDirection = studyListFilterVM.currentSortData.direction === direction;
    return isSameField && isSameDirection;
  };

  return (
    <HeadRow className="sticky z-[1] top-0 max-md:static max-md:top-auto">
      {tableHeadList.map(headItem => (
        <HeadCell
          cellAlign="top"
          key={headItem.key}
          className={classNames(
            'px-[12px] pt-[6px] pb-[12px]',
            headItem.canSort && 'cursor-pointer'
          )}
          style={headItem.style}
          onClick={() => {
            if (!headItem.canSort) {
              return;
            }
            studyListFilterVM.handleClickDirection(headItem.key);
          }}
        >
          <div className={classNames('w-full flex i-center justify-between mb-[8px]')}>
            <div className="truncate">{headItem.label}</div>

            {headItem.canSort && (
              <div className="h-full flex flex-col justify-center">
                <Svg
                  name="icTriangle"
                  className={classNames('mb-[6px] block w-[10px] h-[6px]', [
                    checkArrowActive(headItem.key, 'asc') ? 'text-green-19dbc4' : 'text-gray-666',
                  ])}
                />
                <Svg
                  name="icTriangle"
                  className={classNames('transform rotate-180 block w-[10px] h-[6px]', [
                    checkArrowActive(headItem.key, 'desc') ? 'text-green-19dbc4' : 'text-gray-666',
                  ])}
                />
              </div>
            )}
          </div>
          {headItem.canFilter && (
            <div
              className="w-full"
              onClick={e => e.stopPropagation()}
            >
              <TextInput
                value={studyListFilterVM.filterData[headItem.key]}
                onChange={event => {
                  const fieldName = headItem.key as keyof StudyFilterField;
                  studyListFilterVM.updateFilterDataField(fieldName, event.currentTarget.value);
                }}
              />
            </div>
          )}
        </HeadCell>
      ))}
    </HeadRow>
  );
};
