import { compareHelper } from '@/utils/compareHelper';

/**
 * 用來調整 ohif 既有的 displaySet
 */
class DisplaySetHelper {
  sortFunc = compareHelper.createCompareFunc([
    { fieldName: 'seriesNumber', order: 'asc', compareType: 'number' },
    { fieldName: 'seriesTime', order: 'asc', compareType: 'dicomTime' },
    { fieldName: 'acquisitionTime', order: 'asc', compareType: 'dicomTime' },
    { fieldName: 'contentTime', order: 'asc', compareType: 'dicomTime' },
  ]);

  /**
   * 準備好要被排序的資料
   */
  getSortDataFromDisplaySet(displaySetData: any) {
    return {
      seriesTime: displaySetData.SeriesTime,
      acquisitionTime: displaySetData?.instance?.AcquisitionTime,
      contentTime: displaySetData?.instances[0]?.ContentTime,
    };
  }

  /**
   * 轉換更新 displaySet 資料以利於排序
   */
  displaySetDataMappingFunc(displaySetData: any) {
    const sortData = this.getSortDataFromDisplaySet(displaySetData);
    return {
      ...displaySetData,
      ...sortData,
    };
  }
}

export const displaySetHelper = new DisplaySetHelper();
