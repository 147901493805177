import React from 'react';
import { ModalBg } from '@/components/BaseModal/ModalBg';
import { PacsButton } from '@/components/common/PacsButton';
import { ModalityWorkVM } from '@/viewModel/modalityWorkList/ModalityWorkVM';
import { SecondayTable, HeadItem, TableRow, TableCell } from '@/components/common/SecondayTable';
import { timeFormatHelper } from '@/utils';
import classNames from 'classnames';

const headList: HeadItem<keyof Api.SpsItem>[] = [
  {
    key: 'status',
    label: 'Status',
    style: { width: '140px', minWidth: '140px', maxWidth: '140px' },
  },
  {
    key: 'modality',
    label: 'Modality',
    style: { width: '80px', minWidth: '80px', maxWidth: '80px' },
  },
  { key: 'start_datetime', label: 'Scheduled Time', style: { minWidth: '110px' } },
  { key: 'ae_title', label: 'AE Title', style: { minWidth: '110px' } },
  { key: 'performing_physician', label: 'Performing Physician', style: { minWidth: '110px' } },
  { key: 'description', label: 'SPS Description', style: { minWidth: '110px' } },
];

type Props = {
  modalityWorkVM: ModalityWorkVM;
  onClose: () => Promise<void> | void;
};

export const SpsModal = ({ onClose, modalityWorkVM }: Props) => {
  return (
    <ModalBg handleClickBg={onClose}>
      <div className="appear_top relative w-full max-w-[1060px] max-h-[90%] bg-green-182c2a overflow-y-auto scrollbar-primary hidden-overflow-with-radius rounded-[16px] py-[36px]">
        <div className="w-full h-full text-gray-f9f9f9 px-[36px]">
          <h3 className="text-gray-f9f9f9 text-[14px] mb-[16px]">
            Accession No. {modalityWorkVM.currentAccessionNumber}
          </h3>

          <SecondayTable
            headList={headList}
            className="mb-[36px]"
          >
            {modalityWorkVM.spsList.map(spsItem => (
              <TableRow
                key={spsItem.identifier}
                className={classNames([
                  spsItem.status === 'DEPARTED' && 'text-gray-2b2b2b',
                  spsItem.status === 'COMPLETED' && 'text-black',
                  spsItem.status === 'DISCONTINUED' && 'text-[#F7CA15]',
                ])}
              >
                <TableCell>{spsItem.status}</TableCell>
                <TableCell>{spsItem.modality}</TableCell>
                <TableCell>{timeFormatHelper.formatTime(spsItem.start_datetime)}</TableCell>
                <TableCell>{spsItem.ae_title}</TableCell>
                <TableCell>{spsItem.performing_physician}</TableCell>
                <TableCell>{spsItem.description}</TableCell>
              </TableRow>
            ))}
          </SecondayTable>

          <PacsButton
            className="block w-full max-w-[300px] mx-auto h-[40px] "
            onClick={onClose}
          >
            <span className="text-[14px] text-green-182c2a font-bold">確定</span>
          </PacsButton>
        </div>
      </div>
    </ModalBg>
  );
};
