import React from 'react';
import classNames from 'classnames';
import { Radio } from '@mantine/core';
import { Tooltip } from 'react-tooltip';

export interface RadioOptionItem<T> {
  key: T;
  label: string;
  isCheck: boolean;
  tooltipMsg?: string;
}

type Props = {
  className?: string;
  optionList: RadioOptionItem<string>[];
  onClickOption: (arrIndex: number) => Promise<void> | void;
};

export const RadioOptionList = ({ className, optionList, onClickOption }: Props) => {
  return (
    <div className={classNames('inline-flex i-center day_option', className)}>
      {optionList.map((item, i) => (
        <div
          key={item.key}
          className="relative z-[5] ml-[24px] first-of-type:ml-0"
          data-tooltip-id={`radio_${item.key}`}
        >
          <Radio
            label={item.label}
            checked={item.isCheck}
            onChange={() => onClickOption(i)}
          />

          {item.tooltipMsg && (
            <Tooltip
              id={`radio_${item.key}`}
              className="!bg-gray-111 text-white"
              place="bottom-end"
              content={item.tooltipMsg}
              delayShow={350}
              opacity={1}
            />
          )}
        </div>
      ))}
    </div>
  );
};
