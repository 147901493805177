import React from 'react';
import Input from '../Input';
import InputLabelWrapper from '../InputLabelWrapper';

type Props = {
  id: string;
  label: string;
  isSortable: boolean;
  sortDirection: StudySortDirection;
  value: string;
  onLabelClick: () => Promise<void> | void;
  onChange: (val: string) => Promise<void> | void;
};

const InputText = ({
  id,
  label,
  isSortable = false,
  sortDirection = 'none',
  onLabelClick = () => {},
  value = '',
  onChange,
}: Props) => {
  return (
    <InputLabelWrapper
      label={label}
      isSortable={isSortable}
      sortDirection={sortDirection}
      onLabelClick={onLabelClick}
    >
      <Input
        id={id}
        className="mt-2"
        type="text"
        containerClassName=""
        value={value}
        onChange={event => {
          onChange(event.target.value);
        }}
      />
    </InputLabelWrapper>
  );
};

export default InputText;
