import moment from 'moment';

export const getStudyDate = (date: string, time: string) => {
  const studyDate =
    date &&
    moment(date, ['YYYYMMDD', 'YYYY.MM.DD'], true).isValid() &&
    moment(date, ['YYYYMMDD', 'YYYY.MM.DD']).format('MMM-DD-YYYY');
  const studyTime =
    time &&
    moment(time, ['HH', 'HHmm', 'HHmmss', 'HHmmss.SSS']).isValid() &&
    moment(time, ['HH', 'HHmm', 'HHmmss', 'HHmmss.SSS']).format('hh:mm A');

  return {
    studyDate,
    studyTime,
  };
};
