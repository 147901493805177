import { cookieKey } from '@/config/constants';
import { utils } from '@ohif/core';

const { cookie, cookieHelper } = utils;

class ShareLinkHelper {
  /**
   * 取得 share link google api token
   */
  getShareLinkDicomTokenData() {
    return cookie.get(cookieKey.SHARE_LINK_AUTH_DATA)?.data ?? {};
  }

  /**
   * 清除 share link google api token
   */
  clearShareLinkDicomTokenData() {
    const data = this.getShareLinkDicomTokenData();

    if (data.dicomStoreUrl) {
      cookie.remove(cookieKey.SHARE_LINK_AUTH_DATA);
    }
  }

  /**
   * 檢查用戶在 viewer 頁面是否有正確的權限
   */
  checkIsUserValidInViewerPage() {
    const { isViewerPage, isShareLinkPage, shareToken } = this.getCurrentUrlInfo();
    if (!isViewerPage && !isShareLinkPage) {
      return;
    }

    const { dicomStoreUrl } = cookieHelper.getDicomStoreUrl();
    const shareLinkAuthData = shareLinkHelper.getShareLinkDicomTokenData();
    const isValid = Boolean(shareLinkAuthData.dicomStoreUrl || dicomStoreUrl);

    const shouldValid = isViewerPage && !shareToken;

    if (shouldValid && !isValid) {
      window.location.replace('/login');
    }
  }

  /**
   * 取得當前網址資訊
   */
  getCurrentUrlInfo() {
    const isViewerPage = window.location.pathname.startsWith('/viewer');
    const WinUrl = window.URL || window.webkitURL;
    const url = new WinUrl(window.location.href);
    const shareToken: string = url.searchParams.get('token') || '';
    const StudyInstanceUIDs = url.searchParams.get('StudyInstanceUIDs') || '';
    const isShareLinkPage = isViewerPage && Boolean(shareToken);
    const isUrlHasShareParam = url.searchParams.get('share') === 'y';

    return {
      shareToken,
      StudyInstanceUIDs,
      isViewerPage,
      isShareLinkPage,
      isUrlHasShareParam,
    };
  }
}

export const shareLinkHelper = new ShareLinkHelper();
