import React, { createContext, useContext, useEffect, useState } from 'react';

type Props = {
  className?: string;
  children?: React.ReactNode;
};

interface WindowSize {
  x: number;
  y: number;
}

interface WindowSizeApi {
  windowSize: WindowSize;
  isMobile: boolean;
  isPc: boolean;
}

const SizeContext = createContext<WindowSizeApi>(null);

export const useWindowSize = () => {
  const sizeApiContext = useContext(SizeContext);
  return sizeApiContext;
};

export const WindowResizeProvider = ({ className, children }: Props) => {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    x: window.innerWidth,
    y: window.innerHeight,
  });

  const isMobile = windowSize.x < 768;

  const windowSizeApi: WindowSizeApi = {
    windowSize: windowSize,
    isMobile,
    isPc: !isMobile,
  };

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        x: window.innerWidth,
        y: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return <SizeContext.Provider value={windowSizeApi}>{children}</SizeContext.Provider>;
};
