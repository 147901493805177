import dayjs from 'dayjs';

class ModalityWorkListHelper {
  getDateParams(dayOption: DayOption = 'last7Days') {
    const today = dayjs();
    const last7Days = today.subtract(7, 'day');
    const last30Days = today.subtract(30, 'day');
    const startFormat = 'YYYY-MM-DD 00:00:00';
    const endFormat = 'YYYY-MM-DD 23:59:59';

    const dayMap: { [key in DayOption]: { from_datetime: string; to_datetime: string } } = {
      last7Days: {
        from_datetime: last7Days.format(startFormat),
        to_datetime: today.format(endFormat),
      },
      last30Days: {
        from_datetime: last30Days.format(startFormat),
        to_datetime: today.format(endFormat),
      },
      today: {
        from_datetime: today.format(startFormat),
        to_datetime: today.format(endFormat),
      },
      all: { from_datetime: undefined, to_datetime: undefined },
    };

    return dayMap[dayOption];
  }

  /**
   * 格式化 rp 清單
   */
  formatRpList(worklist: Api.ModalityWorkListItem[]) {
    const allRpList: Api.RpItem[] = [];

    worklist.forEach(workListItem => {
      const { requested_procedure_item_list, ...restWorkListItem } = workListItem;
      requested_procedure_item_list.forEach(rpItem => {
        const rpData = {
          ...rpItem,
          ...restWorkListItem,
        };
        allRpList.push(rpData);
      });
    });

    return allRpList;
  }
}

export const modalityWorkListHelper = new ModalityWorkListHelper();
