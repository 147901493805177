import { doApi, ApiLoading } from '../helper/doApi';
import { useState } from 'react';
import { sleepAtLeast, modalityWorkListHelper } from '@/utils';
import { apiGetModalityWorklist } from '@/api/modalityWorklist';
import { useModalState } from '@/hooks/useSwitchModal';
import { utils } from '@ohif/core';
import { cookieKey } from '@/config/constants';
import { ModalityWorkFilterVM } from './ModalityWorkFilterVM';
import { PopupHintVM } from './PopupHintVM';

interface GetModalityWorklistParams {
  dayOption: DayOption;
}

const { cookie, cookieHelper } = utils;
const saveAccountData = cookieHelper.getLoginAccountData();

export const getDefaultDayOption = (): DayOption => {
  return cookie.get(cookieKey.MODALITY_WORKLIST_DAY_OPTION).data ?? 'last7Days';
};

export class ModalityWorkVM {
  constructor() {
    [this.rpList, this.setRpList] = useState<Api.RpItem[]>([]);
    [this.spsList, this.setSpsList] = useState<Api.SpsItem[]>([]);
    [this.currentAccessionNumber, this.setCurrentAccessionNumber] = useState<string>('');
    [this.lastParams, this.setLastParams] = useState<GetModalityWorklistParams>({
      dayOption: 'last7Days',
    });
    [this.isRefreshing, this.setIsRefreshing] = useState<boolean>(false);
    this.spsModalState = useModalState();
  }

  modalityWorkFilterVM = new ModalityWorkFilterVM();
  popupHintVM = new PopupHintVM();

  /**
   * 當前 accession number
   */
  currentAccessionNumber: string;

  /**
   * 資料 loading
   */
  dataLoading = new ApiLoading();

  /**
   * rp 清單
   */
  rpList: Api.RpItem[];

  /**
   * sps 清單
   */
  spsList: Api.SpsItem[];

  /**
   * sps 彈窗管理
   */
  spsModalState: ModalState;

  /**
   * 最新一次的參數
   */
  lastParams: GetModalityWorklistParams;

  /**
   * 是否正在刷新
   */
  isRefreshing: boolean;

  /**
   * setter
   */
  private setRpList: React.Dispatch<React.SetStateAction<Api.RpItem[]>>;
  private setSpsList: React.Dispatch<React.SetStateAction<Api.SpsItem[]>>;
  private setCurrentAccessionNumber: React.Dispatch<React.SetStateAction<string>>;
  private setLastParams: React.Dispatch<React.SetStateAction<GetModalityWorklistParams>>;
  private setIsRefreshing: React.Dispatch<React.SetStateAction<boolean>>;

  /**
   * 取得 modality worklist 清單
   */
  async getModalityWorklist(params: GetModalityWorklistParams, options?: Api.Options) {
    const dayOption = params.dayOption ?? getDefaultDayOption();
    const { from_datetime, to_datetime } = modalityWorkListHelper.getDateParams(dayOption);

    this.setLastParams(params);
    cookie.set(cookieKey.MODALITY_WORKLIST_DAY_OPTION, { data: dayOption });

    const fn = async () => {
      const res = await apiGetModalityWorklist({
        unitId: saveAccountData.hospitalCode ?? '',
        from_datetime,
        to_datetime,
      });
      const allRpList = modalityWorkListHelper.formatRpList(res.data.data);
      this.setRpList(allRpList);
    };

    await doApi(
      () => sleepAtLeast(fn, 650),
      this.dataLoading,
      'get modality worklist error',
      options
    );
  }

  /**
   * 刷新 modality worklist
   */
  async refreshWorkList(options?: Api.Options) {
    this.setIsRefreshing(true);
    await this.getModalityWorklist(this.lastParams, options);
    this.setIsRefreshing(false);
  }

  /**
   * 取得排序 rpList
   */
  getSortRpList() {
    return this.modalityWorkFilterVM.getSortRpList(this.rpList);
  }

  /**
   * 點擊詳細
   */
  handleClickDetail(rpItem: Api.RpItem) {
    const spsList = rpItem.scheduled_procedure_step_item_list;
    this.setSpsList(spsList);
    this.setCurrentAccessionNumber(rpItem.accession_number);
    this.spsModalState.open();
  }
}
