/**
 * use the <OnClickOutside /> component directly instead of this hook
 *
 *  @see "platform/app/src/components/OnClickOutside/OnClickOutside.tsx"
 */
export const useOnClickOutside = (element, onClickOutside) => {
  const clickOutsideHandler = event => {
    if (element.current && !element.current.contains(event.target)) {
      onClickOutside();
    }
  };

  const add = () => {
    if (typeof window !== 'undefined') {
      window.addEventListener('mousedown', clickOutsideHandler);
    }
  };
  const remove = () => {
    if (typeof window !== 'undefined') {
      window.removeEventListener('mousedown', clickOutsideHandler);
    }
  };

  return {
    add,
    remove,
  };
};
