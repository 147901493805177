import { useState } from 'react';
import { TabItem } from '@/components/TabToggleList/TabToggleList';
import { utils } from '@ohif/core';

export const useTabToggleList = <T>(initTabList: TabItem<T>[], options?: { initialTabKey: T }) => {
  const [tabList, setTabList] = useState<TabItem<T>[]>(initTabList);
  const [currentTabKey, setCurrentTabKey] = useState<T>(
    options?.initialTabKey ?? initTabList[0]?.key
  );

  const handleClickTab = (arrIndex: number) => {
    let copyList = utils.jsonClone(tabList) as TabItem<T>[];
    copyList = copyList.map(item => {
      return { ...item, isActive: false };
    });
    copyList[arrIndex].isActive = true;
    setCurrentTabKey(copyList[arrIndex].key);
    setTabList(copyList);

    return copyList[arrIndex];
  };

  return {
    tabList,
    currentTabKey,
    handleClickTab,
  };
};
