import React from 'react';
import classNames from 'classnames';

type Props = {
  className?: string;
  children?: React.ReactNode;
  isPlain?: boolean;
};

export const DisabledButton = ({ className, children, isPlain = false }: Props) => {
  return (
    <div
      className={classNames(
        'cursor-not-allowed flex-center rounded-[8px] text-[14px]',
        isPlain && 'border-2 border-gray-c9c9c9 text-gray-c9c9c9',
        !isPlain && 'bg-gray-c9c9c9',
        className
      )}
    >
      {children}
    </div>
  );
};
