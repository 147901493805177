import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const stickyClasses = 'sticky top-0';
const notStickyClasses = 'relative';

const NavBar = ({ className, children, isSticky }) => {
  return (
    <div
      className={classnames(
        'bg-gray-2b2b2b z-20 flex flex-row items-center px-1 shadow-[0_4px_4px_0_rgba(0,0,0,0.25)]',
        isSticky && stickyClasses,
        !isSticky && notStickyClasses,
        className
      )}
      style={{ height: '60px', minHeight: '60px' }}
    >
      {children}
    </div>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  isSticky: PropTypes.bool,
};

export default NavBar;
