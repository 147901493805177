import dayjs, { extend } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { getDateStrByStudyInfo } from './getDateStrByStudyInfo';

extend(customParseFormat);

class CompareHelper {
  /**
   * 取得順序修飾數字
   */
  static getOrderModifier(order: Compare.Order) {
    const orderMap: { [key in Compare.Order]: number } = {
      asc: -1,
      desc: 1,
    };

    return orderMap[order];
  }

  /**
   * 解析 dicom time
   *
   * - 142013.000000 (14點 20分 13秒)
   * - 122013 (14點 20分 13秒)
   */
  static parseDicomTime(time: string) {
    return dayjs(time, 'HHmmss');
  }

  /**
   * 比對時間
   */
  static compareDateTime(dateStrA: string, dateStrB: string, order: Compare.Order) {
    const dayA = dayjs(dateStrA);
    const dayB = dayjs(dateStrB);

    if (dayA.isSame(dayB)) {
      return 0;
    }

    const isDayBNewer = dayB.isAfter(dayA);
    const compareNumber = isDayBNewer ? 1 : -1;
    return compareNumber * CompareHelper.getOrderModifier(order);
  }

  /**
   * 比對類型 mapping
   */
  private static compareTypeFuncMap: {
    [key in Compare.Type]: (dataA: any, dataB: any, compareRule: Compare.Rule) => number;
  } = {
    number(dataA, dataB, compareRule) {
      const aField = dataA[compareRule.fieldName];
      const bField = dataB[compareRule.fieldName];
      if (compareRule.order === 'desc') {
        return bField - aField;
      }

      return aField - bField;
    },
    string(dataA, dataB, compareRule) {
      const aField = dataA[compareRule.fieldName] ?? '';
      const bField = dataB[compareRule.fieldName] ?? '';
      return bField.localeCompare(aField) * CompareHelper.getOrderModifier(compareRule.order);
    },
    dateTime(dataA, dataB, compareRule) {
      const dateStrA = dataA[compareRule.fieldName];
      const dateStrB = dataB[compareRule.fieldName];
      return CompareHelper.compareDateTime(dateStrA, dateStrB, compareRule.order);
    },
    // 專門 for study
    studyDate(studyA: WorkListStudy, studyB: WorkListStudy, compareRule) {
      const dateStrA = getDateStrByStudyInfo(studyA.date, studyA.time);
      const dateStrB = getDateStrByStudyInfo(studyB.date, studyB.time);
      return CompareHelper.compareDateTime(dateStrA, dateStrB, compareRule.order);
    },
    dicomTime(dataA, dataB, compareRule) {
      const aField = dataA[compareRule.fieldName];
      const bField = dataB[compareRule.fieldName];

      // dicom 缺少資料，維持不變
      if (!aField || !bField) {
        return 0;
      }

      const aTime = CompareHelper.parseDicomTime(aField);
      const bTime = CompareHelper.parseDicomTime(bField);

      if (aTime.isSame(bTime)) {
        return 0;
      }

      const isDayBNewer = bTime.isAfter(aTime);
      const compareNumber = isDayBNewer ? 1 : -1;
      return compareNumber * CompareHelper.getOrderModifier(compareRule.order);
    },
  };

  /**
   * 建立比對 function
   */
  createCompareFunc(compareRuleList: Compare.Rule[]) {
    const compareFunc = (dataA: any, dataB: any) => {
      /**
       * reference:
       * https://dev.to/markbdsouza/js-sort-an-array-of-objects-on-multiple-columns-keys-2bj1
       */
      const compareResult = compareRuleList.reduce((prev, compareRule) => {
        const currentCompare = CompareHelper.compareTypeFuncMap[compareRule.compareType](
          dataA,
          dataB,
          compareRule
        );
        return prev || currentCompare;
      }, 0);

      return compareResult;
    };

    return compareFunc;
  }
}

export const compareHelper = new CompareHelper();
