import React from 'react';
import classNames from 'classnames';
import { ShareStudyVM } from '@/viewModel/ShareStudy/ShareStudyVM';
import { Svg, ProgressLoadingBar } from '@ohif/ui';
import { useDialog } from '@/components/Dialog/SmartPacsDialogProvider';
import { Loading } from '@/components/common/Loading/Loading';

type Props = {
  className?: string;
  shareStudyVM: ShareStudyVM;
};

export const MenuSelect = ({ className, shareStudyVM }: Props) => {
  const { dialog } = useDialog();
  const { menuVM, studySizeVM } = shareStudyVM;

  /**
   * 分享 study 影像
   */
  const shareStudy = async () => {
    const errorCallback = () => {
      dialog.update({
        icon: 'alert',
        title: '',
        content: (
          <p>
            Sharing Failed...
            <br />
            Please try again later or contact your service provider.
          </p>
        ),
        positiveText: 'OK',
        negativeText: '',
        isLoading: false,
        isHideButton: false,
        canCloseOnBg: false,
      });
    };

    const onLoading = async () => {
      dialog.open({
        icon: 'none',
        title: <Loading className="block w-[24px] h-[24px]" />,
        content: (
          <p className="text-[14px]">Depending the size, it may takes few minutes to complete.</p>
        ),
        isHideButton: true,
        canCloseOnBg: false,
      });
    };

    await shareStudyVM.shareStudy({ onLoading, errorCallback });
  };

  return (
    <div className={classNames('px-[36px]', className)}>
      {shareStudyVM.studySizeLoading && (
        <div className="flex j-center  w-full max-w-[192px] mx-auto">
          <ProgressLoadingBar />
        </div>
      )}

      {!shareStudyVM.studySizeLoading && (
        <div className="grid grid-cols-3 gap-x-[12px] min-h-[176px]">
          <div
            className="pt-[24px] px-[24px] flex flex-col i-center border border-green-19dbc4 rounded-[16px] cursor-pointer text-green-19dbc4 hover:text-white text-center"
            onClick={() => menuVM.setCurrentMenuItem('viewOnly')}
          >
            <Svg
              name="icViewOnly"
              className="block w-[64px] h-[64px] mb-[24px]"
            />
            <h2 className="font-bold">View Only</h2>
          </div>

          <div
            className={classNames(
              'relative pt-[24px] px-[24px] flex flex-col i-center border border-green-19dbc4 rounded-[16px] cursor-pointer text-green-19dbc4 hover:text-white text-center overflow-hidden hidden-overflow-with-radius',
              studySizeVM.isOverSize && 'pointer-events-none'
            )}
            onClick={() => {
              if (studySizeVM.isOverSize) {
                return;
              }
              shareStudy();
              menuVM.setCurrentMenuItem('viewAndDownload');
            }}
          >
            <Svg
              name="icDownload"
              className="block w-[64px] h-[64px] mb-[24px]"
            />
            <h2 className="font-bold">View and Download</h2>

            {studySizeVM.isOverSize && (
              <div className="absolute z-[1] left-0 top-0 w-full h-full bg-black/80 pt-[17px] px-[12px]">
                <p className="text-orange-ea6a3d text-left">
                  Due to the study being over 1.0GB, it cannot be downloaded.
                </p>
              </div>
            )}
          </div>

          <div
            className="pt-[24px] px-[24px] flex flex-col i-center border border-green-19dbc4 rounded-[16px] cursor-pointer text-green-19dbc4 hover:text-white text-center"
            onClick={() => menuVM.setCurrentMenuItem('DownloadOthers')}
          >
            <Svg
              name="icStudies"
              className="block w-[64px] h-[64px] mb-[24px]"
            />
            <h2 className="font-bold">Download Others</h2>
          </div>
        </div>
      )}
    </div>
  );
};
