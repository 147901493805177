import React from 'react';
import classNames from 'classnames';
import { ModalBg } from '@/components/BaseModal/ModalBg';
import { PacsButton } from '@/components/common/PacsButton';
import { TextInput } from '@mantine/core';
import {
  CommonTable,
  HeadRow,
  HeadCell,
  TableRow,
  TableCell,
  HeadItem,
} from '@/components/common/CommonTable';
import { StudyListMainVM } from '@/viewModel/StudyList/StudyListMainVM';
import { ProgressLoadingBar, Svg } from '@ohif/ui';
import { Select } from '@mantine/core';

type Props = {
  studyListMainVM: StudyListMainVM;
  onClose?: () => Promise<void> | void;
};

const headList: HeadItem<string>[] = [
  { key: 'studyId', label: '(0020,0010) StudyID', style: { minWidth: '120px' } },
  { key: 'patientName', label: '(0010,0010) PatientName', style: { minWidth: '120px' } },
  { key: 'patientId', label: '(0010,0020) PatientID', style: { minWidth: '120px' } },
  { key: 'modality', label: 'Modality', style: { minWidth: '120px' } },
  { key: 'delete', label: 'Delete', style: { width: '68px', maxWidth: '68px', minWidth: '68px' } },
];

export const UploadDicomModal = ({ onClose, studyListMainVM }: Props) => {
  const { uploadDicomVM } = studyListMainVM;

  return (
    <ModalBg>
      <div className="appear_top upload_dicom_table relative w-full max-w-[1060px] max-h-[90%] bg-green-182c2a overflow-y-auto scrollbar-primary hidden-overflow-with-radius rounded-[16px] px-[24px] py-[36px]">
        <div className="w-full h-full">
          <div className="w-full mb-[16px]">
            <h3 className="text-white text-[20px] font-bold mb-[16px] whitespace-nowrap">
              Choose code
            </h3>

            <div
              className={classNames(
                'w-full',
                uploadDicomVM.isParseDicomLoading && 'pointer-events-none'
              )}
            >
              <Select
                placeholder="Choose code"
                value={uploadDicomVM.encoding}
                data={uploadDicomVM.encodingOptionList}
                onChange={val => uploadDicomVM.selectEncoding(val)}
              />
            </div>
          </div>

          <h3 className="text-white text-[20px] font-bold mb-[20px]">Edit DICOM tag</h3>

          {uploadDicomVM.isParseDicomLoading && (
            <div className="flex flex-col items-center justify-center mt-[80px] mb-[120px]">
              <div className="w-full max-w-[192px]">
                <ProgressLoadingBar />
              </div>
            </div>
          )}

          {!uploadDicomVM.isParseDicomLoading && (
            <>
              <CommonTable
                className="rounded-[8px] border border-green-19dbc4 mb-[16px]"
                isCustomHeader={true}
                customHeader={
                  <HeadRow className="bg-green-19dbc4">
                    {headList.map(headItem => (
                      <HeadCell
                        key={headItem.key}
                        className="h-[40px] px-[12px] text-green-182c2a border-r-green-182c2a"
                      >
                        {headItem.label}
                      </HeadCell>
                    ))}
                  </HeadRow>
                }
              >
                {uploadDicomVM.studyInfoList.map((item, i) => (
                  <TableRow
                    key={item.study_instance_uid}
                    className={classNames('bg-transparent')}
                  >
                    <TableCell className="border-r border-r-green-19dbc4 h-[60px] px-[10px]">
                      <TextInput
                        value={item.study_id || ''}
                        onChange={event => {
                          const val = event.currentTarget.value;
                          uploadDicomVM.updateStudyTag({
                            arrIndex: i,
                            tag: 'study_id',
                            value: val,
                          });
                        }}
                      />
                    </TableCell>
                    <TableCell className="border-r border-r-green-19dbc4 h-[60px] px-[10px]">
                      <TextInput
                        value={item.patient_name || ''}
                        onChange={event => {
                          const val = event.currentTarget.value;
                          uploadDicomVM.updateStudyTag({
                            arrIndex: i,
                            tag: 'patient_name',
                            value: val,
                          });
                        }}
                      />
                    </TableCell>
                    <TableCell className="border-r border-r-green-19dbc4 h-[60px] px-[10px]">
                      <TextInput
                        value={item.patient_id || ''}
                        onChange={event => {
                          const val = event.currentTarget.value;
                          uploadDicomVM.updateStudyTag({
                            arrIndex: i,
                            tag: 'patient_id',
                            value: val,
                          });
                        }}
                      />
                    </TableCell>
                    <TableCell className="border-r border-r-green-19dbc4 h-[60px] px-[10px] text-green-19a698 text-center">
                      {item.modality}
                    </TableCell>
                    <TableCell className="border-r border-r-green-19dbc4 h-[60px] text-green-19dbc4">
                      <div
                        className="w-full h-full flex-center cursor-pointer hover:opacity-80"
                        onClick={() => uploadDicomVM.removeStudy(i)}
                      >
                        <div className="w-[44px] h-[44px] flex-center border border-green-19dbc4 rounded-[8px]">
                          <Svg
                            name="icDelete"
                            className="block w-[20px] h-[20px]"
                          />
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </CommonTable>

              <div
                className={classNames(
                  'flex justify-end',
                  studyListMainVM.uploadLoading.isLoading && 'pointer-events-none'
                )}
              >
                <PacsButton
                  theme="secondary"
                  className="min-w-[140px] h-[40px] mr-[16px]"
                  onClick={onClose}
                  isLoading={studyListMainVM.uploadLoading.isLoading}
                >
                  Cancel
                </PacsButton>

                {Boolean(uploadDicomVM.studyInfoList.length) && (
                  <PacsButton
                    className="min-w-[140px] h-[40px]"
                    onClick={() => studyListMainVM.uploadDicomList()}
                    isLoading={studyListMainVM.uploadLoading.isLoading}
                  >
                    Upload
                  </PacsButton>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </ModalBg>
  );
};
