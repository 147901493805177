import { useState } from 'react';

export const useSwitchModal = (
  isOpenState: boolean
): [boolean, () => void, () => void, () => void] => {
  const [isOpen, setIsOpen] = useState(isOpenState);

  /**
   * 開啟彈窗
   */
  const open = () => {
    setIsOpen(true);
  };

  /**
   * 關閉彈窗
   */
  const close = () => {
    setIsOpen(false);
  };

  const toggle = () => {
    if (isOpen) {
      close();
      return;
    }

    open();
  };

  return [isOpen, open, close, toggle];
};

export const useModalState = (): ModalState => {
  const [isOpen, open, close, toggle] = useSwitchModal(false);
  return { isOpen, open, close, toggle };
};
