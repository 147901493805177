import { SettingPage } from './SettingPage';
import { LoginPage } from './LoginPage';
import { PatientRedirectPage } from './PatientRedirectPage';
import { StudyListPage } from './StudyListPage';

export const smartPacsPageList = [
  {
    path: '/',
    children: StudyListPage,
  },
  {
    path: '/setting',
    children: SettingPage,
  },
  {
    path: '/login',
    children: LoginPage,
  },
  {
    path: 'patient-redirect',
    children: PatientRedirectPage,
  },
];
