import { useState } from 'react';
import {
  apiGetUserSelfData,
  apiUpdateUserName,
  apiVerifyOldPassword,
  apiUpdateUserSelfPassword,
} from '@/api/user';

const createAccountData = (): UserSelfAccountSettingForm => {
  return {
    account: '',
    name: '',
    oldPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
  };
};

export class UserSelfFormViewModel {
  constructor() {
    [this.accountData, this.setAccountData] = useState(createAccountData());
    [this.isDoingApiAction, this.setIsDoingApiAction] = useState(false);
  }

  /**
   * 帳號資料
   */
  accountData: UserSelfAccountSettingForm;

  /**
   * 是否正在執行 api 動作
   */
  isDoingApiAction: boolean;

  /**
   * 更新各個帳號欄位
   */
  updateAccountDataField(keyName: keyof UserSelfAccountSettingForm, val: string) {
    this.setAccountData({ ...this.accountData, [keyName]: val });
  }

  /**
   * setter 帳號資料
   */
  private setAccountData: React.Dispatch<React.SetStateAction<UserSelfAccountSettingForm>>;

  /**
   * setter
   */
  private setIsDoingApiAction: React.Dispatch<React.SetStateAction<boolean>>;

  /**
   * 取得用戶自己的資料
   */
  async getUserSelfData(options?: {
    successCallback: (userSelfData: Api.UserSelfData) => Promise<void> | void;
    errorCallback?: (error: any) => Promise<void> | void;
  }) {
    if (this.isDoingApiAction) {
      return;
    }

    this.setIsDoingApiAction(true);
    try {
      const res = await apiGetUserSelfData();
      const selfData = res.data.data as Api.UserSelfData;

      this.setAccountData({
        account: selfData.account,
        name: selfData.name,
        oldPassword: '',
        newPassword: '',
        newPasswordConfirm: '',
      });

      await options?.successCallback(selfData);
    } catch (error) {
      console.error('get user self data error:', error);
      options?.errorCallback?.(error);
    } finally {
      this.setIsDoingApiAction(false);
    }
  }

  /**
   * 儲存 name
   */
  async saveName(options?: Api.Options) {
    if (this.isDoingApiAction) {
      return;
    }

    this.setIsDoingApiAction(true);
    try {
      const name = this.accountData.name.trim();
      await apiUpdateUserName({ name });
      await this.getUserSelfData();
      await options?.successCallback();
    } catch (error) {
      console.error('save name error:', error);
      options?.errorCallback?.(error);
    } finally {
      this.setIsDoingApiAction(false);
    }
  }

  /**
   * 驗證舊密碼
   */
  async verifyOldPassword(options?: Api.Options) {
    if (this.accountData.oldPassword.trim() === '') {
      return;
    }

    if (this.isDoingApiAction) {
      return;
    }

    this.setIsDoingApiAction(true);
    try {
      await apiVerifyOldPassword({ old_password: this.accountData.oldPassword });
      await options?.successCallback();
    } catch (error) {
      console.error('verify old password error:', error);
      options?.errorCallback?.(error);
    } finally {
      this.setIsDoingApiAction(false);
    }
  }

  /**
   * 儲存新密碼
   */
  async saveNewPassword(options?: {
    // 再次輸入的密碼有錯誤
    onPasswordConfirmNotMatch?: () => Promise<void> | void;
    successCallback?: () => Promise<void> | void;
    errorCallback?: (error: any) => Promise<void> | void;
  }) {
    const pwd = this.accountData.newPassword.trim();
    const confirmPwd = this.accountData.newPasswordConfirm.trim();

    if (pwd === '' || confirmPwd === '') {
      return;
    }

    if (pwd !== confirmPwd) {
      options?.onPasswordConfirmNotMatch?.();
      return;
    }

    if (this.isDoingApiAction) {
      return;
    }

    this.setIsDoingApiAction(true);

    try {
      await apiUpdateUserSelfPassword({
        old_password: this.accountData.oldPassword,
        new_password: this.accountData.newPassword,
      });
      await options?.successCallback();
    } catch (error) {
      console.error('verify old password error:', error);
      options?.errorCallback?.(error);
    } finally {
      this.setIsDoingApiAction(false);
    }
  }

  reset() {
    this.setAccountData(createAccountData());
  }
}
