import CryptoJS from 'crypto-js';

const SECRET_KEY =
  'K7us20vtZWwTFdYCAN+fHxNKtm3I2fBNjAbrPtIWb5Iw7yZrLw3nKLx1VkaKLeBFbTtRwgbVmZGhTEd+9cMK4K2/kgBzU6cXYjEZEZzCXCAJ+F7Dvsv6G6mQ8RxWSPgc0w4gT47uqnv59lkLI8yl67Al1sU5Tq6xS1vS45zmejEr0GdQbD34dC+PVVlAMt5tayVoolNBGS8lnlG2VnSj4t4i0OhZmcjjZbU0jvH59wYVQtjm8RjoMtJbBuSAfY59Ot54RwMB/ZSXorYi+oZk0ZPByCKlBCjWmx45B9nhVxwXf6PkmidA/Qwj9iO/jNsXyHqhrIWeUCB7zfs+eoRTQQ==';

class EncryptHelper {
  encryptText(text: string) {
    return CryptoJS.AES.encrypt(text, SECRET_KEY).toString() as string;
  }

  decryptText(encryptStr: string) {
    const bytes = CryptoJS.AES.decrypt(encryptStr, SECRET_KEY);
    return bytes.toString(CryptoJS.enc.Utf8) as string;
  }
}

export const encryptHelper = new EncryptHelper();
