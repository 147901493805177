import { useState } from 'react';
import { AccountListViewModel } from './AccountListViewModel';
import { UserSelfFormViewModel } from './UserSelfFormViewModel';
import { doApi, ApiLoading } from '../helper/doApi';
import { apiGetSystemInfo } from '@/api/system';
import { apiGetUnitInfo } from '@/api/unit';
import { DiskSpace } from '@/utils/DiskSpace';

export class SettingMainViewModel {
  constructor() {
    [this.systemInfo, this.setSystemInfo] = useState<Api.SystemInfo | null>(null);
    [this.unitInfo, this.setUnitInfo] = useState<Api.UnitInfo | null>(null);
    [this.diskSpace, this.setDiskSpace] = useState<DiskSpace | null>(null);
  }

  accountListViewModel = new AccountListViewModel();
  userSelfFormViewModel = new UserSelfFormViewModel();
  apiLoading = new ApiLoading();
  unitLoading = new ApiLoading();

  /**
   * 基礎容量單位 1000 gb
   */
  baseSpaceGb = 1000;

  systemInfo: Api.SystemInfo | null;
  unitInfo: Api.UnitInfo | null;
  diskSpace: DiskSpace;
  private setSystemInfo: React.Dispatch<React.SetStateAction<Api.SystemInfo>>;
  private setUnitInfo: React.Dispatch<React.SetStateAction<Api.UnitInfo>>;
  private setDiskSpace: React.Dispatch<React.SetStateAction<DiskSpace>>;

  /**
   * 取得版號
   */
  async getAppVersion(options?: Api.Options) {
    const fn = async () => {
      const res = await apiGetSystemInfo();
      this.setSystemInfo(res.data.data);
    };

    await doApi(fn, this.apiLoading, 'get app version error', options);
  }

  /**
   * 取得醫院資訊
   */
  async getUnitInfo() {
    const fn = async () => {
      const res = await apiGetUnitInfo();
      const unitInfo = res.data.data;
      this.setUnitInfo(unitInfo);
      this.setDiskSpace(new DiskSpace(unitInfo.current_disk_usage, unitInfo.max_disk_usage));
    };

    await doApi(fn, this.unitLoading, 'get unit info error');
  }
}
