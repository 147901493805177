import React from 'react';
import { ModalBg } from '@/components/BaseModal/ModalBg';
import imgLineSupport from '@assets/images/line_support.png';
import { PacsButton } from '@/components/common/PacsButton';

type Props = {
  handleClickBg?: () => Promise<void> | void;
  handleClickBack?: () => Promise<void> | void;
};

export const LoginSupportModal = ({
  handleClickBg = () => {},
  handleClickBack = () => {},
}: Props) => {
  return (
    <ModalBg handleClickBg={handleClickBg}>
      <div className="appear_top relative w-full max-w-[480px] max-h-[90%] bg-green-182c2a overflow-hidden hidden-overflow-with-radius rounded-[16px] px-[48px] py-[36px]">
        <div className="w-full flex flex-col i-center text-white">
          <img
            src={imgLineSupport}
            alt="qr code"
            className="block w-[100px] h-[100px] mb-[28px]"
          />
          <p className="text-[14px] leading-[20px]">(02) 2559-7156</p>
          <p className="text-[14px] mb-[28px] leading-[20px]">support@hugg.com.tw</p>

          <PacsButton
            onClick={handleClickBack}
            className="w-full h-[40px]"
          >
            <span className="text-green-182c2a text-[14px] font-bold">OK</span>
          </PacsButton>
        </div>
      </div>
    </ModalBg>
  );
};
