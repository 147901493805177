import React from 'react';
import classNames from 'classnames';
import { zIndex } from '@/config/constants';
import { ModalityWorkVM } from '@/viewModel/modalityWorkList/ModalityWorkVM';

interface Props extends React.ComponentProps<'div'> {
  className?: string;
  modalityWorkVM: ModalityWorkVM;
}

export const PopupHint = ({ className, style, modalityWorkVM, ...props }: Props) => {
  const { popupHintVM } = modalityWorkVM;

  return (
    <div
      className={classNames(
        'border border-green-19dbc4 rounded-[8px] min-w-[185px] min-h-[120px] bg-green-182c2a p-[10px] pointer-events-none',
        className
      )}
      style={{
        zIndex: zIndex.MODALITY_WORKLIST_POPUP,
        ...style,
      }}
      {...props}
    >
      <p>
        Sex<span className="inline-block mx-[2px]">:</span>
        {popupHintVM.data.sex}
      </p>
      <p>
        Species<span className="inline-block mx-[2px]">:</span>
        {popupHintVM.data.species}
      </p>
      <p>
        Tel<span className="inline-block mx-[2px]">:</span>
        {popupHintVM.data.tel}
      </p>
      <p>
        Birthday<span className="inline-block mx-[2px]">:</span>
        {popupHintVM.data.birthday}
      </p>
      <p>
        Weight<span className="inline-block mx-[2px]">:</span>
        {popupHintVM.data.weight}
      </p>
    </div>
  );
};
