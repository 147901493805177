import { useState } from 'react';
import { AccountFormViewModel } from './AccountFormViewModel';
import {
  apiManagerDeleteUserAccount,
  apiManagerGetUserList,
  apiManagerUpdateUserAccount,
  apiManagerAddUser,
} from '@/api/user';

export class AccountListViewModel {
  constructor() {
    [this.accountList, this.setAccountList] = useState<Api.UserAccountItem[]>([]);
    [this.isDoingApiAction, this.setIsDoingApiAction] = useState(false);
    [this.currentArrIndex, this.setCurrentArrIndex] = useState(-1);
  }

  accountFormViewModel = new AccountFormViewModel();

  /**
   * 是否正在執行 api 動作
   */
  isDoingApiAction: boolean;

  /**
   * 帳號清單
   */
  accountList: Api.UserAccountItem[];

  /**
   * 當前選中 index
   */
  currentArrIndex: number;

  /**
   * 取得是否有帳號資料
   */
  hasAccountData() {
    return Boolean(this.accountList.length);
  }

  /**
   * 取得選中的帳號
   */
  getSelectedAccountItem() {
    return this.accountList[this.currentArrIndex];
  }

  /**
   * setter
   */
  private setIsDoingApiAction: React.Dispatch<React.SetStateAction<boolean>>;

  /**
   * setter
   */
  private setAccountList: React.Dispatch<React.SetStateAction<Api.UserAccountItem[]>>;

  /**
   * setter
   */
  setCurrentArrIndex: React.Dispatch<React.SetStateAction<number>>;

  /**
   * 取得用戶清單
   */
  async getUserList() {
    if (this.isDoingApiAction) {
      return;
    }

    this.setIsDoingApiAction(true);
    try {
      const res = await apiManagerGetUserList();
      this.setAccountList(res.data.data);
    } catch (error) {
      console.error('get user account list error:', error);
    } finally {
      this.setIsDoingApiAction(false);
    }
  }

  /**
   * 增加新帳號
   */
  async addNewAccount(options?: Api.Options) {
    if (this.isDoingApiAction) {
      return;
    }

    if (!this.accountFormViewModel.checkIsFormComplete()) {
      return;
    }

    this.setIsDoingApiAction(true);

    try {
      const postData = this.accountFormViewModel.accountData;

      await apiManagerAddUser({
        account: postData.account,
        description: '新建用戶',
        name: postData.name,
        password: postData.password,
      });
      await this.getUserList();
      await options?.successCallback();
    } catch (error) {
      console.error('add new account error:', error);
      options?.errorCallback?.(error);
    } finally {
      this.setIsDoingApiAction(false);
    }
  }

  /**
   * manager 更新用戶帳號狀態
   */
  async managerUpdateUserAccountStatus(
    arrIndex: number,
    status: Api.Status,
    options?: Api.Options
  ) {
    if (this.isDoingApiAction) {
      return;
    }

    this.setIsDoingApiAction(true);
    try {
      const userId = this.accountList[arrIndex].id;
      await apiManagerUpdateUserAccount({ userId, status });
      await this.getUserList();
      await options?.successCallback();
    } catch (error) {
      console.error('manager update user account status error:', error);
      options?.errorCallback?.(error);
    } finally {
      this.setIsDoingApiAction(false);
    }
  }

  /**
   * manager 重設用戶密碼
   */
  async managerResetUserPasswrod(arrIndex: number, password: string, options?: Api.Options) {
    if (this.isDoingApiAction) {
      return;
    }

    this.setIsDoingApiAction(true);
    try {
      const userId = this.accountList[arrIndex].id;
      await apiManagerUpdateUserAccount({ userId, password });
      await this.getUserList();
      await options?.successCallback();
    } catch (error) {
      console.error('manager update user account status error:', error);
      options?.errorCallback?.(error);
    } finally {
      this.setIsDoingApiAction(false);
    }
  }

  /**
   * manager 刪除用戶帳號
   */
  async managerDeleteUserAccount(arrIndex: number, options?: Api.Options) {
    if (this.isDoingApiAction) {
      return;
    }

    this.setIsDoingApiAction(true);
    try {
      const userId = this.accountList[arrIndex].id;
      await apiManagerDeleteUserAccount({ userId });
      await this.getUserList();
      await options?.successCallback();
    } catch (error) {
      console.error('manager delete user account error:', error);
      options?.errorCallback?.(error);
    } finally {
      this.setIsDoingApiAction(false);
    }
  }
}
