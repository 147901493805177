import React from 'react';
import { PacsButton } from '@/components/common/PacsButton';
import { DisabledButton } from '@/components/common/DisabledButton';
import { ShareStudyTable } from './ShareStudyTable';
import { ShareStudyVM } from '@/viewModel/ShareStudy/ShareStudyVM';
import { useDialog } from '@/components/Dialog/SmartPacsDialogProvider';
import { ProgressLoadingBar } from '@ohif/ui';
import { Loading } from '@/components/common/Loading/Loading';
import classNames from 'classnames';
import { Svg } from '@ohif/ui';

type Props = {
  onClose: () => Promise<void> | void;
  shareStudyVM: ShareStudyVM;
};

export const SelectMoreDicom = ({ onClose, shareStudyVM }: Props) => {
  const { dialog } = useDialog();
  const { studySizeVM, menuVM } = shareStudyVM;

  const handleClose = () => {
    if (shareStudyVM.actionLoading.isLoading) {
      return;
    }

    onClose();
  };

  /**
   * 分享 study 影像
   */
  const shareStudy = async () => {
    const errorCallback = () => {
      dialog.update({
        icon: 'alert',
        title: '',
        content: (
          <p>
            Sharing Failed...
            <br />
            Please try again later or contact your service provider.
          </p>
        ),
        positiveText: 'OK',
        negativeText: '',
        isLoading: false,
        isHideButton: false,
        canCloseOnBg: false,
      });
    };

    const onLoading = async () => {
      dialog.open({
        icon: 'none',
        title: <Loading className="block w-[24px] h-[24px]" />,
        content: (
          <p className="text-[14px]">Depending the size, it may takes few minutes to complete.</p>
        ),
        isHideButton: true,
        canCloseOnBg: false,
      });
    };

    await shareStudyVM.shareStudy({ onLoading, errorCallback });
  };

  const isShareDisabled =
    shareStudyVM.sizeLoading.isLoading || //
    !shareStudyVM.hasCheckStudy() ||
    studySizeVM.isOverSize;

  return (
    <div className="w-full h-full text-gray-f9f9f9 px-[24px]">
      <div className="flex mb-[8px]">
        <Svg
          name="icStudies"
          className="block w-[32px] h-[42px] mr-[8px] text-green-19dbc4"
        />
        <div className="text-[16px]">
          <h3 className="font-bold">Download DICOM</h3>
          <p>Select the study which you’d like to download?</p>
        </div>
      </div>

      <div className="mb-[16px]">
        <ShareStudyTable shareStudyVM={shareStudyVM} />
      </div>

      {shareStudyVM.dataLoading.isLoading && (
        <div className="flex j-center mb-[36px] w-full max-w-[192px] mx-auto">
          <ProgressLoadingBar />
        </div>
      )}

      <div className="flex justify-between i-center text-[14px] mb-[24px]">
        <div className={classNames(studySizeVM.isOverSize && 'text-orange-ea6a3d')}>
          Selected :
          {shareStudyVM.sizeLoading.isLoading ? (
            <div className="inline-block mx-[3px]">
              <Loading className="w-[14px] h-[14px]" />
            </div>
          ) : (
            <>{studySizeVM.getTotalSize()}</>
          )}
          GB
        </div>

        <p>Maximum share file size : 1.0 GB</p>
      </div>

      <div className="w-full grid grid-cols-2 gap-x-[8px]">
        <PacsButton
          isDisabled={shareStudyVM.actionLoading.isLoading}
          theme="secondary"
          className="block w-full h-[40px] "
          onClick={handleClose}
        >
          <span className="text-[14px] font-bold">Cancel</span>
        </PacsButton>

        {isShareDisabled ? (
          <DisabledButton className="w-full h-[40px]">Create download link</DisabledButton>
        ) : (
          <PacsButton
            isDisabled={shareStudyVM.actionLoading.isLoading}
            className="block w-full h-[40px]"
            onClick={() => {
              shareStudyVM.setIsDownloadMultpleDicom(true);
              shareStudy();
              menuVM.setCurrentMenuItem('viewAndDownload');
            }}
          >
            <span className="text-green-182c2a text-[14px] font-bold">Create download link</span>
          </PacsButton>
        )}
      </div>
    </div>
  );
};
