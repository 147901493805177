import { doApi, ApiLoading } from '@/viewModel/helper/doApi';
import { shareLinkApi } from '@/api/shareLink';
import { utils } from '@ohif/core';
import { cookieKey } from '@/config/constants';
import { useState } from 'react';
import { shareLinkHelper } from '@/utils';

const { cookieHelper, cookie } = utils;

export class ShareLinkVM {
  constructor() {
    const { isUrlHasShareParam } = shareLinkHelper.getCurrentUrlInfo();
    [this.isOpenByShareLink, this.setIsOpenByShareLink] = useState(isUrlHasShareParam);
    [this.shareLinkDicomTokenData, this.setShareLinkDicomTokenData] =
      useState<Api.ShareLinkDicomTokenData>(null);
  }

  dicomTokenLoading = new ApiLoading();
  isOpenByShareLink: boolean;
  shareLinkDicomTokenData: Api.ShareLinkDicomTokenData | null;

  setIsOpenByShareLink: React.Dispatch<React.SetStateAction<boolean>>;
  setShareLinkDicomTokenData: React.Dispatch<Api.ShareLinkDicomTokenData>;

  /**
   * 取得 dicom token
   */
  async init(shareToken: string) {
    let data: Api.ShareLinkDicomTokenData | null = null;

    const fn = async () => {
      const res = await shareLinkApi.createDicomToken({ share_token: shareToken });
      data = res.data.data;
      this.setShareLinkDicomTokenData(data);

      cookieHelper.setDicomCookie({ dicomToken: data.access_token });
      cookie.set(cookieKey.SHARE_LINK_AUTH_DATA, {
        data: {
          dicomToken: data.access_token,
          dicomStoreUrl: data.dicom_store_url,
        },
      });
    };

    await doApi(fn, this.dicomTokenLoading, 'get dicom token error', {
      errorCallback() {
        window.location.replace('/login');
      },
    });

    this.setIsOpenByShareLink(true);
    return data;
  }
}
