import { utils } from '@ohif/core';
import { fetchRequest } from '@/api/helper';
const { cookieHelper, refreshDicomToken } = utils;

class DicomHelper {
  /**
   * 上傳 study
   */
  uploadStudy(data: any) {
    const { dicomStoreUrl } = utils.cookieHelper.getDicomStoreUrl();
    const apiUrl = `${dicomStoreUrl}/studies`;
    const { dicomToken } = utils.cookieHelper.getDicomTokenFromCookie();
    return fetchRequest(apiUrl, {
      method: 'post',
      data,
      headers: { Authorization: `Bearer ${dicomToken}`, 'Content-Type': 'application/dicom' },
    });
  }

  /**
   * 取得 study 清單
   */
  getStudyList(params: any) {
    const { dicomStoreUrl } = utils.cookieHelper.getDicomStoreUrl();
    const apiUrl = `${dicomStoreUrl}/studies`;
    const { dicomToken } = utils.cookieHelper.getDicomTokenFromCookie();
    return fetchRequest(apiUrl, {
      method: 'get',
      params,
      headers: { Authorization: `Bearer ${dicomToken}` },
    });
  }

  /**
   * 取得 series 清單
   */
  getSeriesList(studyInstanceUid: string, params: any) {
    const { dicomStoreUrl } = utils.cookieHelper.getDicomStoreUrl();
    const apiUrl = `${dicomStoreUrl}/studies/${studyInstanceUid}/series`;
    const { dicomToken } = utils.cookieHelper.getDicomTokenFromCookie();
    return fetchRequest(apiUrl, {
      method: 'get',
      params,
      headers: { Authorization: `Bearer ${dicomToken}` },
    });
  }

  /**
   * 取得 study metric
   */
  getStudyMetric(studyInstanceUID: string) {
    const { dicomStoreUrl } = utils.cookieHelper.getDicomStoreUrl();
    const apiUrl = `${dicomStoreUrl}/studies/${studyInstanceUID}:getStudyMetrics`;
    const { dicomToken } = utils.cookieHelper.getDicomTokenFromCookie();

    return fetchRequest(apiUrl, {
      method: 'get',
      headers: { Authorization: `Bearer ${dicomToken}` },
    });
  }

  /**
   * 刪除 study
   *
   * - google health care api
   */
  async deleteStudy(
    studyInstanceUid: string,
    options?: {
      series_uid: string;
      instance_uid: string;
    }
  ) {
    const { dicomStoreUrl } = utils.cookieHelper.getDicomStoreUrl();
    let apiUrl = `${dicomStoreUrl}/studies/${studyInstanceUid}`;
    const { dicomToken } = utils.cookieHelper.getDicomTokenFromCookie();

    if (options.instance_uid) {
      apiUrl += `/series/${options.series_uid}`;
      apiUrl += `/instances/${options.instance_uid}`;
    }

    const lroRes = await fetchRequest(apiUrl, {
      method: 'delete',
      headers: { Authorization: `Bearer ${dicomToken}` },
    });

    /**
     * 刪除 instances 不會有 long run operation
     *
     * https://cloud.google.com/healthcare-api/docs/how-tos/dicomweb#delete-dicom
     */
    if (!options.instance_uid) {
      await this.untilOperationDone(lroRes.data.name);
    }
  }

  /**
   * 直到 long run operation 執行完成
   *
   * - google health care api
   *
   * // TODO: 未來優化方向: 刪除可以先把項目直接從清單中移除 (會需要後端幫忙記錄狀態)，讓 google 在背後慢慢刪除
   */
  private untilOperationDone(longRunOperationName: string) {
    let keepLoop = true;
    // eslint-disable-next-line
    return new Promise(async (resolve) => {
      while (keepLoop) {
        const res = await this.checkOperationStatus(longRunOperationName);

        if (res.data.done) {
          keepLoop = false;
          resolve(true);
        }

        await utils.sleep(1000);
      }
    });
  }

  /**
   * 查詢 long run operation 狀態
   */
  private async checkOperationStatus(longRunOperationName: string) {
    const { dicomToken } = utils.cookieHelper.getDicomTokenFromCookie();
    const apiUrl = `https://healthcare.googleapis.com/v1/${longRunOperationName}`;
    return fetchRequest(apiUrl, {
      method: 'get',
      headers: { Authorization: `Bearer ${dicomToken}` },
    });
  }

  /**
   * 處理 dicom token 過期
   */
  handleGoogleTokenExpired() {
    const successCallback = dicomToken => {
      cookieHelper.clearDicomToken();
      cookieHelper.setDicomCookie({ dicomToken });
      window.location.reload();
    };

    refreshDicomToken({ successCallback });
  }
}

export const dicomHelper = new DicomHelper();
