import React, { useState } from 'react';
import { ModalBg } from '@/components/BaseModal/ModalBg';
import { PacsButton } from '@/components/common/PacsButton';
import { AccountListViewModel } from '@/viewModel/setting/AccountListViewModel';
import { Radio } from '@mantine/core';

type Props = {
  accountListViewModel?: AccountListViewModel;
  handleClose?: () => Promise<void> | void;
  onSelectActionType?: (statusType: StatusActionType) => Promise<void> | void;
};

export const AskChangeStatusModal = ({
  accountListViewModel = new AccountListViewModel(),
  handleClose = () => {},
  onSelectActionType = () => {},
}: Props) => {
  const [statusType, setStatusType] = useState<StatusActionType | ''>('');

  /**
   * 選擇選項
   */
  const handleCheckOption = (type: StatusActionType) => {
    setStatusType(type);
  };

  /**
   * 按下確認
   */
  const handleConfirm = () => {
    if (statusType === '') {
      return;
    }
    onSelectActionType(statusType);
  };

  return (
    <ModalBg>
      <div className="appear_top pwd_modal relative w-full max-w-[480px] max-h-[90%] bg-green-182c2a overflow-hidden hidden-overflow-with-radius rounded-[16px] px-[48px] py-[36px]">
        <div className="w-full flex flex-col i-center text-white">
          <h3 className="text-gray-f9f9f9 mb-[28px] text-center">
            Do you want to change the status of <br />“
            {accountListViewModel.getSelectedAccountItem()?.name}(
            {accountListViewModel.getSelectedAccountItem()?.account})”?
          </h3>

          <div className="mb-[28px]">
            <Radio
              label="Suspend account"
              className="mb-[18px]"
              checked={statusType === 'stop'}
              onChange={() => handleCheckOption('stop')}
            />
            <Radio
              label="Delete account"
              checked={statusType === 'delete'}
              onChange={() => handleCheckOption('delete')}
            />
          </div>

          <PacsButton
            className="block w-full h-[40px] mb-[8px]"
            isDisabled={statusType === ''}
            onClick={handleConfirm}
          >
            <span className="text-green-182c2a font-bold">Confirm</span>
          </PacsButton>
          <PacsButton
            theme="secondary"
            className="block w-full h-[40px] font-bold"
            onClick={handleClose}
          >
            Cancel
          </PacsButton>
        </div>
      </div>
    </ModalBg>
  );
};
