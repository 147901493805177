import { MantineThemeOverride } from '@mantine/core';

/**
 * config example:
 * https://v6.mantine.dev/theming/mantine-provider/#styles-on-mantineprovider
 */
export const customMantineThemeConfig: MantineThemeOverride = {
  colorScheme: 'light',
  colors: {
    brand: [
      '#D0E0DE',
      '#B5D4D0',
      '#9ACBC6',
      '#7DC8BF',
      '#5DCABD',
      '#39D3C1',
      '#19DBC4',
      '#28B4A3',
      '#319589',
      '#357D75',
    ],
  },
  primaryColor: 'brand',

  components: {
    TextInput: {
      styles: () => ({
        root: {
          color: '#fff',
        },
      }),
    },

    Progress: {
      styles: () => ({
        root: {
          height: '12px',
          borderRadius: '4px',
        },
      }),
    },
  },
};
