import React, { createContext, useEffect, useState } from 'react';
import { utils } from '@ohif/core';
import { AskLogoutModal } from '@/components/Logout/AskLogoutModal';
import { useSwitchModal } from '@/hooks';
import { SettingMainViewModel } from '@/viewModel/setting/SettingMainViewModel';
import { addApiErrorInterceptorHandler } from '@/api/helper';
import { apiUserLogout } from '@/api/user';
import { dicomHelper } from '@/utils';

const { cookieHelper, refreshDicomToken } = utils;

let refrechDicomTokenInterval;

export const smartPacsAuthContext = createContext<{
  /**
   * 是否為醫院管理帳號
   */
  isManager: boolean;

  /**
   * 用戶登入資料
   */
  authData: Api.LoginResponse | null;

  /**
   * 用戶個人資料
   */
  userSelfData: Api.UserSelfData | null;

  /**
   * gcp dicom token
   */
  dicomToken: string;

  /**
   * view model
   */
  settingMainViewModel: SettingMainViewModel;

  /**
   * setter 登入 auth 資料
   */
  setAuthData: React.Dispatch<React.SetStateAction<Api.LoginResponse>>;

  /**
   * setter dicom token
   */
  setDicomToken: React.Dispatch<React.SetStateAction<string>>;

  /**
   * 開啟詢問登出彈窗
   */
  openAskLogoutModal: () => void;

  /**
   * 初始化用戶個人資訊
   */
  initUserSelfData: () => void;
}>(null);

export const SmartPacsAuthProvider = ({ children }) => {
  const { Provider } = smartPacsAuthContext;
  const [authData, setAuthData] = useState<null | Api.LoginResponse>(
    cookieHelper.getAuthFromCookie()
  );
  const [dicomToken, setDicomToken] = useState('');
  const [userSelfData, setUserSelfData] = useState<null | Api.UserSelfData>(null);
  const [isOpenAskLogoutModal, openAskLogoutModal, closeAskLogoutModal] = useSwitchModal(false);
  const settingMainViewModel = new SettingMainViewModel();
  const isManager = Boolean(userSelfData?.permission === 'Manager');

  /**
   * 初始化用戶個人資訊
   */
  const initUserSelfData = () => {
    const { apiToken } = cookieHelper.getApiTokenFromCookie();
    const { dicomToken } = cookieHelper.getDicomTokenFromCookie();
    const isLogin = apiToken && dicomToken;

    if (!isLogin) {
      return;
    }

    settingMainViewModel.userSelfFormViewModel.getUserSelfData({
      successCallback(userSelfData) {
        setUserSelfData(userSelfData);
      },
    });
  };

  const providerApi = {
    isManager,
    authData,
    dicomToken,
    settingMainViewModel,
    userSelfData,
    setAuthData,
    setDicomToken,
    openAskLogoutModal,
    initUserSelfData,
  };

  /**
   * 登出
   */
  const handleLogout = async () => {
    try {
      await apiUserLogout();
    } catch (error) {
      console.error('user logout error:', error);
    }
    cookieHelper.clearAll();
    window.location.replace('/login');
  };

  /**
   * 處理遇到 api 身份、權限異常
   */
  addApiErrorInterceptorHandler(async error => {
    const statusCode = error?.response?.status;
    const isCallGoogleHealthcareApi = error?.config?.url?.startsWith(
      'https://healthcare.googleapis.com'
    );

    // google api
    if (isCallGoogleHealthcareApi && statusCode === 401) {
      dicomHelper.handleGoogleTokenExpired();
      return;
    }

    // 後端 api
    if (statusCode === 403 || statusCode === 401) {
      handleLogout();
    }
  });

  /**
   * 刷新 dicom token callback
   */
  const refreshDicomTokenCallback = (dicomToken: string) => {
    cookieHelper.setDicomCookie({ dicomToken });
    setDicomToken(dicomToken);
  };

  /**
   * 每50分鐘刷新 dicom token
   * (1小時過期)
   *
   */
  const initRefreshDicomTokenTimer = () => {
    refrechDicomTokenInterval = window.setInterval(async () => {
      refreshDicomToken({ successCallback: refreshDicomTokenCallback });
    }, 3000000); // 1000 * 60 * 50 (50分鐘)
  };

  useEffect(() => {
    initUserSelfData();
    initRefreshDicomTokenTimer();

    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        refreshDicomToken({ successCallback: refreshDicomTokenCallback });
        clearInterval(refrechDicomTokenInterval);
        initRefreshDicomTokenTimer();
      }
    });
  }, []);

  return (
    <Provider value={providerApi}>
      <>
        {isOpenAskLogoutModal && (
          <AskLogoutModal
            handleConfirm={handleLogout}
            handleCancel={closeAskLogoutModal}
          />
        )}
        {children}
      </>
    </Provider>
  );
};
