import React from 'react';
import classNames from 'classnames';
import { ProgressLoadingBar, Svg } from '@ohif/ui';
import { PrimaryTable, TableRow, TableCell } from '@/components/common/PrimaryTable';
import { StudyTableHead } from './StudyTableHead';
import { StudyListMainVM } from '@/viewModel/StudyList/StudyListMainVM';
import { Checkbox } from '@/components/common/Checkbox';
import { useNavigate } from 'react-router-dom';
import { ShareStudyModal } from '@/components/ShareStudyModal/ShareStudyModal';
import { useOpenState } from '@/hooks/useOpenState';
import { ShareStudyVM } from '@/viewModel/ShareStudy/ShareStudyVM';
import { StudyDetailModal } from '@/components/StudyList/StudyDetailModal/StudyDetailModal';
import { timeFormatHelper } from '@/utils';
import { useDialog } from '@/components/Dialog/SmartPacsDialogProvider';
import { useWindowSize } from '@/components/provider/WindowResizeProvider';

type Props = {
  className?: string;
  studyListMainVM: StudyListMainVM;
};

export const StudyTable = ({ className, studyListMainVM }: Props) => {
  const shareModalState = useOpenState();
  const studyDetailModalState = useOpenState();
  const shareStudyVM = new ShareStudyVM();
  const studyList = studyListMainVM.getFilterStudyList();
  const navigate = useNavigate();
  const { dialog } = useDialog();
  const { isPc } = useWindowSize();

  const goViewerPage = (studyInstanceUid: string) => {
    navigate(`/viewer?StudyInstanceUIDs=${studyInstanceUid}`);
  };

  const openShare = async (study: Api.StudyItem) => {
    shareModalState.open();
    await shareStudyVM.initData({
      patientId: study.patient_id,
      studyInstanceUid: study.study_instance_uid,
      patientName: study.patient_name,
    });
  };

  const openDetail = async (study: Api.StudyItem) => {
    studyDetailModalState.open();
    await studyListMainVM.getSeriesList(study);
  };

  const onClickRow = (study: Api.StudyItem) => {
    if (study.status === 'Disable') {
      dialog.open({
        icon: 'alert',
        content: (
          <p className="text-center">
            This DICOM image has been removed from the cloud storage. Please navigate to another
            storage location to view this file. Thanks.
          </p>
        ),
        positiveText: 'OK',
        negativeText: '',
      });
      return;
    }

    goViewerPage(study.study_instance_uid);
  };

  return (
    <div className={classNames('h-full', className)}>
      {studyListMainVM.listLoading.isLoading && (
        <div className="flex flex-col items-center justify-center pt-48">
          <div className="w-full max-w-[192px]">
            <ProgressLoadingBar />
          </div>
        </div>
      )}

      {!studyListMainVM.listLoading.isLoading && (
        <PrimaryTable
          isCustomHeader={true}
          isHeightFull={true}
          customHeader={<StudyTableHead studyListMainVM={studyListMainVM} />}
        >
          {studyList.map(study => (
            <TableRow
              key={study.study_instance_uid}
              onClick={() => onClickRow(study)}
              isDisabled={study.status === 'Disable'}
            >
              <>
                {isPc && (
                  <>
                    {study.status !== 'Disable' ? (
                      <TableCell>
                        <div
                          className="group/checkbox w-full h-[50px] flex-center"
                          onClick={e => {
                            e.stopPropagation();
                            studyListMainVM.handleCheckStudy(study.originalIndex);
                          }}
                        >
                          <Checkbox isCheck={study.isCheck} />
                        </div>
                      </TableCell>
                    ) : (
                      <TableCell>
                        <div className="flex-center">
                          <Svg
                            name="icFileDeleted"
                            className="block w-[20px] h-[20px]"
                          />
                        </div>
                      </TableCell>
                    )}
                  </>
                )}
              </>
              <TableCell className="max-md:break-all">{study.patient_name}</TableCell>
              <TableCell className="max-md:break-all">{study.patient_id}</TableCell>
              <TableCell className="max-md:break-words">
                {timeFormatHelper.formatTime(study.study_datetime)}
              </TableCell>

              {isPc && (
                <TableCell className="break-all">{study.patient_telephone_number}</TableCell>
              )}
              {isPc && <TableCell>{study.study_description}</TableCell>}
              <TableCell className="max-md:break-all max-md:text-center">
                {study.modalities}
              </TableCell>

              {isPc && <TableCell>{study.accession_number}</TableCell>}
              {isPc && <TableCell>{study.instances}</TableCell>}

              {isPc && (
                <TableCell hasPadding={false}>
                  <div
                    className="w-full h-[50px] flex-center hover:text-green-19dbc4"
                    onClick={e => {
                      e.stopPropagation();
                      openDetail(study);
                    }}
                  >
                    <Svg
                      name="icList"
                      className="block w-[22px] h-[22px]"
                    />
                  </div>
                </TableCell>
              )}

              {isPc && (
                <TableCell hasPadding={false}>
                  <div
                    className="w-full h-[50px] flex-center hover:text-green-19dbc4"
                    onClick={e => {
                      e.stopPropagation();
                      openShare(study);
                    }}
                  >
                    <Svg
                      name="icShare"
                      className="block w-[22px] h-[22px]"
                    />
                  </div>
                </TableCell>
              )}
            </TableRow>
          ))}
        </PrimaryTable>
      )}

      {shareModalState.isOpen && (
        <ShareStudyModal
          onClose={shareModalState.close}
          shareStudyVM={shareStudyVM}
        />
      )}

      {studyDetailModalState.isOpen && (
        <StudyDetailModal
          onClose={studyDetailModalState.close}
          studyListMainVM={studyListMainVM}
        />
      )}
    </div>
  );
};
