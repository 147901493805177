import React, { useState } from 'react';
import { ModalBg } from '@/components/BaseModal/ModalBg';
import { PasswordInput } from '@mantine/core';
import { PacsButton } from '@/components/common/PacsButton';
import { utils } from '@ohif/core';

type Props = {
  who: string;
  handleClose?: () => Promise<void> | void;
  /**
   * 確認輸入完密碼事件
   */
  onConfirmInputPassword: (password: string) => Promise<void> | void;
};

export const ResetPasswordModal = ({
  who = '',
  handleClose = () => {},
  onConfirmInputPassword = () => {},
}: Props) => {
  const [inputStr, setInputStr] = useState('');

  /**
   * 確認輸入完
   */
  const confirmInput = () => {
    if (inputStr.trim() === '') {
      return;
    }

    onConfirmInputPassword(inputStr);
  };

  return (
    <ModalBg>
      <div className="appear_top pwd_modal relative w-full max-w-[480px] max-h-[90%] bg-green-182c2a overflow-hidden hidden-overflow-with-radius rounded-[16px] px-[48px] py-[36px]">
        <div className="w-full flex flex-col i-center text-white">
          <h3 className="text-gray-f9f9f9 mb-[28px]">New password of “{Boolean(who) && who}”</h3>

          <div className="w-full mb-[28px] center_input">
            <PasswordInput
              placeholder="New password"
              value={inputStr}
              onChange={e => setInputStr(e.currentTarget.value)}
              onKeyPress={e => utils.handleEnterKey(e, confirmInput)}
            />
          </div>

          <PacsButton
            className="block w-full h-[40px] mb-[8px]"
            onClick={confirmInput}
          >
            <span className="text-green-182c2a font-bold">Edit</span>
          </PacsButton>
          <PacsButton
            theme="secondary"
            className="block w-full h-[40px] font-bold"
            onClick={handleClose}
          >
            Cancel
          </PacsButton>
        </div>
      </div>
    </ModalBg>
  );
};
