import React, { createContext, useContext, useState } from 'react';
import { ModalBg } from '@/components/BaseModal/ModalBg';
import { PacsButton } from '@/components/common/PacsButton';
import type { DialogState, ModalPromise, OpenOption, PromiseData } from './dialogType';
import { Svg } from '@ohif/ui';
import classNames from 'classnames';

type Props = {
  className?: string;
  children?: React.ReactNode;
};

const createDefaultState = (): DialogState => {
  return {
    icon: 'none',
    isOpen: false,
    isLoading: false,
    isHideButton: false,
    isBtnHorizontal: false,
    canCloseOnBg: true,
    title: '',
    content: '',
    positiveText: '確認',
    negativeText: '取消',
    onPositiveClick: () => {},
    onNegativeClick: () => {},
  };
};

const DialogContext = createContext<DialogService>(null);

export interface DialogService {
  open: (options: OpenOption) => Promise<PromiseData>;
  update: (options: OpenOption) => Promise<void> | void;
  startLoading: (options: OpenOption) => Promise<void> | void;
  close: Fn;
}

export const useDialog = () => {
  return {
    dialog: useContext(DialogContext),
  };
};

const modalPromise: ModalPromise = { resolve: () => {} };

/**
 * component
 */
export const SmartPacsDialogProvider = ({ children }: Props) => {
  const [dialogState, setDialogState] = useState(createDefaultState());

  /**
   * 重置狀態
   */
  const resetState = () => {
    setDialogState(createDefaultState());
  };

  /**
   * 開啟 dialog
   */
  const open = (options: OpenOption): Promise<PromiseData> => {
    resetState();

    const defaultState = createDefaultState();
    const newState: DialogState = {
      icon: options.icon ?? defaultState.icon,
      isOpen: true,
      isLoading: options.isLoading ?? defaultState.isLoading,
      isHideButton: options.isHideButton ?? defaultState.isHideButton,
      isBtnHorizontal: options.isBtnHorizontal ?? defaultState.isBtnHorizontal,
      canCloseOnBg: options.canCloseOnBg ?? defaultState.canCloseOnBg,
      title: options.title ?? defaultState.title,
      content: options.content ?? defaultState.content,
      positiveText: options.positiveText ?? defaultState.positiveText,
      negativeText: options.negativeText ?? defaultState.negativeText,
      onPositiveClick: options.onPositiveClick ?? defaultState.onPositiveClick,
      onNegativeClick: options.onNegativeClick ?? defaultState.onNegativeClick,
    };

    setDialogState(newState);

    return new Promise(resolve => {
      modalPromise.resolve = resolve;
    });
  };

  /**
   * 更新 dialog
   */
  const update = (options: OpenOption) => {
    setDialogState(prev => {
      const currentState = prev;
      const defaultState = createDefaultState();
      return {
        isOpen: true,
        icon: options.icon ?? currentState.icon,
        isLoading: options.isLoading ?? currentState.isLoading,
        isHideButton: options.isHideButton ?? currentState.isHideButton,
        isBtnHorizontal: options.isBtnHorizontal ?? currentState.isBtnHorizontal,
        canCloseOnBg: options.isHideButton ?? currentState.canCloseOnBg,
        title: options.title ?? currentState.title,
        content: options.content ?? currentState.content,
        positiveText: options.positiveText ?? currentState.positiveText,
        negativeText: options.negativeText ?? currentState.negativeText,
        onPositiveClick: options.onPositiveClick ?? defaultState.onPositiveClick,
        onNegativeClick: options.onNegativeClick ?? defaultState.onNegativeClick,
      };
    });
  };

  /**
   * 關閉 dialog
   */
  const close = () => {
    if (dialogState.isLoading) {
      return;
    }

    setDialogState({ ...dialogState, isOpen: false });
  };

  /**
   * positive 事件
   */
  const onPositiveClick = async () => {
    if (dialogState.isLoading) {
      return;
    }

    startLoading();
    const status = await dialogState.onPositiveClick();

    /**
     * 在 onPositiveClick return { shouldClsoe: false }
     * 就可以使用 model.update() 直接更新彈窗內容
     */
    // @ts-expect-error void
    const stopClose = status?.shouldClose === false;
    if (stopClose) {
      modalPromise.resolve({ isPositive: true });
      return;
    }

    modalPromise.resolve({ isPositive: true });
    close();
  };

  /**
   * negative 事件
   */
  const onNegativeClick = async () => {
    if (dialogState.isLoading) {
      return;
    }

    startLoading();
    await dialogState.onNegativeClick();
    modalPromise.resolve({ isPositive: false });
    close();
  };

  /**
   * 點擊背景
   */
  const onClickBg = () => {
    if (!dialogState.canCloseOnBg) {
      return;
    }

    close();
  };

  /**
   * 開始 loading
   */
  const startLoading = () => {
    if (!dialogState.isOpen) {
      console.warn('modal is not open');
      return;
    }

    setDialogState({ ...dialogState, isLoading: true });
  };

  /**
   * 公開的 dialog api
   */
  const dialogApi: DialogService = {
    open,
    update,
    startLoading,
    close,
  };

  return (
    <DialogContext.Provider value={dialogApi}>
      {dialogState.isOpen && (
        <ModalBg handleClickBg={onClickBg}>
          <div className="appear_top pwd_modal relative w-full max-w-[480px] max-h-[90%] bg-green-182c2a overflow-hidden hidden-overflow-with-radius rounded-[16px] px-[48px] py-[36px]">
            <div className="w-full flex flex-col i-center text-white">
              {dialogState.title && (
                <h3 className="text-gray-f9f9f9 mb-[28px]">{dialogState.title}</h3>
              )}

              {dialogState.icon === 'alert' && (
                <div className="mb-[28px]">
                  <Svg
                    name="icAlert"
                    className="block w-[24px] h-[24px] text-[#F7CA15]"
                  />
                </div>
              )}

              {dialogState.content && (
                <div className="w-full mb-[28px] text-center">{dialogState.content}</div>
              )}

              <div
                className={classNames(
                  'w-full',
                  dialogState.isBtnHorizontal && 'grid grid-cols-2 gap-x-[8px]'
                )}
              >
                {!dialogState.isHideButton && (
                  <PacsButton
                    isLoading={dialogState.isLoading}
                    className={classNames(
                      'block w-full h-[40px] mb-[8px]',
                      dialogState.isBtnHorizontal && 'order-1'
                    )}
                    onClick={() => onPositiveClick()}
                  >
                    <span className="text-green-182c2a font-bold">{dialogState.positiveText}</span>
                  </PacsButton>
                )}

                {!dialogState.isHideButton && dialogState.negativeText && (
                  <PacsButton
                    isLoading={dialogState.isLoading}
                    theme="secondary"
                    className={classNames(
                      'block w-full h-[40px] font-bold',
                      dialogState.isBtnHorizontal && 'order-[-1]'
                    )}
                    onClick={() => onNegativeClick()}
                  >
                    {dialogState.negativeText}
                  </PacsButton>
                )}
              </div>
            </div>
          </div>
        </ModalBg>
      )}

      {children}
    </DialogContext.Provider>
  );
};
