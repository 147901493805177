import React from 'react';
import { ModalBg } from '@/components/BaseModal/ModalBg';
import { PasswordInput } from '@mantine/core';
import { PacsButton } from '@/components/common/PacsButton';
import { utils } from '@ohif/core';
import { UserSelfFormViewModel } from '@/viewModel/setting/UserSelfFormViewModel';
import { useToast } from '@ohif/ui';

type Props = {
  userSelfFormViewModel?: UserSelfFormViewModel;
  handleClose?: () => Promise<void> | void;
  handleOldPasswordSuccess?: () => Promise<void> | void;
};

export const AskChangePasswordModal = ({
  userSelfFormViewModel = new UserSelfFormViewModel(),
  handleClose = () => {},
  handleOldPasswordSuccess = () => {},
}: Props) => {
  const { toastShow } = useToast();

  /**
   * 驗證舊密碼
   */
  const verifyOldPassword = async () => {
    await userSelfFormViewModel.verifyOldPassword({
      successCallback() {
        handleOldPasswordSuccess();
        toastShow({
          type: 'success',
          message: 'Enter a new password below to change your password.',
        });
      },
      errorCallback() {
        toastShow({ type: 'error', message: 'Incorrect password' });
      },
    });
  };

  return (
    <ModalBg>
      <div className="appear_top pwd_modal relative w-full max-w-[480px] max-h-[90%] bg-green-182c2a overflow-hidden hidden-overflow-with-radius rounded-[16px] px-[48px] py-[36px]">
        <div className="w-full flex flex-col i-center text-white">
          <h3 className="text-gray-f9f9f9 mb-[28px]">Please enter the current password</h3>

          <div className="w-full mb-[28px] center_input">
            <PasswordInput
              placeholder="Current password"
              value={userSelfFormViewModel.accountData.oldPassword}
              onChange={e =>
                userSelfFormViewModel.updateAccountDataField('oldPassword', e.currentTarget.value)
              }
              onKeyPress={e => utils.handleEnterKey(e, verifyOldPassword)}
            />
          </div>

          <PacsButton
            className="block w-full h-[40px] mb-[8px]"
            onClick={verifyOldPassword}
          >
            <span className="text-green-182c2a font-bold">Edit</span>
          </PacsButton>
          <PacsButton
            theme="secondary"
            className="block w-full h-[40px] font-bold"
            onClick={handleClose}
          >
            Cancel
          </PacsButton>
        </div>
      </div>
    </ModalBg>
  );
};
