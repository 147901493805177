import Cookies from 'js-cookie';
import { cookieKey } from '@/config/constants';

export const cookie = {
  get(name: string): any {
    if (!Cookies.get(name)) {
      return {};
    }
    return JSON.parse(Cookies.get(name) as string);
  },

  set(name: string, value: object, options?: Cookies.CookieAttributes) {
    /** @type {Cookies.CookieAttributes} */
    const obj = {
      expires: options?.expires ?? 365,
    };
    return Cookies.set(name, JSON.stringify(value), obj);
  },

  remove(name: string, options?: Cookies.CookieAttributes) {
    Cookies.remove(name, options ?? {});
  },
};

/**
 * 取得 auth data
 */
const getAuthFromCookie = () => {
  const authData = cookie.get(cookieKey.AUTH) as Api.LoginResponse;
  return authData;
};

/**
 * 取得 api token
 */
const getApiTokenFromCookie = () => {
  const authData = cookie.get(cookieKey.AUTH) as Api.LoginResponse;
  return { apiToken: authData.access_token };
};

/**
 * setter auth
 */
const setAuthCookie = (authData: Api.LoginResponse) => {
  cookie.set(cookieKey.AUTH, authData);
};

/**
 * 取得 dicom token
 */
const getDicomTokenFromCookie = () => {
  const dicomToken = cookie.get(cookieKey.DICOM).token as string;
  return { dicomToken };
};

/**
 * setter dicom token
 */
const setDicomCookie = (data: { dicomToken: string }) => {
  cookie.set(cookieKey.DICOM, { token: data.dicomToken });
};

/**
 * 取得 dicom store url
 */
const getDicomStoreUrl = () => {
  const authData = cookie.get(cookieKey.AUTH) as Api.LoginResponse;
  return { dicomStoreUrl: authData.dicom_store_url };
};

/**
 * 清除 dicom token
 */
const clearDicomToken = () => {
  cookie.remove(cookieKey.DICOM);
};

/**
 * 取得登入的資訊
 */
const getLoginAccountData = (): SaveAccountData => {
  return cookie.get(cookieKey.LOGIN_ACCOUNT)?.data ?? {};
};

/**
 * setter login account
 */
const setLoginAccountData = (accontData: SaveAccountData) => {
  cookie.set(cookieKey.LOGIN_ACCOUNT, { data: accontData });
};

/**
 * 清除所有 token
 */
const clearAll = () => {
  cookie.remove(cookieKey.AUTH);
  cookie.remove(cookieKey.DICOM);
};

const clearWebData = () => {
  cookie.remove(cookieKey.AUTH);
  cookie.remove(cookieKey.DICOM);
  cookie.remove(cookieKey.IS_SAVE_PASSWORD);
  cookie.remove(cookieKey.LOGIN_ACCOUNT);
};

export const cookieHelper = {
  getAuthFromCookie,
  setAuthCookie,
  getApiTokenFromCookie,
  setDicomCookie,
  getDicomTokenFromCookie,
  getDicomStoreUrl,
  clearDicomToken,
  getLoginAccountData,
  setLoginAccountData,
  clearAll,
  clearWebData,
};
