class Validation {
  /**
   * reference:
   * https://stackoverflow.com/a/46181
   */
  isEmail(str: string) {
    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const regex = new RegExp(pattern);
    return regex.test(str);
  }
}

export const validation = new Validation();
