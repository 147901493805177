class CopyHelper {
  /**
   * @returns {boolean}
   */
  private doExecCommand(copyText: string) {
    try {
      const textArea = document.createElement('textarea');
      textArea.value = copyText;
      Object.assign(textArea.style, {
        position: 'fixed',
        left: '0',
        top: '0',
        height: '0',
        opacity: '0',
      });
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      return document.execCommand('copy');
    } catch (error) {
      console.error('do execCommand error:', error);
      return false;
    }
  }

  private async doNavigatorClipboardCopy(copyText: string) {
    if (!navigator.clipboard) {
      return false;
    }

    try {
      await navigator.clipboard.writeText(copyText);
      return true;
    } catch (error) {
      console.error('do navigator clipboard copy error:', error);
      return false;
    }
  }

  /**
   * @returns {boolean}
   */
  async clipboardCopy(copyText: string) {
    let isCopySuccess = false;

    isCopySuccess = await this.doNavigatorClipboardCopy(copyText);

    if (!isCopySuccess) {
      isCopySuccess = this.doExecCommand(copyText);
    }

    return isCopySuccess;
  }
}

export const copyHelper = new CopyHelper();
