import { fetchRequest } from '@/api/helper';
import { utils } from '@ohif/core';

const { cookieHelper } = utils;

/**
 * 取得用戶自己的資料
 */
export const apiGetSystemInfo = () => {
  const { apiToken } = cookieHelper.getApiTokenFromCookie();

  return fetchRequest<{ data: Api.SystemInfo }>('/system', {
    headers: { Authorization: `Bearer ${apiToken}` },
  });
};
