import { sleepAtLeast } from '@/utils';
import { utils } from '@ohif/core';

const { cookieHelper } = utils;

class WebCacheHelper {
  /**
   * reference
   *
   * https://stackoverflow.com/a/46597656
   */
  async clearAll() {
    const fn = async () => {
      if ('serviceWorker' in navigator) {
        const keyList = await caches.keys();

        for (const keyName of keyList) {
          await caches.delete(keyName);
        }
      }
    };

    await sleepAtLeast(fn, 1000);

    cookieHelper.clearWebData();
    window.location.replace('/login');
  }
}

export const webCacheHelper = new WebCacheHelper();
