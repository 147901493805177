import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { PacsButton } from '@/components/common/PacsButton';

import LegacyButton from '../LegacyButton';
import LegacyButtonGroup from '../LegacyButtonGroup';
import Typography from '../Typography';
import Select from '../Select';

const StudyListPagination = ({ onChangePage, currentPage, perPage, onChangePerPage }) => {
  const { t } = useTranslation('StudyList');

  const navigateToPage = page => {
    const toPage = page < 1 ? 1 : page;
    onChangePage(toPage);
  };

  const ranges = [
    { value: '25', label: '25' },
    { value: '50', label: '50' },
    { value: '100', label: '100' },
  ];
  const [selectedRange, setSelectedRange] = useState(ranges.find(r => r.value === perPage));
  const onSelectedRange = selectedRange => {
    setSelectedRange(selectedRange);
    onChangePerPage(selectedRange.value);
  };

  return (
    <div className="py-10">
      <div className="container relative m-auto px-8">
        {/* <div className="flex justify-between"> */}
        <div className="flex j-center">
          {/* <div className="flex items-center">
            <Select
              id="rows-per-page"
              className="border-primary-main relative mr-3 w-24"
              options={ranges}
              value={selectedRange}
              isMulti={false}
              isClearable={false}
              isSearchable={false}
              closeMenuOnSelect={false}
              hideSelectedOptions={true}
              onChange={onSelectedRange}
            />
            <Typography className="text-base opacity-60">{t('ResultsPerPage')}</Typography>
          </div> */}
          <div className="">
            <div className="flex-center">
              <Typography className="mr-4 text-base opacity-60 block mb-[18px]">
                {t('Page')} {currentPage}
              </Typography>
            </div>

            <div className="flex items-center">
              {/* TODO Revisit design of LegacyButtonGroup later - for now use LegacyButton for its children.*/}
              <PacsButton
                className="block mr-[12px]"
                onClick={() => navigateToPage(1)}
              >{`<<`}</PacsButton>
              <PacsButton
                className="block mr-[12px]"
                onClick={() => navigateToPage(currentPage - 1)}
              >
                {t('Previous')}
              </PacsButton>
              <PacsButton
                className="block"
                onClick={() => navigateToPage(currentPage + 1)}
              >
                {' '}
                {t('Next')}
              </PacsButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

StudyListPagination.propTypes = {
  onChangePage: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  onChangePerPage: PropTypes.func.isRequired,
};

export default StudyListPagination;
