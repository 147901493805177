import { useState } from 'react';

interface MenuItem<MenuKeyT extends string> {
  key: MenuKeyT;
  label?: string;
  isActive?: boolean;
  value?: string;
}

export class MenuVM<MenuKeyT extends string> {
  constructor(initMenuList: MenuItem<MenuKeyT>[], options?: { initialMenuKey: MenuKeyT }) {
    [this.menuList, this.setMenuList] = useState(initMenuList);
    [this.currentMenuKey, this.setCurrentMenuKey] = useState(
      options?.initialMenuKey ?? initMenuList[0]?.key
    );
  }

  menuList: MenuItem<MenuKeyT>[];
  currentMenuKey: MenuKeyT;

  private setMenuList: React.Dispatch<React.SetStateAction<MenuItem<MenuKeyT>[]>>;
  private setCurrentMenuKey: React.Dispatch<React.SetStateAction<MenuKeyT>>;

  get keyList() {
    return this.menuList.map(item => item.key);
  }

  setCurrentMenuItem(menuKey: MenuKeyT) {
    const newList = this.menuList.map(item => {
      const isActive = item.key === menuKey;
      return { ...item, isActive };
    });

    this.setMenuList(newList);
    this.setCurrentMenuKey(menuKey);
  }
}
