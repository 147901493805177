import React from 'react';
import classNames from 'classnames';
import { useRadioOptionList } from '@/components/RadioOptionList/useRadioOptionList';
import { RadioOptionItem } from '@/components/RadioOptionList/RadioOptionList';
import { RadioOptionList } from '@/components/RadioOptionList/RadioOptionList';

type Props = {
  className?: string;
  children?: React.ReactNode;
  defaultStudyOption?: StudyListOption;
  onClickDayOption: (option: RadioOptionItem<StudyListOption>) => Promise<void> | void;
};

export const StudyOption = ({
  className,
  defaultStudyOption = 'last7Days',
  onClickDayOption,
}: Props) => {
  const radioOptionState = useRadioOptionList<StudyListOption>([
    { label: 'Last 7 Days', key: 'last7Days', isCheck: defaultStudyOption === 'last7Days' },
    { label: 'Last 30 Days', key: 'last30Days', isCheck: defaultStudyOption === 'last30Days' },
    { label: 'Today', key: 'today', isCheck: defaultStudyOption === 'today' },
    {
      label: 'Recent',
      key: 'recent',
      isCheck: defaultStudyOption === 'recent',
      tooltipMsg: 'Last 100 studies.',
    },
  ]);

  return (
    <RadioOptionList
      optionList={radioOptionState.tabList}
      onClickOption={arrIndex => {
        const item = radioOptionState.handleClickTab(arrIndex);
        onClickDayOption(item);
      }}
      className={classNames('', className)}
    />
  );
};
