class SizeConvert {
  /**
   * reference https://stackoverflow.com/a/33837733
   */
  byteToGigaByte(n: number): number {
    const num = n / Math.pow(10, 9);
    return +num.toFixed(2);
  }
}

export const sizeConvert = new SizeConvert();
