import { useState } from 'react';

const createAccountData = (): AccountForm => {
  return {
    account: '',
    password: '',
    employeeNumber: '',
    name: '',
  };
};

export class AccountFormViewModel {
  constructor() {
    [this.accountData, this.setAccountData] = useState(createAccountData());
  }

  /**
   * 帳號資料
   */
  accountData: AccountForm;

  /**
   * 檢查表單是否填寫完成
   */
  checkIsFormComplete() {
    const account = this.accountData.account.trim();
    const password = this.accountData.password.trim();
    const name = this.accountData.name.trim();
    return Boolean(account !== '' && password !== '' && name !== '');
  }

  /**
   * 更新各個帳號欄位
   */
  updateAccountDataField(keyName: keyof AccountForm, val: string) {
    this.setAccountData({ ...this.accountData, [keyName]: val });
  }

  /**
   * setter 帳號資料
   */
  private setAccountData: React.Dispatch<React.SetStateAction<AccountForm>>;

  reset() {
    this.setAccountData(createAccountData());
  }
}
