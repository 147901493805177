import React from 'react';
import classNames from 'classnames';
import { TextInput } from '@mantine/core';
import { Svg } from '@ohif/ui';
import { StudyListMainVM } from '@/viewModel/StudyList/StudyListMainVM';
import { useWindowSize } from '@/components/provider/WindowResizeProvider';

type Props = {
  className?: string;
  studyListMainVM: StudyListMainVM;
};

export const StudySearchInput = ({ className, studyListMainVM }: Props) => {
  const { isPc } = useWindowSize();

  const onKeyPressHandler = event => {
    if (event.key === 'Enter') {
      studyListMainVM.getStudyList();
    }
  };

  return (
    <div className={classNames('search_study relative', className)}>
      <div className="absolute left-[8px] top-0 text-green-19dbc4 h-full inline-flex i-center">
        <Svg
          name="icSearch"
          className="w-[24px] h-[24px]"
        />
      </div>
      <TextInput
        value={studyListMainVM.searchText}
        onChange={event => {
          const val = event.currentTarget.value;
          studyListMainVM.setSearchText(val);
        }}
        enterKeyHint="done"
        onKeyPress={onKeyPressHandler}
      />

      {studyListMainVM.searchText && (
        <div
          className="absolute right-0 top-0 text-green-19dbc4 h-full inline-flex i-center pr-[8px] cursor-pointer"
          onClick={e => {
            e.stopPropagation();
            studyListMainVM.clearSearch();
          }}
        >
          <Svg
            name="icClose"
            className="w-[24px] h-[24px]"
          />
        </div>
      )}
    </div>
  );
};
