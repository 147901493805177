import React from 'react';
import classNames from 'classnames';

interface ItemProps extends React.ComponentProps<'div'> {
  className?: string;
  children?: React.ReactNode;
  isActive?: boolean;
}

const TabToggleItem = ({ className, children, isActive = false, ...props }: ItemProps) => (
  <div
    className={classNames(
      'px-[24px] text-white rounded-t-[8px] cursor-pointer hover:bg-green-4e6d6a',
      isActive ? 'py-[10px] bg-green-4e6d6a' : 'py-[6px] bg-green-182c2a',
      className
    )}
    {...props}
  >
    {children}
  </div>
);

export interface TabItem<T> {
  key: T;
  label: string;
  isActive?: boolean;
}

type Props = {
  className?: string;
  children?: React.ReactNode;
  tabList: TabItem<string>[];
  onClickTab: (arrIndex: number) => Promise<void> | void;
};

export const TabToggleList = ({ className, tabList, onClickTab }: Props) => {
  return (
    <div
      className={classNames(
        'w-full flex items-end border-b border-b-green-4e6d6a px-[30px]',
        className
      )}
    >
      {tabList.map((item, i) => (
        <TabToggleItem
          key={i}
          className="ml-[4px] first-of-type:ml-0"
          isActive={item.isActive}
          onClick={() => onClickTab(i)}
        >
          {item.label}
        </TabToggleItem>
      ))}
    </div>
  );
};
