import React, { useEffect } from 'react';
import { Svg } from '@ohif/ui';
import { SettingMainViewModel } from '@/viewModel/setting/SettingMainViewModel';
import { PacsButton } from '@/components/common/PacsButton';
import { AccountCard } from './AccountCard';
import { useSwitchModal } from '@hooks';
import { AccountFormModal } from './AccountFormModal';
import { useToast } from '@ohif/ui';
import { AskChangeStatusModal } from './AskChangeStatusModal';
import { ResetPasswordModal } from './ResetPasswordModal';

type Props = {
  settingMainViewModel: SettingMainViewModel;
};

export const AccountList = ({ settingMainViewModel }: Props) => {
  const [isOpenAccountForm, openAccountForm, closeAccountForm] = useSwitchModal(false);
  const [isOpenChangeStatusModal, openChangeStatusModal, closeChangeStatusModal] =
    useSwitchModal(false);
  const [isOpenResetPasswordModal, openResetPasswordModal, closeResetPasswordModal] =
    useSwitchModal(false);

  const { accountListViewModel } = settingMainViewModel;
  const { accountFormViewModel } = accountListViewModel;
  const { toastShow } = useToast();

  const showAccountForm = () => {
    accountFormViewModel.reset();
    openAccountForm();
  };

  /**
   * 新增帳號
   */
  const handleAddNewAccount = () => {
    accountListViewModel.addNewAccount({
      successCallback() {
        toastShow({ type: 'success', message: 'Account added.' });
        closeAccountForm();
      },
      errorCallback() {
        toastShow({ type: 'error', message: 'Add account failed.' });
      },
    });
  };

  /**
   * 開啟調整帳號狀態彈窗
   */
  const showChangeStatusModal = (arrIndex: number) => {
    accountListViewModel.setCurrentArrIndex(arrIndex);
    openChangeStatusModal();
  };

  /**
   * 對帳號要執行的處理 map
   */
  const accountActionMap: { [key in StatusActionType]: () => Promise<void> | void } = {
    async stop() {
      const arrIndex = accountListViewModel.currentArrIndex;
      await accountListViewModel.managerUpdateUserAccountStatus(arrIndex, 'Disable', {
        successCallback() {
          toastShow({ type: 'success', message: 'Account suspended.' });
        },
        errorCallback() {
          toastShow({ type: 'error', message: 'Suspend account failed.' });
        },
      });
    },
    async delete() {
      const arrIndex = accountListViewModel.currentArrIndex;
      await accountListViewModel.managerDeleteUserAccount(arrIndex, {
        successCallback() {
          toastShow({ type: 'success', message: 'Account deleted.' });
        },
        errorCallback() {
          toastShow({ type: 'error', message: 'Delete account failed.' });
        },
      });
    },
  };

  /**
   * 選擇帳號狀態執行的動作 (停用、刪除)
   */
  const handleUpdateAccountStatus = (actionType: StatusActionType) => {
    accountActionMap[actionType]?.();
    closeChangeStatusModal();
  };

  /**
   * 啟用帳號
   */
  const recoverAccount = async (arrIndex: number) => {
    accountListViewModel.setCurrentArrIndex(arrIndex);
    await accountListViewModel.managerUpdateUserAccountStatus(arrIndex, 'Enable', {
      successCallback() {
        toastShow({ type: 'success', message: 'Account activated.' });
      },
      errorCallback() {
        toastShow({ type: 'error', message: 'Activate account failed.' });
      },
    });
  };

  /**
   * 開啟重設密碼彈窗
   */
  const showResetPasswordModal = (arrIndex: number) => {
    accountListViewModel.setCurrentArrIndex(arrIndex);
    openResetPasswordModal();
  };

  /**
   * 重設密碼
   */
  const resetPassword = async (password: string) => {
    const arrIndex = accountListViewModel.currentArrIndex;
    await accountListViewModel.managerResetUserPasswrod(arrIndex, password, {
      successCallback() {
        toastShow({ type: 'success', message: 'Password reset completed.' });
        closeResetPasswordModal();
      },
      errorCallback() {
        toastShow({ type: 'error', message: 'Reset password failed.' });
        closeResetPasswordModal();
      },
    });
  };

  useEffect(() => {
    accountListViewModel.getUserList();
  }, []);

  return (
    <>
      <div className="w-[calc(100%_-_550px)] min-w-[580px] h-[calc(100vh_-_276px)] max-h-[600px] rounded-[8px] border border-green-19dbc4 px-[36px] py-[36px] overflow-y-auto scrollbar-primary">
        {accountListViewModel.hasAccountData() && (
          <>
            <div className="mb-[16px]">
              <PacsButton
                isRoundedFull={true}
                className="w-full max-w-[180px] h-[40px] flex-center"
                onClick={showAccountForm}
              >
                <Svg
                  name="icAdd"
                  className="block w-[16px] h-[16px] mr-[8px]"
                />
                {/* TODO: 取得帳號上限 */}
                <span className="font-bold">Add ({accountListViewModel.accountList.length}/5)</span>
              </PacsButton>
            </div>

            <ul>
              {accountListViewModel.accountList.map((accountItem, i) => (
                <li
                  key={i}
                  className="w-full mb-[16px]"
                >
                  <AccountCard
                    title={accountItem.name}
                    content={accountItem.account}
                    onClickResetPassword={() => showResetPasswordModal(i)}
                    onClickAccountStatus={() => showChangeStatusModal(i)}
                    onClickRecoverAccount={() => recoverAccount(i)}
                    isDisabled={accountItem.status === 'Disable'}
                  />
                </li>
              ))}
            </ul>
          </>
        )}

        {!accountListViewModel.hasAccountData() && (
          <div className="w-full h-full flex-center flex-col">
            <p className="mb-[18px]">您尚未新增任何子帳號</p>

            <PacsButton
              isRoundedFull={true}
              className="w-full max-w-[180px] h-[40px] flex-center"
              onClick={showAccountForm}
            >
              <Svg
                name="icAdd"
                className="block w-[16px] h-[16px] mr-[8px]"
              />
              <span className="font-bold">新增帳號</span>
            </PacsButton>
          </div>
        )}
      </div>

      {isOpenAccountForm && (
        <AccountFormModal
          accountFormViewModel={accountFormViewModel}
          handleClose={closeAccountForm}
          handleAddNewAccount={handleAddNewAccount}
        />
      )}

      {isOpenChangeStatusModal && (
        <AskChangeStatusModal
          accountListViewModel={accountListViewModel}
          handleClose={closeChangeStatusModal}
          onSelectActionType={type => handleUpdateAccountStatus(type)}
        />
      )}

      {isOpenResetPasswordModal && (
        <ResetPasswordModal
          who={`”${accountListViewModel.getSelectedAccountItem()?.name}(
            ${accountListViewModel.getSelectedAccountItem()?.account})”`}
          onConfirmInputPassword={password => resetPassword(password)}
          handleClose={closeResetPasswordModal}
        />
      )}
    </>
  );
};
