import { useState } from 'react';
import { useOpenState } from '@/hooks/useOpenState';

interface PopupData {
  sex: string;
  species: string;
  tel: string;
  birthday: string;
  weight: string;
}

interface Position {
  x: number;
  y: number;
}

export class PopupHintVM {
  constructor() {
    [this.data, this.setData] = useState<PopupData>({
      sex: '',
      birthday: '',
      species: '',
      tel: '',
      weight: '',
    });

    [this.position, this.setPosition] = useState<Position>({ x: 0, y: 0 });

    this.openState = useOpenState();
  }

  openState: ReturnType<typeof useOpenState>;

  position: Position;
  data: PopupData;

  private setData: React.Dispatch<React.SetStateAction<PopupData>>;
  private setPosition: React.Dispatch<React.SetStateAction<Position>>;

  updateHint(rpItem: Api.RpItem) {
    this.setData({
      sex: rpItem.patient_sex,
      species: rpItem.patient_species,
      tel: rpItem.patient_telephone_number,
      birthday: rpItem.patient_birth_date,
      weight: rpItem.patient_weight,
    });
  }

  updatePosition = (div: HTMLElement, e: React.MouseEvent<HTMLUListElement, MouseEvent>) => {
    if (!div) {
      return;
    }

    let positionX = e.clientX + 25;
    let positionY = e.clientY;

    const rect = div.getBoundingClientRect();
    const padding = 20;

    /**
     * 是否超出視窗右邊
     */
    const isOverRightWindow = rect.width + positionX + padding > window.innerWidth;

    /**
     * 是否超出視窗下方
     */
    const isOverBottomWindow = rect.height + positionY + padding > window.innerHeight;

    if (isOverRightWindow) {
      positionX = e.clientX - padding - rect.width;
    }

    if (isOverBottomWindow) {
      positionY = e.clientY - padding - rect.height;
    }

    this.setPosition({ x: positionX, y: positionY });
  };
}
