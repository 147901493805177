import { CycleController, compareHelper } from '@/utils';
import { useState } from 'react';
import { utils } from '@ohif/core';

export type FieldName = keyof Api.RpItem;

export type Direction = 'asc' | 'desc';

interface SortData {
  fieldName: FieldName;
  direction: Direction;
}

export class ModalityWorkFilterVM {
  constructor() {
    [this.currentSortData, this.setCurrentSortData] = useState<SortData>({
      fieldName: 'create_time',
      direction: 'desc',
    });

    [this.cycleController] = useState(new CycleController<Direction>(['desc', 'asc']));
  }

  cycleController: CycleController<Direction>;

  /**
   * 排序資料
   */
  currentSortData: SortData;

  /**
   * 點擊排序方向
   */
  handleClickDirection(fieldName: FieldName) {
    const isSameField = this.currentSortData.fieldName === fieldName;
    let newDirection: Direction = 'desc';

    if (isSameField) {
      newDirection = this.cycleController.getNext();
    } else {
      this.cycleController.reset();
    }

    this.setCurrentSortData({ fieldName, direction: newDirection });
  }

  /**
   * 取得排序過的 study 清單
   */
  getSortRpList(rpList: Api.RpItem[]): Api.RpItem[] {
    const copyList = utils.jsonClone(rpList) as Api.RpItem[];
    const compareTypeMap: { [key in FieldName]?: Compare.Type } = {
      status: 'string',
      accession_number: 'string',
      identifier: 'string',
      patient_name: 'string',
      patient_id: 'string',
      create_time: 'dateTime',
      requesting_physician: 'string',
      description: 'string',
    };

    const sortData = this.currentSortData;
    const order = sortData.direction;

    const compareFunc = compareHelper.createCompareFunc([
      { fieldName: sortData.fieldName, order, compareType: compareTypeMap[sortData.fieldName] },
      { fieldName: 'create_time', order: 'desc', compareType: 'dateTime' },
    ]);
    copyList.sort(compareFunc);

    return copyList;
  }

  /**
   * setter
   */
  setCurrentSortData: React.Dispatch<React.SetStateAction<SortData>>;
}
