import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Header, Svg } from '@ohif/ui';
import { SettingMainViewModel } from '@/viewModel/setting/SettingMainViewModel';
import { TabToggleList } from '@/components/TabToggleList/TabToggleList';
import { useTabToggleList } from '@/components/TabToggleList/useTabToggleList';
import { SettingTabItem } from './tabItem/SettingTabItem';
import { ModalityWorklistTabItem } from './tabItem/ModalityWorklistTabItem';
import { utils } from '@ohif/core';
import { cookieKey } from '@/config/constants';
import { HubStatusTab } from './tabItem/HubStatusTab';

type Props = {
  settingMainViewModel?: SettingMainViewModel;
};

type TabKey = 'setting' | 'modalityWorklist' | 'hubStatus';

const { cookie } = utils;

export const Setting = ({ settingMainViewModel = new SettingMainViewModel() }: Props) => {
  const defaultTabKey: TabKey = cookie.get(cookieKey.SETTING_PAGE_TAB).data ?? 'setting';
  const navigate = useNavigate();

  const tabToggle = useTabToggleList<TabKey>(
    [
      { key: 'setting', label: 'Account', isActive: defaultTabKey === 'setting' },
      {
        key: 'modalityWorklist',
        label: 'Modality Worklist',
        isActive: defaultTabKey === 'modalityWorklist',
      },
      { key: 'hubStatus', label: 'Hub Status', isActive: defaultTabKey === 'hubStatus' },
    ],
    { initialTabKey: defaultTabKey }
  );

  const goPreviousPage = () => {
    navigate(-1);
  };

  useEffect(() => {
    settingMainViewModel.getAppVersion();
  }, []);

  return (
    <div>
      <div>
        <Header
          isReturnEnabled={false}
          isSettingPage={true}
        />

        <div
          className="w-full h-full pt-[24px] bg-gray-111 text-white overflow-y-auto scrollbar-primary"
          style={{ height: 'calc(100vh - 60px' }}
        >
          <div
            className="inline-flex items-center hover:text-green-19dbc4 cursor-pointer mb-[20px]"
            onClick={goPreviousPage}
          >
            <div className="flex justify-center items-center w-[30px] h-[30px]">
              <Svg
                name="icArrow"
                className="block w-[24px] h-[24px]"
              />
            </div>
            <h1 className="text-[20px] font-bold">Back</h1>
          </div>

          <TabToggleList
            tabList={tabToggle.tabList}
            onClickTab={arrIndex => {
              const tabItem = tabToggle.handleClickTab(arrIndex);
              cookie.set(cookieKey.SETTING_PAGE_TAB, { data: tabItem.key });
            }}
          />

          {tabToggle.currentTabKey === 'setting' && (
            <SettingTabItem settingMainViewModel={settingMainViewModel} />
          )}
          {tabToggle.currentTabKey === 'modalityWorklist' && <ModalityWorklistTabItem />}
          {tabToggle.currentTabKey === 'hubStatus' && <HubStatusTab />}
        </div>
      </div>
    </div>
  );
};
