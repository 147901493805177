import React from 'react';
import { useLocation } from 'react-router-dom';
import { utils } from '@ohif/core';
import { shareLinkHelper } from '@/utils';
import { ShareLinkProvider } from '@/components/provider/ShareLinkProvider';

const { cookieHelper } = utils;

export const PrivateRoute = ({ children }) => {
  const { isShareLinkPage, isViewerPage } = shareLinkHelper.getCurrentUrlInfo();
  if (isShareLinkPage || isViewerPage) {
    return (
      <>
        <ShareLinkProvider>{children}</ShareLinkProvider>;
      </>
    );
  }

  const location = useLocation();
  const { apiToken } = cookieHelper.getApiTokenFromCookie();
  const { dicomToken: cookieDicomToken } = cookieHelper.getDicomTokenFromCookie();
  const isLogin = apiToken && cookieDicomToken;
  const isLoginPage = location.pathname.startsWith('/login');

  /**
   * 檢查用戶登入狀態
   */
  if (!isLogin && !isLoginPage) {
    window.location.href = '/login';
    return <div className="w-screen h-screen bg-green-182c2a"></div>;
  }

  return children;
};

export default PrivateRoute;
