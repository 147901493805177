import { StudyListFilterVM } from './StudyListFilterVM';
import { doApi, ApiLoading } from '../helper/doApi';
import { utils } from '@ohif/core';
import { apiGetDicomStudyList, apiDeleteStudies, apiGetDicomSeriesList } from '@/api/dicom';
import { useState } from 'react';
import { sleepAtLeast, compareHelper } from '@/utils';
import { cookieKey } from '@/config/constants';
import { StudyListTransfer } from './StudyListTransfer';
import { UploadDicomVM } from './UploadDicomVM';
import { useToast } from '@ohif/ui';

const { cookie } = utils;

export class StudyListMainVM {
  constructor() {
    const saveAccountData = utils.cookieHelper.getLoginAccountData();
    this.unitId = saveAccountData.hospitalCode;
    [this.studyList, this.setStudyList] = useState<Api.StudyItem[]>([]);
    [this.seriesList, this.setSeriesList] = useState<Api.SeriesItem[]>([]);
    [this.currentStudyItem, this.setCurrentStudyItem] = useState<Api.StudyItem>(null);
    [this.searchText, this.setSearchText] = useState(cookie.get(cookieKey.STUDY_SEARCH).data ?? '');

    this.toast = useToast();
  }

  toast: ReturnType<typeof useToast>;
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;

  async clearSearch() {
    this.setSearchText('');
    this.getStudyList('');
  }

  unitId = '';
  studyListFilterVM = new StudyListFilterVM();
  uploadDicomVM = new UploadDicomVM();
  listLoading = new ApiLoading();
  seriesLoading = new ApiLoading();
  actionLoading = new ApiLoading();
  uploadLoading = new ApiLoading();
  private studyList: Api.StudyItem[];
  seriesList: Api.SeriesItem[];
  currentStudyItem: Api.StudyItem;
  studyListTransfer = new StudyListTransfer();

  /**
   * setter
   */
  private setStudyList: React.Dispatch<React.SetStateAction<Api.StudyItem[]>>;
  private setSeriesList: React.Dispatch<React.SetStateAction<Api.SeriesItem[]>>;
  private setCurrentStudyItem: React.Dispatch<React.SetStateAction<Api.StudyItem>>;

  /**
   * 取得 study 清單
   */
  async getStudyList(searchVal?: string) {
    if (this.listLoading.isLoading) {
      return;
    }

    const fn = async () => {
      const searchPattern =
        searchVal === '' //
          ? ''
          : searchVal || this.searchText || undefined;

      const filterParams = this.studyListFilterVM.getFilterParams();
      const res = await apiGetDicomStudyList({
        unitId: this.unitId,
        search_pattern: searchPattern,
        start_study_datetime: searchPattern ? undefined : filterParams.startDate,
        end_study_datetime: searchPattern ? undefined : filterParams.endDate,
        limit: searchPattern ? undefined : filterParams.limit,
      });

      const normalizeStudyList = res.data.data.map((study, i) => {
        const studyData = { ...study };
        Object.keys(studyData).forEach(keyName => {
          studyData[keyName] = studyData[keyName] ?? '';
        });

        return {
          ...studyData,
          originalIndex: i,
          isCheck: false,
        };
      });

      cookie.set(cookieKey.STUDY_SEARCH, { data: searchPattern });
      this.setStudyList(normalizeStudyList);
    };

    await doApi(() => sleepAtLeast(fn, 750), this.listLoading, 'get study list error');
  }

  /**
   * 取得 series 清單
   */
  async getSeriesList(study: Api.StudyItem) {
    if (this.seriesLoading.isLoading) {
      return;
    }

    this.setCurrentStudyItem(study);

    const fn = async () => {
      const res = await apiGetDicomSeriesList({
        unitId: this.unitId,
        studyInstanceUid: study.study_instance_uid,
      });
      const seriesList = res.data.data;

      const compareFunc = compareHelper.createCompareFunc([
        { fieldName: 'series_number', order: 'asc', compareType: 'number' },
      ]);

      seriesList.sort(compareFunc);

      this.setSeriesList(seriesList);
    };

    await doApi(() => sleepAtLeast(fn, 550), this.seriesLoading, 'get series list error');
  }

  /**
   * 勾選天數選項
   */
  async checkStudyOption(option: StudyListOption) {
    if (this.listLoading.isLoading) {
      return;
    }

    this.studyListFilterVM.checkOption(option);
    this.getStudyList();
  }

  /**
   * 取得篩選、排序後的 study 清單
   */
  getFilterStudyList() {
    return this.studyListFilterVM.getFinalFilterStudyList(this.studyList);
  }

  /**
   * 取得是否有被勾選的項目
   */
  getHasCheckStudy() {
    return this.getFilterStudyList().some(study => study.isCheck);
  }

  /**
   * 勾選 study
   */
  async handleCheckStudy(arrIndex: number) {
    if (this.actionLoading.isLoading) {
      return;
    }

    const copyList = utils.jsonClone(this.studyList);
    copyList[arrIndex].isCheck = !copyList[arrIndex].isCheck;
    this.setStudyList(copyList);
  }

  /**
   * 刪除 study 清單
   */
  async deleteStudyList(options?: Api.Options) {
    const fn = async () => {
      if (this.actionLoading.isLoading) {
        return;
      }

      const checkedList = this.getFilterStudyList().filter(study => study.isCheck);
      const uidList = checkedList.map(item => {
        return item.study_instance_uid;
      });

      await apiDeleteStudies({ unitId: this.unitId, studyUidList: uidList });
      await this.getStudyList();
    };

    await doApi(
      () => sleepAtLeast(fn, 750),
      this.actionLoading,
      'delete study list error',
      options
    );
  }

  /**
   * 上傳 dicom 檔案清單
   */
  async uploadDicomList() {
    const fn = async () => {
      await this.uploadDicomVM.uploadDicomList();
      await this.uploadDicomVM.closeUploadModal();
      await this.getStudyList();
    };

    const successCallback = () =>
      this.toast.toastShow({ type: 'success', message: 'Dicom Uploaded' });
    const errorCallback = () =>
      this.toast.toastShow({ type: 'error', message: 'Upload Dicom failed' });

    await doApi(() => sleepAtLeast(fn, 1000), this.uploadLoading, 'upload dicom list error', {
      successCallback,
      errorCallback,
    });
  }
}
