import React from 'react';
import { TextInput, PasswordInput } from '@mantine/core';
import { ModalBg } from '@/components/BaseModal/ModalBg';
import { PacsButton } from '@/components/common/PacsButton';
import { AccountFormViewModel } from '@/viewModel/setting/AccountFormViewModel';
import { utils } from '@ohif/core';

type Props = {
  children?: React.ReactNode;
  accountFormViewModel: AccountFormViewModel;
  handleClose?: () => Promise<void> | void;
  handleAddNewAccount?: () => Promise<void> | void;
};

export const AccountFormModal = ({
  accountFormViewModel = new AccountFormViewModel(),
  handleClose = () => {},
  handleAddNewAccount = () => {},
}: Props) => {
  const enterKeyToAddNewAccount = e => {
    utils.handleEnterKey(e, () => handleAddNewAccount());
  };

  return (
    <ModalBg>
      <div className="appear_top relative w-full max-w-[364px] max-h-[90%] bg-green-182c2a overflow-hidden hidden-overflow-with-radius rounded-[16px] px-[32px] py-[36px]">
        <div className="w-full flex flex-col i-center text-white">
          <div className="w-full mb-[12px]">
            <p className="text-[12px] mb-[8px]">Name</p>
            <TextInput
              placeholder="Please enter name"
              value={accountFormViewModel.accountData.name}
              onChange={event =>
                accountFormViewModel.updateAccountDataField('name', event.currentTarget.value)
              }
              onKeyPress={enterKeyToAddNewAccount}
            />
          </div>

          {/* <div className="w-full mb-[12px]">
            <p className="text-[12px] mb-[8px]">員工編號</p>
            <TextInput
              placeholder="輸入員工編號"
              value={accountFormViewModel.accountData.employeeNumber}
              onChange={event =>
                accountFormViewModel.updateAccountDataField(
                  'employeeNumber',
                  event.currentTarget.value
                )
              }
              onKeyPress={enterKeyToAddNewAccount}
            />
          </div> */}

          <div className="w-full mb-[12px]">
            <p className="text-[12px] mb-[8px]">Account</p>
            <TextInput
              placeholder="Please enter account"
              value={accountFormViewModel.accountData.account}
              onChange={event =>
                accountFormViewModel.updateAccountDataField('account', event.currentTarget.value)
              }
              onKeyPress={enterKeyToAddNewAccount}
            />
          </div>

          <div className="w-full mb-[28px]">
            <p className="text-[12px] mb-[8px]">Password</p>
            <PasswordInput
              placeholder="Please enter password"
              value={accountFormViewModel.accountData.password}
              onChange={event =>
                accountFormViewModel.updateAccountDataField('password', event.currentTarget.value)
              }
              onKeyPress={enterKeyToAddNewAccount}
            />
          </div>

          <PacsButton
            isDisabled={!accountFormViewModel.checkIsFormComplete()}
            className="block w-full h-[40px] mb-[8px]"
            onClick={handleAddNewAccount}
          >
            <span className="text-green-182c2a text-[14px] font-bold">Add</span>
          </PacsButton>

          <p
            className="text-[14px] text-green-19dbc4 hover:text-[#28B4A3] underline cursor-pointer"
            onClick={handleClose}
          >
            Cancel
          </p>
        </div>
      </div>
    </ModalBg>
  );
};
