import React from 'react';
import classNames from 'classnames';
import { ShareStudyVM } from '@/viewModel/ShareStudy/ShareStudyVM';
import { Svg, ProgressLoadingBar } from '@ohif/ui';
import { PacsButton } from '@/components/common/PacsButton';

type Props = {
  className?: string;
  shareStudyVM: ShareStudyVM;
};

export const ViewOnly = ({ className, shareStudyVM }: Props) => {
  const { menuVM } = shareStudyVM;
  return (
    <div className={classNames('px-[36px]', className)}>
      <div className="flex mb-[22px]">
        <Svg
          name="icViewOnly"
          className="block w-[32px] h-[42px] mr-[8px] text-green-19dbc4"
        />
        <div className="text-[16px]">
          <h3 className="font-bold">View Only</h3>
          <p>For online viewing this study in 7 days.</p>
        </div>
      </div>

      {shareStudyVM.shareLoading.isLoading && (
        <div className="flex j-center mb-[36px] w-full max-w-[192px] mx-auto">
          <ProgressLoadingBar />
        </div>
      )}

      {!shareStudyVM.shareLoading.isLoading && (
        <div className="flex flex-col i-center">
          <div className="flex j-center mb-[24px]">
            <img
              src={shareStudyVM.qrCodeSrc}
              className="block w-[215px] h-[215px]"
              alt="share link qr code"
            />
          </div>

          <p
            className="text-green-19dbc4 cursor-pointer underline text-center hover:text-opacity-80 font-bold mb-[20px] select-none"
            onClick={() => shareStudyVM.copyShareLink()}
          >
            Copy Link
          </p>
        </div>
      )}

      <PacsButton
        theme="secondary"
        className="w-full h-[40px]"
        onClick={() => menuVM.setCurrentMenuItem('menu')}
      >
        Back
      </PacsButton>
    </div>
  );
};
