export class DataCache {
  private _data: { [key in string]: any } = {};

  get(keyName: string) {
    return this._data[keyName];
  }

  set(keyName: string, val: any) {
    this._data[keyName] = val;
  }

  remove(keyName) {
    const { [keyName]: _, ...rest } = this._data;
    this._data = rest;
  }
}
