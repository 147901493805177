import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { StudyDayRadioOptionList } from '@/components/StudyDayRadioOptionList/StudyDayRadioOptionList';
import { PacsButton } from '@/components/common/PacsButton';
import { SpsModal } from '@/components/ModalityWorkList/SpsModal/SpsModal';
import { ModalityWorkVM, getDefaultDayOption } from '@/viewModel/modalityWorkList/ModalityWorkVM';
import { ProgressLoadingBar } from '@ohif/ui';
import { PrimaryTable, TableRow, TableCell } from '@/components/common/PrimaryTable';
import { timeFormatHelper } from '@/utils';
import { ModalityWorkListTableHead } from './ModalityWorkListTableHead';
import { PopupHint } from './PopupHint';

type Props = {
  className?: string;
  children?: React.ReactNode;
};

export const ModalityWorklistTabItem = ({ className }: Props) => {
  const modalityWorkVM = new ModalityWorkVM();
  const { popupHintVM } = modalityWorkVM;
  const defaultDayOption = getDefaultDayOption();

  const elRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    modalityWorkVM.getModalityWorklist({ dayOption: defaultDayOption });
  }, []);

  return (
    <div className={classNames('', className)}>
      <div className="flex justify-between i-center px-[24px] pt-[12px] mb-[12px]">
        <div className="flex i-center">
          <p className="text-[14px] font-bold mr-[8px]">Scheduled Time:</p>
          <StudyDayRadioOptionList
            className={classNames(modalityWorkVM.dataLoading.isLoading && 'pointer-events-none')}
            defaultDayOption={defaultDayOption}
            onClickDayOption={option => {
              modalityWorkVM.getModalityWorklist({ dayOption: option.key });
            }}
          />
        </div>

        <PacsButton
          className="min-w-[104px] h-[36px]"
          theme="secondary"
          isLoading={modalityWorkVM.isRefreshing}
          onClick={() => modalityWorkVM.refreshWorkList()}
        >
          Refresh
        </PacsButton>
      </div>

      {modalityWorkVM.dataLoading.isLoading && (
        <div className="flex flex-col items-center justify-center pt-48">
          <div className="w-full max-w-[192px]">
            <ProgressLoadingBar />
          </div>
        </div>
      )}

      {!modalityWorkVM.dataLoading.isLoading && (
        <div
          onMouseLeave={() => {
            popupHintVM.openState.close();
          }}
        >
          <PrimaryTable
            isCustomHeader={true}
            customHeader={<ModalityWorkListTableHead modalityWorkVM={modalityWorkVM} />}
          >
            {modalityWorkVM.getSortRpList().map((rpItem, i) => (
              <TableRow
                key={rpItem.accession_number}
                onClick={e => {
                  e.stopPropagation();
                  modalityWorkVM.handleClickDetail(rpItem);
                }}
                className={classNames([
                  rpItem.status === 'DEPARTED' && 'text-gray-666',
                  rpItem.status === 'COMPLETED' && 'text-gray-c9c9c9',
                  rpItem.status === 'DISCONTINUED' && 'text-[#F7CA15]',
                ])}
                onMouseEnter={e => {
                  popupHintVM.updatePosition(elRef.current, e);
                  popupHintVM.openState.open();
                  popupHintVM.updateHint(rpItem);
                }}
                onMouseMove={e => {
                  popupHintVM.updatePosition(elRef.current, e);
                }}
              >
                <TableCell>{rpItem.status}</TableCell>
                <TableCell>{rpItem.accession_number}</TableCell>
                <TableCell>{rpItem.identifier}</TableCell>
                <TableCell>{rpItem.patient_name}</TableCell>
                <TableCell>{rpItem.patient_id}</TableCell>
                <TableCell>{timeFormatHelper.formatUtcTime(rpItem.create_time)}</TableCell>
                <TableCell>{rpItem.requesting_physician}</TableCell>
                <TableCell>{rpItem.description}</TableCell>
              </TableRow>
            ))}
          </PrimaryTable>

          {popupHintVM.openState.isOpen && (
            <div
              className="fixed pointer-events-none"
              ref={elRef}
              style={{
                left: popupHintVM.position.x + 'px',
                top: popupHintVM.position.y + 'px',
              }}
            >
              <PopupHint modalityWorkVM={modalityWorkVM} />
            </div>
          )}
        </div>
      )}

      {modalityWorkVM.spsModalState.isOpen && (
        <SpsModal
          modalityWorkVM={modalityWorkVM}
          onClose={() => modalityWorkVM.spsModalState.close()}
        />
      )}
    </div>
  );
};
