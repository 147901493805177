import { useEffect } from 'react';
import { utils } from '@ohif/core';

const { cookieHelper } = utils;

function OpenIdConnectRoutes({ userAuthenticationService }) {
  const getAuthorizationHeader = () => {
    const { dicomToken } = cookieHelper.getDicomTokenFromCookie();
    return {
      Authorization: `Bearer ${dicomToken}`,
    };
  };

  const handleUnauthenticated = async () => {
    // return null because this is used in a react component
    return null;
  };

  useEffect(() => {
    userAuthenticationService.set({ enabled: true });

    userAuthenticationService.setServiceImplementation({
      getAuthorizationHeader,
      handleUnauthenticated,
    });
  }, []);

  return null;
}

export default OpenIdConnectRoutes;
