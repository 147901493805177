import React, { useEffect } from 'react';
import { Header } from '@ohif/ui';
import { useNavigate } from 'react-router-dom';
import { StudyListHeader } from './StudyListHeader';
import { StudyTable } from './StudyTable/StudyTable';
import { StudyListMainVM } from '@/viewModel/StudyList/StudyListMainVM';

export const StudyList = () => {
  const studyListMainVM = new StudyListMainVM();
  const navigate = useNavigate();

  const goSettingPage = () => {
    navigate('/setting');
  };

  /**
   * 取得 study 清單
   */
  useEffect(() => {
    studyListMainVM.getStudyList();
  }, []);

  return (
    <div className="study_list w-full h-[100dvh] bg-gray-202020 flex flex-col text-white">
      <Header
        isSticky
        isReturnEnabled={false}
        onClickSetting={goSettingPage}
      />

      <div className="max-md:overflow-y-auto no-scrollbar">
        <StudyListHeader studyListMainVM={studyListMainVM} />

        <div className="h-[calc(100dvh_-_122px)] max-md:h-auto">
          <StudyTable
            studyListMainVM={studyListMainVM}
            className="h-full relative"
          />
        </div>
      </div>
    </div>
  );
};
