import React from 'react';
import { useState } from 'react';
import { apiUserLogin } from '@/api/user';
import { apiGetDicomToken } from '@/api/dicom';
import { utils } from '@ohif/core';
import { cookieKey } from '@/config/constants';
import { encryptHelper } from '@/utils/encryptHelper';

interface AccountData {
  hospitalCode: string;
  account: string;
  password: string;
}

const { cookieHelper, cookie } = utils;

export class LoginViewModel {
  constructor() {
    const saveAccountData = cookieHelper.getLoginAccountData();
    const defaultRememberPassword = cookie.get(cookieKey.IS_SAVE_PASSWORD).data ?? false;
    const pwd = saveAccountData.password ? encryptHelper.decryptText(saveAccountData.password) : '';

    const [data, setData] = useState<AccountData>({
      hospitalCode: saveAccountData.hospitalCode ?? '', // 醫院代碼
      account: saveAccountData.account ?? '',
      password: pwd,
    });

    const [isLoading, setIsLoading] = useState(false);
    [this.isRememberPassword, this.setIsRememberPassword] = useState(defaultRememberPassword);

    this.accountData = data;
    this.setAccountData = setData;
    this.isDoingLogin = isLoading;
    this.setIsDoingLogin = setIsLoading;
  }

  /**
   * 帳號資料
   */
  accountData: AccountData;

  /**
   * 是否正在登入
   */
  isDoingLogin: boolean;

  /**
   * 是否要記住密碼
   */
  isRememberPassword: boolean;

  /**
   * 更新各個帳號欄位
   */
  updateAccountDataField(keyName: keyof AccountData, val: string) {
    this.setAccountData({ ...this.accountData, [keyName]: val });
  }

  /**
   * 清除已經紀錄的密碼
   */
  _clearSavedPassword() {
    const saveAccountData = cookieHelper.getLoginAccountData();
    if (!saveAccountData?.account) {
      return;
    }

    const { account, hospitalCode } = saveAccountData;
    cookieHelper.setLoginAccountData({ account, hospitalCode });
  }

  /**
   * 勾選記住密碼
   */
  checkRememberPassword() {
    const isSaved = !this.isRememberPassword;
    this.setIsRememberPassword(isSaved);
    cookie.set(cookieKey.IS_SAVE_PASSWORD, { data: isSaved });

    if (!isSaved) {
      this._clearSavedPassword();
    }
  }

  /**
   * 進行登入
   */
  async login(options?: {
    successCallback: (
      loginResponse: Api.LoginResponse,
      loginAccountData: SaveAccountData,
      dicomToken: string
    ) => Promise<void> | void;
    errorCallback?: (error: any) => Promise<void> | void;
  }): Promise<{ isLoginSuccess: boolean }> {
    if (this.isDoingLogin) {
      return;
    }

    this.setIsDoingLogin(true);
    try {
      const res = await apiUserLogin({
        account: this.accountData.account,
        password: this.accountData.password,
        hospital_code: this.accountData.hospitalCode,
      });

      const loginResponse = res.data.data as Api.LoginResponse;
      const dicomRes = await apiGetDicomToken(loginResponse.access_token);
      const dicomToken = dicomRes.data.data.access_token;
      const { account, hospitalCode, password } = this.accountData;
      const saveAccountData: SaveAccountData = { account, hospitalCode };

      if (this.isRememberPassword) {
        saveAccountData.password = encryptHelper.encryptText(password);
      }

      await options?.successCallback(loginResponse, saveAccountData, dicomToken);

      return { isLoginSuccess: true };
    } catch (error) {
      console.error('login error:', error);
      options?.errorCallback?.(error);

      return { isLoginSuccess: false };
    } finally {
      this.setIsDoingLogin(false);
    }
  }

  /**
   * setter
   */
  private setAccountData: React.Dispatch<React.SetStateAction<AccountData>>;
  private setIsDoingLogin: React.Dispatch<React.SetStateAction<boolean>>;
  private setIsRememberPassword: React.Dispatch<React.SetStateAction<boolean>>;
}
