type DiskSpaceStatus = 'normal' | 'warning' | 'alert';

interface IUnitSpace {
  maxGb: number;
  maxTb: number;
  currentUsedGb: number;
  leftGb: number;
  usedPercentage: number;
  leftPercentage: number;
  currentStatus: DiskSpaceStatus;
}

interface DiskSpaceOptions {
  warningUsagePercentage: number;
  alertUsagePercentage: number;
}

export class DiskSpace implements IUnitSpace {
  constructor(currentUsedGb: number, maxGb: number, options?: DiskSpaceOptions) {
    this._caculateSpace(currentUsedGb, maxGb);

    if (options) {
      this.warningUsagePercentage = options.warningUsagePercentage;
      this.alertUsagePercentage = options.alertUsagePercentage;
    }
  }

  private baseSpaceGb = 1000;

  maxGb: number;
  maxTb: number;
  currentUsedGb: number;
  leftGb: number;
  usedPercentage: number;
  leftPercentage: number;

  warningUsagePercentage = 75;
  alertUsagePercentage = 90;

  get currentStatus(): DiskSpaceStatus {
    if (this.usedPercentage >= this.alertUsagePercentage) {
      return 'alert';
    } else if (this.usedPercentage >= this.warningUsagePercentage) {
      return 'warning';
    }

    return 'normal';
  }

  static formatSpaceNumber(num: number) {
    return +num.toFixed(2);
  }

  /**
   * 計算容量 (單位 GB)
   */
  private _caculateSpace(currentUsedGb: number, maxGb = 1000) {
    const max = maxGb;
    const current = currentUsedGb;
    const usedPercentage = DiskSpace.formatSpaceNumber((current / max) * 100);

    this.currentUsedGb = currentUsedGb;
    this.maxGb = maxGb;
    this.maxTb = DiskSpace.formatSpaceNumber(maxGb / this.baseSpaceGb);
    this.leftGb = DiskSpace.formatSpaceNumber(Math.max(maxGb - currentUsedGb, 0));
    this.usedPercentage = usedPercentage;
    this.leftPercentage = DiskSpace.formatSpaceNumber(Math.max(100 - usedPercentage, 0));
  }
}
