import React, { ReactNode, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { smartPacsAuthContext } from '@/SmartPacsProvider/SmartPacsAuthProvider';
import { useNavigate } from 'react-router-dom';

import NavBar from '../NavBar';
import Svg from '../Svg';
import Icon from '../Icon';

function Header({
  children,
  menuOptions,
  isReturnEnabled,
  onClickReturnButton,
  onClickSetting,
  isSticky,
  isSettingPage,
  isShowLoginInfo,
  isOpenByShareLink,
  ...props
}): ReactNode {
  const [isHover, setIsHover] = useState(false);
  const { userSelfData, authData, openAskLogoutModal } = useContext(smartPacsAuthContext);
  const navigate = useNavigate();

  const goHome = () => {
    navigate('/');
  };

  const onClickReturn = () => {
    if (isReturnEnabled && onClickReturnButton) {
      onClickReturnButton();
    }
  };

  return (
    <NavBar
      className="justify-between w-full h-full"
      isSticky={isSticky}
    >
      <div className="relative flex w-full h-full flex-1 justify-between text-white px-[12px]">
        <div className="relative z-[1] flex items-center">
          {isOpenByShareLink && (
            <div className={classNames('mr-3 inline-flex items-center')}>
              <Svg
                name="icLogo"
                className="mr-[5px] block h-[40px] w-[52px]"
              />
            </div>
          )}

          {!isOpenByShareLink && (
            <div
              className={classNames(
                'mr-3 inline-flex items-center',
                isReturnEnabled && 'cursor-pointer'
              )}
              onClick={onClickReturn}
            >
              {isReturnEnabled && (
                <div className="hover:bg-green-19dbc4 flex-center  h-[36px] w-[36px] cursor-pointer rounded-full">
                  <Icon
                    name="chevron-left"
                    className="text-blue-01c5d5 block h-[36px] w-[36px] translate-x-[-2px] transform "
                  />
                </div>
              )}

              {!isReturnEnabled && (
                <div
                  className="flex items-center cursor-pointer"
                  onClick={goHome}
                >
                  <Svg
                    name="icLogo"
                    className="mr-[5px] block h-[40px] w-[52px] max-md:w-[35px] max-md:h-[26px]"
                  />
                  <Svg
                    name="icSmart"
                    className="mr-[10px] block h-[20px] w-[132px] max-md:w-[86px] max-md:h-[13px] max-md:mr-[6px]"
                  />
                  <Svg
                    name="icPacs"
                    className="block h-[20px] w-[98px] max-md:w-[64px] max-md:h-[13px]"
                  />
                </div>
              )}
            </div>
          )}
        </div>

        <div className="absolute z-[0] left-0 top-0 w-full h-full flex-center">{children}</div>

        {userSelfData?.id && (
          <div className="relative z-[1] flex items-center">
            {isShowLoginInfo && (
              <>
                <p className="mr-[20px] text-[20px] max-md:hidden">
                  Hi, {authData.hospital_name} {userSelfData.name}
                </p>

                <p
                  className="text-green-19dbc4 mr-[30px] cursor-pointer text-[20px] underline hover:opacity-80 max-md:mr-0"
                  onClick={openAskLogoutModal}
                >
                  Log out
                </p>
              </>
            )}

            {!isOpenByShareLink && (
              <div
                className="hover:text-green-19dbc4 flex h-[30px] w-[30px] cursor-pointer items-center max-md:hidden"
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
                onClick={onClickSetting}
              >
                <Svg
                  name={isSettingPage || isHover ? 'icSettingFill' : 'icSettingOutline'}
                  className={classNames(
                    'inline-block h-[24px] w-[24px]',
                    isSettingPage && 'text-green-19dbc4'
                  )}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </NavBar>
  );
}

Header.propTypes = {
  menuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.string,
      onClick: PropTypes.func.isRequired,
    })
  ),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  isReturnEnabled: PropTypes.bool,
  isSticky: PropTypes.bool,
  isSettingPage: PropTypes.bool,
  isShowLoginInfo: PropTypes.bool,
  isOpenByShareLink: PropTypes.bool,
  onClickReturnButton: PropTypes.func,
  onClickSetting: PropTypes.func,
};

Header.defaultProps = {
  isReturnEnabled: true,
  isSticky: false,
  isShowLoginInfo: true,
};

export default Header;
