export class StudyListTransfer {
  displayStudyOption(option: StudyListOption) {
    const optionMap: { [key in StudyListOption]: string } = {
      last7Days: 'Last 7 Days',
      last30Days: 'Last 30 Days',
      today: 'Today',
      recent: 'Recent',
    };

    return optionMap[option];
  }
}
