class WindowHelper {
  private isAddRemovePrint = false;

  addRemovePrintListener() {
    if (this.isAddRemovePrint) {
      return;
    }

    window.onbeforeprint = () => {
      document.querySelectorAll('.noPrint').forEach(el => {
        el.style.opacity = '0';
      });
    };

    window.onafterprint = () => {
      document.querySelectorAll('.noPrint').forEach(el => {
        el.style.opacity = '1';
      });
    };

    this.isAddRemovePrint = true;
  }
}

export const windowHelper = new WindowHelper();
