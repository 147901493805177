import classNames from 'classnames';
import React from 'react';
import { zIndex as appZIndex } from '@/config/constants';

type Props = {
  handleClickBg?: () => Promise<void> | void;
  children?: React.ReactNode;
  zIndex?: number;
};

export const ModalBg = ({
  handleClickBg = () => {},
  children,
  zIndex = appZIndex.BASE_MODAL_BG,
}: Props) => {
  return (
    <div
      className={classNames('fixed  left-0 top-0 flex-center w-full h-full px-[15px]')}
      style={{ zIndex }}
    >
      <div
        className={classNames('absolute z-[-1] w-full h-full bg-black bg-opacity-50')}
        onClick={handleClickBg}
      ></div>
      {children}
    </div>
  );
};
