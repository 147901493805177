import React from 'react';
import { ModalBg } from '@/components/BaseModal/ModalBg';
import { PacsButton } from '@/components/common/PacsButton';
import { SecondayTable, HeadItem, TableRow, TableCell } from '@/components/common/SecondayTable';
import { StudyListMainVM } from '@/viewModel/StudyList/StudyListMainVM';
import { ProgressLoadingBar } from '@ohif/ui';

const headList: HeadItem<string>[] = [
  {
    key: 'description',
    label: 'Description',
    style: { width: '25%' },
  },
  {
    key: 'series',
    label: 'Series',
    style: { width: '25%' },
  },
  { key: 'modality', label: 'Modality', style: { width: '25%' } },
  { key: 'instances', label: 'Instances', style: { width: '25%' } },
];

type Props = {
  studyListMainVM: StudyListMainVM;
  onClose: () => Promise<void> | void;
};

export const StudyDetailModal = ({ studyListMainVM, onClose }: Props) => {
  return (
    <ModalBg handleClickBg={onClose}>
      <div className="appear_top relative w-full max-w-[1060px] max-h-[90%] bg-green-182c2a overflow-y-auto scrollbar-primary hidden-overflow-with-radius rounded-[16px] py-[36px]">
        <div className="w-full h-full text-gray-f9f9f9 px-[36px]">
          <h3 className="text-gray-f9f9f9 text-[14px] mb-[16px]">
            Accession No. {studyListMainVM.currentStudyItem.accession_number}
          </h3>

          {studyListMainVM.seriesLoading.isLoading && (
            <div className="flex j-center mb-[36px] w-full max-w-[192px] mx-auto">
              <ProgressLoadingBar />
            </div>
          )}

          {!studyListMainVM.seriesLoading.isLoading && (
            <SecondayTable
              headList={headList}
              className="mb-[36px]"
            >
              {studyListMainVM.seriesList.map((item, i) => (
                <TableRow key={item.series_instance_uid}>
                  <TableCell>{item.series_description || '-'}</TableCell>
                  <TableCell>{item.series_number || '-'}</TableCell>
                  <TableCell>{item.modality || '-'}</TableCell>
                  <TableCell>{item.instances || '-'}</TableCell>
                </TableRow>
              ))}
            </SecondayTable>
          )}

          <PacsButton
            className="block w-full max-w-[300px] mx-auto h-[40px] "
            onClick={onClose}
          >
            <span className="text-[14px] text-green-182c2a font-bold">確定</span>
          </PacsButton>
        </div>
      </div>
    </ModalBg>
  );
};
