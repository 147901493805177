import { apiGetUnitInfo } from '@/api/unit';
import { doApi, ApiLoading } from '@/viewModel/helper/doApi';
import { useState } from 'react';
import { DeviceItem } from '@/viewModel/setting/DeviceItem';

export class HubStatusVM {
  constructor() {
    [this.deviceList, this.setDeviceList] = useState([]);
  }

  dataLoading = new ApiLoading();
  interval;

  deviceList: DeviceItem[];
  setDeviceList: React.Dispatch<React.SetStateAction<DeviceItem[]>>;

  async getDeviceList() {
    const fn = async () => {
      const res = await apiGetUnitInfo();
      const deviceList = res.data.data.bindings.map(item => {
        return new DeviceItem(item);
      });
      this.setDeviceList(deviceList);
    };

    await doApi(fn, this.dataLoading, 'get device list error');
  }

  intervalRefreshDeviceList() {
    this.getDeviceList();

    this.interval = setInterval(() => {
      this.getDeviceList();
    }, 60000); // 1分鐘
  }

  destroyIntervalRefresh() {
    clearInterval(this.interval);
  }
}
