import React from 'react';
import classNames from 'classnames';
import { Svg } from '@ohif/ui';

type Props = {
  className?: string;
  isCheck?: boolean;
  children?: React.ReactNode;
  size?: 'normal' | 'small';
  onClick?: () => Promise<void> | void;
};

export const Checkbox = ({ className, children, isCheck = false, size = 'normal' }: Props) => {
  return (
    <div
      className={classNames(
        'inline-flex i-center cursor-pointer select-none rounded-[3px]',
        className
      )}
    >
      <div
        className={classNames(
          'relative rounded-[3px] border-2 ',
          size === 'normal' && 'w-[20px] h-[20px]',
          size === 'small' && 'w-[16px] h-[16px]',
          isCheck && 'bg-green-19dbc4 border-green-19dbc4',
          /**
           * 由外層給 class "group/checkbox"
           */
          !isCheck && 'border-gray-ededed group-hover/checkbox:border-green-19dbc4'
        )}
      >
        {isCheck && (
          <Svg
            name="icCheck"
            className={classNames(
              'absolute left-[-1px] bottom-[-1px] inline-block text-white',
              size === 'normal' && 'w-[20px] h-[20px]',
              size === 'small' && 'w-[16px] h-[16px]'
            )}
          />
        )}
      </div>
      <p className="select-none">{children}</p>
    </div>
  );
};
