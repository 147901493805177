import { dicomHelper } from '@/utils';
import { doApi, ApiLoading } from './helper/doApi';
import { processStudyResults, compareHelper } from '@/utils';

const patientIdTag = '00100020';

export class PatientRedirectVM {
  dataLoading = new ApiLoading();

  async getPatientStudyList(
    patientId: string,
    options?: { onNoContent: () => Promise<void> | void } & Api.Options
  ) {
    let firstStudy: WorkListStudy | null = null;

    const fn = async () => {
      const res = await dicomHelper.getStudyList({ [patientIdTag]: patientId });

      if (!res.data?.length) {
        options?.onNoContent();
        return;
      }

      const studyList = processStudyResults(res.data).map((study, i): WorkListStudy => {
        return {
          ...study,
          originalIndex: i,
          isCheck: false,
        };
      });

      const compareFunc = compareHelper.createCompareFunc([
        { fieldName: 'studyDate', order: 'desc', compareType: 'studyDate' },
      ]);

      studyList.sort(compareFunc);

      if (studyList.length) {
        firstStudy = studyList[0];
      }
    };

    await doApi(fn, this.dataLoading, 'get patient study list error', options);

    return firstStudy;
  }
}
