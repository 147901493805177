import React from 'react';
import classNames from 'classnames';
import { Svg } from '@ohif/ui';
import { DeviceItem } from '@/viewModel/setting/DeviceItem';

type Props = {
  className?: string;
  children?: React.ReactNode;
  deviceItem: DeviceItem;
};

export const DeviceCard = ({ className, deviceItem }: Props) => {
  const status = deviceItem.isConnected ? 'connected' : 'disconnected';

  return (
    <div
      className={classNames(
        'border rounded-[8px] p-[36px] max-xl:px-[24px]',
        status === 'connected' && 'border-gray-c9c9c9',
        status === 'disconnected' && 'border-red-eb1e4b',
        className
      )}
    >
      <div
        className={classNames(
          'flex-center flex-col',
          status === 'connected' && 'text-green-19dbc4',
          status === 'disconnected' && 'text-red-eb1e4b'
        )}
      >
        <Svg
          name="icTerminal"
          className="block w-[24px] h-[24px]"
        />
        <p className={classNames('text-[14px] font-bold mb-[24px]')}>
          {deviceItem.isConnected ? 'Connected' : 'Disconnected'}
        </p>
      </div>

      <div className="mb-[16px] break-all">
        <p className="text-gray-c9c9c9">Device ID</p>
        <div className="w-full flex i-center bg-gray-2b2b2b min-h-[44px] rounded-[8px] px-[10px]">
          {deviceItem.data.hub_device_id}
        </div>
      </div>

      <div className="mb-[16px] break-all">
        <p className="text-gray-c9c9c9">Last connection time</p>
        <div className="w-full flex i-center bg-gray-2b2b2b min-h-[44px] rounded-[8px] px-[10px]">
          {deviceItem.aliveTime}
        </div>
      </div>

      <div className="mb-[16px]">
        <p className="text-gray-c9c9c9">Last upload time</p>
        <div className="w-full flex i-center bg-gray-2b2b2b min-h-[44px] rounded-[8px] px-[10px]">
          {deviceItem.uploadTime}
        </div>
      </div>
    </div>
  );
};
