import { useDialog, DialogService } from '@/components/Dialog/SmartPacsDialogProvider';
import { useToast } from '@ohif/ui';
import { useState } from 'react';
import { downloadHelper, copyHelper } from '@/utils';
import dayjs from 'dayjs';

type ActionType = 'downloadLink' | 'selectMoreDicom' | '';

export class ShareDownloadVM {
  constructor() {
    const { dialog } = useDialog();
    this.toast = useToast();
    this.dialog = dialog;

    [this.actionType, this.setActionType] = useState<ActionType>('');
    [this.dicomDownloadQrCodeSrc, this.setDicomDownloadQrCodeSrc] = useState('');
    [this.dicomDownloadLink, this.setDicomDownloadLink] = useState('');
    [this.expiredDate, this.setExpiredDate] = useState('');
  }

  toast: ReturnType<typeof useToast>;
  dialog: DialogService;

  actionType: ActionType;
  dicomDownloadQrCodeSrc: string;
  dicomDownloadLink: string;
  expiredDate: string;

  private setActionType: React.Dispatch<React.SetStateAction<ActionType>>;
  private setDicomDownloadQrCodeSrc: React.Dispatch<React.SetStateAction<string>>;
  private setDicomDownloadLink: React.Dispatch<React.SetStateAction<string>>;
  private setExpiredDate: React.Dispatch<React.SetStateAction<string>>;

  /**
   * 分享成功事件
   */
  onShareDicomSuccess(res: Api.ShareDicom) {
    this.setDicomDownloadLink(res.download_url);

    this.dialog.close();

    this.setActionType('downloadLink');
    this.setDicomDownloadQrCodeSrc(`data:image/png;base64,${res.qrcode_base64_string}`);
    this.setExpiredDate(dayjs().add(7, 'day').format('YYYY-MM-DD HH:mm'));
  }

  /**
   * 下載 dicom
   */
  async downloadDicom() {
    await downloadHelper.downloadFileByUrl(this.dicomDownloadLink);
  }

  /**
   * 複製下載網址
   */
  async copyDownloadLink() {
    const isCopySuccess = await copyHelper.clipboardCopy(this.dicomDownloadLink);
    if (isCopySuccess) {
      this.toast.toastShow({ type: 'success', message: 'Copy successful', title: '' });
    }
  }

  reset() {
    this.setActionType('');
    this.setDicomDownloadQrCodeSrc('');
    this.setDicomDownloadLink('');
    this.setExpiredDate('');
  }
}
