import React from 'react';
import classNames from 'classnames';
import { PacsButton } from '@/components/common/PacsButton';
import { Svg } from '@ohif/ui';

interface SelectItem {
  key: string;
  label: string;
}

type Props = {
  theme?: 'primary' | 'grass';
  className?: string;
  optionList: SelectItem[];
  children?: React.ReactNode;
  onClickOption: (optionKey: string) => Promise<void> | void;
};

export const PacsNativeSelect = ({
  theme = 'grass',
  className,
  optionList,
  children,
  onClickOption,
}: Props) => {
  return (
    <PacsButton
      theme={theme}
      roundSize="small"
      className={classNames('relative ', className)}
    >
      <select
        className="absolute z-[1] left-0 top-0 w-full h-full opacity-0 cursor-pointer"
        onChange={e => {
          onClickOption(e.target.value);
        }}
      >
        {optionList.map(item => (
          <option
            key={item.key}
            value={item.key}
          >
            {item.label}
          </option>
        ))}
      </select>

      <div className="w-full h-full flex i-center justify-between">
        {children}

        <Svg
          name="icSelect"
          className="block ml-[8px]"
        />
      </div>
    </PacsButton>
  );
};
